import moment from 'moment-timezone';
import firebase from "../../services/firebase";
import { CallDavGoogle, CallDavApple, CallDavOutlook } from '../../services/caldav';

export const SET_EVENTS_LOADING = 'SET_EVENTS_LOADING';
export const SET_GOOGLE_USER_CALENDARS = 'SET_GOOGLE_USER_CALENDARS';
export const SET_OUTLOOK_USER_CALENDARS = 'SET_OUTLOOK_USER_CALENDARS';
export const SET_APPLE_USER_CALENDARS = 'SET_APPLE_USER_CALENDARS';
export const SET_PLANNER_EVENTS = 'SET_PLANNER_EVENTS';
export const CLEANUP_PLANNER_EVENTS = 'CLEANUP_PLANNER_EVENTS';
export const UPDATE_PLANNER_EVENT = 'UPDATE_PLANNER_EVENT';
export const DELETE_PLANNER_EVENT = 'DELETE_PLANNER_EVENT';
export const CLEANUP_CALENDARS = 'CLEANUP_CALENDARS';

const setLoading = (loading: any) => ({
  type: SET_EVENTS_LOADING,
  loading
});

const setGoogleUserCalendars = (calendars: object) => ({
  type: SET_GOOGLE_USER_CALENDARS,
  calendars
})
const setOutlookUserCalendars = (calendars: object) => ({
  type: SET_OUTLOOK_USER_CALENDARS,
  calendars
})
const setAppleUserCalendars = (calendars: object) => ({
  type: SET_APPLE_USER_CALENDARS,
  calendars
})
const setPlannerEvents = (events: any, startDate: any, endDate: any) => ({
  type: SET_PLANNER_EVENTS,
  events,
  startDate,
  endDate
})
const updatePlannerEvent = (event: any) => ({
  type: UPDATE_PLANNER_EVENT,
  event
})
const deletePlannerEvent = (uid: any) => ({
  type: DELETE_PLANNER_EVENT,
  uid
})


export const cleanup_all_data = () => {
  return (dispatch: any) => {
    dispatch({type: CLEANUP_CALENDARS});
  }
}
export const set_google_user_calendars = (calendars: object) => {
  return (dispatch: any) => {
    dispatch(setGoogleUserCalendars(calendars));
  }
}
export const set_outlook_user_calendars = (calendars: object) => {
  return (dispatch: any) => {
    dispatch(setOutlookUserCalendars(calendars));
  }
}
export const set_apple_user_calendars = (calendars: object) => {
  return (dispatch: any) => {
    dispatch(setAppleUserCalendars(calendars));
  }
}

export const get_planner_events = (startDate: string, endDate: string, calendars: any, googleToken: string, microsoftToken: string, appleId: string, applePass: string) => {
  return (dispatch: any) => {
    if (calendars) {
      dispatch(setLoading(true));
      let allEvents: any = [];
      const dataQueue: any = [];
      if (googleToken) {
        const callDavGoogle = new CallDavGoogle(googleToken);
        calendars.forEach((calendar: any) => {
          if (calendar.type != 'google') return;
          dataQueue.push(new Promise(async (resolve, reject) => {
            callDavGoogle.setAccessUrl(calendar.url);
            callDavGoogle.listEvents(startDate, endDate).then((events: any) => {
              if (events) {
                events = events.map((event: any) => {
                  return { ...event, calendar: calendar.url, calendarType: calendar.type, calendarName: calendar.name, color: calendar.color }
                })
                allEvents = [...events];
              }
              resolve(true);
            }).catch((error) => {
              console.log(error.message);
            });
          }));
        })
      }
      if (microsoftToken) {
        const callDavOutlook = new CallDavOutlook(microsoftToken);
        calendars.forEach((calendar: any) => {
          if (calendar.type != 'outlook') return;
          dataQueue.push(new Promise(async (resolve, reject) => {
            callDavOutlook.setAccessUrl(calendar.url);
            callDavOutlook.listEvents(startDate, endDate).then((events: any) => {
              if (events) {
                events = events.map((event: any) => {
                  return { ...event, calendar: calendar.url, calendarType: calendar.type, calendarName: calendar.name, color: calendar.color }
                })
                allEvents = [...events];
              }
              resolve(true);
            }).catch((error) => {
              console.log(error.message);
            });
          }));
        })
      }
      if (appleId && applePass) {
        const callDavApple = new CallDavApple(appleId, applePass);
        calendars.forEach((calendar: any) => {
          if (calendar.type != 'apple') return;
          dataQueue.push(new Promise(async (resolve, reject) => {
            callDavApple.setAccessUrl(calendar.url);
            callDavApple.listEvents(startDate, endDate).then((events: any) => {
              if (events) {
                events = events.map((event: any) => {
                  return { ...event, calendar: calendar.url, calendarType: calendar.type, calendarName: calendar.name, color: calendar.color }
                })
                allEvents = [...allEvents, ...events];
              }
              resolve(true);
            }).catch((error) => {
              console.log(error.message);
            });
          }))
        })
      }
      Promise.all(dataQueue).then(() => {
        dispatch(setPlannerEvents(allEvents, startDate, endDate));
        dispatch(setLoading(false));
      })
    }
  }
}

export const cleanup_planner_events = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_PLANNER_EVENTS })
  }
}

export const update_planner_event = (event: any, config: any) => {
  return (dispatch: any) => {
    const newEvent: any = {
      timezone: event.timezone,
      uid: event.uid ? event.uid : 0,
      start: moment(event.start),
      end: moment(event.end),
      summary: event.summary || '',
      description: event.description || ''
    };

    if(config.type === 'google'){
      const callDavGoogle = new CallDavGoogle(config.token);
      callDavGoogle.setAccessUrl(event.calendar);
      callDavGoogle.createEvent(newEvent)
      .then(uid => {
        console.log(uid)
        if(!uid) return;
        newEvent.uid = uid;
        newEvent.calendarType = event.calendarType;
        newEvent.calendarName = event.calendar;
        newEvent.color = event.color;
        dispatch(updatePlannerEvent(newEvent))
      })
      .catch(e => console.log(e));
    }else if(config.type === 'outlook'){
      const callDavOutlook = new CallDavOutlook(config.token);
      callDavOutlook.setAccessUrl(event.calendar);
      callDavOutlook.createEvent(newEvent)
      .then(uid => {
        console.log(uid)
        if(!uid) return;
        newEvent.uid = uid;
        newEvent.calendarType = event.calendarType;
        newEvent.calendarName = event.calendar;
        newEvent.color = event.color;
        dispatch(updatePlannerEvent(newEvent))
      })
      .catch(e => console.log(e));
    }else if(config.type === 'apple'){
      const callDavApple = new CallDavApple(config.appleId, config.applePass);
      callDavApple.setAccessUrl(event.calendar);
      callDavApple.createEvent(newEvent)
      .then(uid => {
        console.log(uid)
        if(!uid) return;
        newEvent.uid = uid;
        newEvent.calendarType = event.calendarType;
        newEvent.calendarName = event.calendar;
        newEvent.color = event.color;
        dispatch(updatePlannerEvent(newEvent))
      })
      .catch(e => console.log(e));
    }
    
  }
}

export const delete_planner_event = (config: any) => {
  return (dispatch: any) => {
    if(config.type === 'google'){
      const callDavGoogle = new CallDavGoogle(config.token);
      callDavGoogle.setAccessUrl(config.calendar);
      callDavGoogle.deleteEvent(config.uid)
      .catch(e => console.log(e));
    }else if(config.type === 'outlook'){
      const callDavOutlook = new CallDavOutlook(config.token);
      callDavOutlook.setAccessUrl(config.calendar);
      callDavOutlook.deleteEvent(config.uid)
      .catch(e => console.log(e));
    }else if(config.type === 'apple'){
      const callDavApple = new CallDavApple(config.appleId, config.applePass);
      callDavApple.setAccessUrl(config.calendar);
      callDavApple.deleteEvent(config.uid)
      .catch(e => console.log(e));
    }
    dispatch(deletePlannerEvent(config.uid))
  }
}
