import axios from 'axios';
import moment from 'moment';

class BaseRequest {
  protected _apiUrl: string
  accessUrl: string

  constructor(apiUrl: string) {
    this._apiUrl = 'https://digital-planner.com/planner/api/caldav/' + apiUrl;
    this.accessUrl = '';
  }

  setAccessUrl(accessUrl: string) {
    this.accessUrl = accessUrl
  }

  protected request(path: string, data: object) {
    return new Promise((resolve, reject) => {
      axios(this._apiUrl + path, {
          method: 'post',
          headers: {
            'content-type': 'text/plain'
          },
          data: data
        }).then((res) => {
          if(res.data.status) {
            resolve(res.data.data);
          } else {
            reject(new Error(res.data.message));
          }
        }).catch((error) => {
          reject(error);
        });
    });
  }
}

class CallDavGoogle extends BaseRequest {
  accessToken: string

  constructor(accessToken: string) {
    super('google');

    this.accessToken = accessToken;
  }

  listCalendars() {
    return this.request('/calendars', {
        access_token: this.accessToken
      });
  }

  listEvents(dateStart: any, dateEnd: any = '') {
    return this.request('/event', {
        date_start: dateStart,
        date_end: dateEnd,
        access_url: this.accessUrl,
        access_token: this.accessToken
      });
  }

  /*
    uid
    summary
    description
    created
    start
    end
    status
  */
  createEvent(event: object) {
    return this.request('/event/new',  {
        event: event,
        access_url: this.accessUrl,
        access_token: this.accessToken
      });
  }

  deleteEvent(uid: string) {
    return this.request('/event/delete', {
        uid: uid,
        access_url: this.accessUrl,
        access_token: this.accessToken
      });
  }
}

class CallDavApple extends BaseRequest {
  accessName: string
  accessPass: string

  constructor(name: string, pass: string) {
    super('apple');

    this.accessName = name;
    this.accessPass = pass;
  }

  listCalendars() {
    return this.request('/calendars', {
        access_name: this.accessName,
        access_pass: this.accessPass
      });
  }

  listEvents(dateStart: any, dateEnd: any = '') {
    return this.request('/event', {
        date_start: dateStart,
        date_end: dateEnd,
        access_url: this.accessUrl,
        access_name: this.accessName,
        access_pass: this.accessPass
      });
  }

  /*
    uid
    summary
    description
    created
    start
    end
    status
  */
  createEvent(event: object) {
    return this.request('/event/new', {
        event: event,
        access_url: this.accessUrl,
        access_name: this.accessName,
        access_pass: this.accessPass
      });
  }

  deleteEvent(uid: string) {
    return this.request('/event/delete', {
        uid: uid,
        access_url: this.accessUrl,
        access_name: this.accessName,
        access_pass: this.accessPass
      });
  }
}

class CallDavOutlook extends BaseRequest {
  accessToken: string

  constructor(accessToken: string) {
    super('outlook');

    this.accessToken = accessToken;
  }

  listCalendars() {
    return this.request('/calendars', {
        access_token: this.accessToken
      });
  }

  listEvents(dateStart: any, dateEnd: any = '') {
    return this.request('/event', {
        date_start: moment(dateStart).toISOString(true),
        date_end: dateEnd ? moment(dateEnd).toISOString(true) : dateEnd,
        access_url: this.accessUrl,
        access_token: this.accessToken
      });
  }

  /*
    uid
    summary
    description
    created
    start
    end
    status
  */
  createEvent(event: any) {
    if('start' in event && event.start) {
      event.start = moment(event.start).toISOString(true);
    }

    if('end' in event && event.end) {
      event.end = moment(event.end).toISOString(true);
    }

    return this.request('/event/new', {
        event: event,
        access_url: this.accessUrl,
        access_token: this.accessToken
      });
  }

  deleteEvent(uid: string) {
    return this.request('/event/delete', {
        uid: uid,
        access_url: this.accessUrl,
        access_token: this.accessToken
      });
  }
}

export {CallDavGoogle, CallDavApple, CallDavOutlook};
