import * as React from 'react';
import { useState } from 'react';
import firebase from "./firebase";
import sha256 from 'crypto-js/sha256';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../store/actions/auth';

const PermissionsContext = React.createContext(null);

let allPermissions: any = null;

export type Props = {
  children: any;
}

let loadingPermissions = false;

function PermissionProvider({ children }: Props) {
  const [permissions, setPermissions] = useState<any>([]);
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const role = useSelector((state: any) => state.auth.role);
  const customUid = useSelector((state: any) => state.auth.uid);
  const fbAuthUID = useSelector((state: any) => state.firebase.auth.uid);
  const dispatch = useDispatch();
  if(uid && !role) {
    dispatch(authActions.get_user_role(uid));
  }
  if(uid && role && !permissions.length && !loadingPermissions){
    // console.log('loading permissions')
    loadingPermissions = true;
    firebase.firestore().collection('config').doc('userRoles').get()
    .then((roles: any) => {
      if(roles.exists) {
        const entries: any = Object.entries(roles.data())
        for(let [key, val] of entries) {
          const hash = sha256('role'+key).toString();
          if(role == hash){
            if(fbAuthUID != customUid){
              val = val.toString().replace('writeDb', '').split(',');
            }
            setPermissions(val);
          }
        }
      }
    })
    loadingPermissions = false;
  }
  return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>
}

function useCheckPermission() {
  const context = React.useContext(PermissionsContext)
  if (context === undefined) {
    throw new Error('Permissions context error')
  }
  return context
}

export { PermissionProvider, useCheckPermission }