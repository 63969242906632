import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userDataActions from '../store/actions/userData';

type props = {
  year: any;
  hash: any;
}
export const useChangePlannerYear = () => {
  const uid = useSelector((state: any) => state.auth.uid);
  const userData = useSelector((state: any) => state.userData);
  const plannerYear = useSelector((state: any) => parseInt(state.userData.activePlanner.plannerYear));
  const dispatch = useDispatch();

  const switchYear = (year: any, hash?: any) => {
    if(plannerYear && year != plannerYear){
      const plannerConfig: any = {
        builderId: userData.activePlanner.builderId,
        fbPlannerId: userData.activePlanner.fbPlannerId,
        originalPlannerId: userData.activePlanner.originalPlannerId,
        plannerCover: userData.activePlanner.plannerCover,
        plannerName: userData.activePlanner.plannerName,
        plannerYear: year.toString(),
        weekStart: userData.activePlanner.weekStart,
        hash,
        plannerCalendars: userData.activePlanner.plannerCalendars
      }
      dispatch(userDataActions.load_planner_source(uid, {...plannerConfig}))
    }
  }
  return switchYear;
}