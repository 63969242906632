import firebase from "../../services/firebase";
import axios from 'axios';

export const LOAD_LIBRARY = 'LOAD_LIBRARY';
export const LOAD_COVERS_LIBRARY = 'LOAD_COVERS_LIBRARY';
export const LOAD_STICKERS_LIBRARY = 'LOAD_STICKERS_LIBRARY';
export const SET_LOADING = 'SET_LOADING';
export const SET_STICKERS_LOADING = 'SET_STICKERS_LOADING';
export const SET_STICKERS_NAV = 'SET_STICKERS_NAV';

const setLoading = (loading: any) => ({
  type: SET_LOADING,
  loading
});
const setStickersLoading = (loading: any) => ({
  type: SET_STICKERS_LOADING,
  loading
});
const loadLibrary = (library: any) => ({
  type: LOAD_LIBRARY,
  library
});
const loadCoversLibrary = (library: any) => ({
  type: LOAD_COVERS_LIBRARY,
  library
});
const loadStickersLibrary = (library: any, tid?: any, parent?: boolean) => ({
  type: LOAD_STICKERS_LIBRARY,
  library,
  tid,
  parent
});
const setStickersNav = (cats: any) => ({
  type: SET_STICKERS_NAV,
  cats
})

export const load_planners_library = () => {
  return (dispatch: any) => {
    dispatch(setLoading(true));
    let library:any = [];
    firebase.firestore().collection('plannerLibrary').get().then((res) => {
      res.docs.forEach((doc:any) => {
        let docData = {id: doc.id, ...doc.data()};
        library.push(docData);
      });
      dispatch(loadLibrary(library))
    }).catch((e:any) => console.log(e));
    dispatch(setLoading(false));
  }
}
export const load_covers_library = () => {
  return (dispatch: any) => {
    dispatch(setLoading(true));
    let library:any = [];
    firebase.firestore().collection('coversLibrary').get().then((res) => {
      res.docs.forEach((doc:any) => {
        let docData = {id: doc.id, ...doc.data()};
        library.push(docData);
      });
      dispatch(loadCoversLibrary(library))
    }).catch((e:any) => console.log(e));
    dispatch(setLoading(false));
  }
}

export const api_load_stickers_nav = () => {
  return (dispatch: any) => {
    dispatch(setStickersLoading(true));
    let library:any = [];
    const navUrl = 'https://digital-planner.com/planner/api/stickers/nav';
    const defaultCatUrl = 'https://digital-planner.com/planner/api/stickers?category=2033&offset=0&limit=100';
    axios.get(navUrl).then((res: any) => {
      if(res.data.cats){
        dispatch(setStickersNav(res.data.cats));
      }
    }).then(() => {
      axios.get(defaultCatUrl).then((res: any) => {
        if(res.data.items) {
          dispatch(loadStickersLibrary(res.data.items, 2033))
        }
      })
    })
    dispatch(setStickersLoading(false));
  }
}

export const api_get_stickers_category = (tid: number, parent?: boolean) => {
  return (dispatch: any) => {
    dispatch(setStickersLoading(true));
    let library:any = [];
    const url = 'https://digital-planner.com/planner/api/stickers?category='+tid+'&offset=0&limit=100';
    axios.get(url).then((res: any) => {
      if(res.data.items){
        dispatch(loadStickersLibrary(res.data.items, tid, parent))
      }
      dispatch(setStickersLoading(false));
    })
  }
}
export const api_search_stickers_library = (searchQuery: string) => {
  return (dispatch: any) => {
    dispatch(setStickersLoading(true));
    let library:any = [];
    const url = 'https://digital-planner.com/planner/api/stickers?search='+searchQuery+'&offset=0&limit=100';
    axios.get(url).then((res: any) => {
      if(res.data.items){
        dispatch(loadStickersLibrary(res.data.items))
      }
      dispatch(setStickersLoading(false));
    })
  }
}