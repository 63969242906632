import { IonButton, IonCol, IonIcon, IonInput, IonRow, IonSpinner, IonText, IonThumbnail, IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from "../../services/firebase";
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';
import * as authActions from '../../store/actions/auth';
import { trash } from 'ionicons/icons';
import './AdminStyles.css';

const AdminViewAsUser: React.FC = () => {
  const dispatch = useDispatch();
  const customUid = useSelector((state: any) => state.auth.uid);
  const fbAuthUID = useSelector((state: any) => state.firebase.auth.uid);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [customFbUID, setCustomFbUID] = useState<string>();
  const [searchVal, setSearchVal] = useState<string>('asdhomer@gmail.com');
  const [searchRes, setSearchRes] = useState<Array<[any]>>([]);

  const setNewFbUID = (uid: any) => {
    if (!uid && customUid !== fbAuthUID) {
      dispatch(authActions.set_custom_fb_uid(fbAuthUID))
      document.location.reload();
      return
    };
    dispatch(authActions.set_custom_fb_uid(uid));
    document.location.reload();
  }

  useEffect(() => {
    setCustomFbUID(customUid)
  }, [customUid])

  const searchUid = (email: string | undefined) => {
    if (email) {
      // console.log('we need firebase admin on server to perform search by users')
      firebase.firestore().collection('userList').where(firebase.firestore.FieldPath.documentId(), '==', email).get()
        .then((res) => {
          const results: any = [];
          res.forEach(result => results.push(result.data()))
          // console.log(results)
          setSearchRes([...results])
        })
        .catch(e => console.log(e));
    }
  }

  return (
    <IonRow>
      <IonCol>
        <IonRow>
          {customUid == fbAuthUID
            ? <IonText color="success">You're viewing as Admin</IonText>
            : <IonText color="danger">You're now viewing as CUSTOMER:</IonText>
          }
        </IonRow>
      </IonCol>
      <IonCol size="12" style={{ backgroundColor: 'rgb(245 245 245)' }} className="ion-margin-bottom">
        <IonRow>
          {errorMessage && <IonText color="danger">{errorMessage}</IonText>}
          <IonInput value={customFbUID == fbAuthUID ? '' : customFbUID} placeholder="Enter Firebase User UID" onIonChange={(e: any) => setCustomFbUID(e.detail.value)}></IonInput>
          {customUid != fbAuthUID &&
            <IonButton fill="clear" onClick={() => setNewFbUID('')}>
              <IonIcon slot="icon-only" icon={trash} color="danger" style={{ fontSize: 14 + 'px' }} />
            </IonButton>
          }
          <IonButton onClick={() => setNewFbUID(customFbUID)}>
            Set view as custom UID
          </IonButton>
        </IonRow>
      </IonCol>
      <IonCol size="12" style={{ backgroundColor: 'rgb(245 245 245)' }} className="ion-margin-bottom">
        <IonRow>
          <IonInput value={searchVal} placeholder="Enter user email" onIonChange={(e: any) => setSearchVal(e.detail.value)}></IonInput>
          <IonButton onClick={() => searchUid(searchVal)}>
            Search UID by email
          </IonButton>
        </IonRow>
        {searchRes.length > 0 &&
          <IonRow className="ion-margin-top ion-margin-bottom">
            <table className="serachResultTable">
              <caption>Search results</caption>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Remote UID</th>
                  <th>UID</th>
                </tr>
              </thead>
              <tbody>
                {searchRes.map((res: any, i: number) => (
                  <tr key={i}>
                    <td>{res.email}</td>
                    <td>{res.remoteUid}</td>
                    <td>{res.uid}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </IonRow>
        }
      </IonCol>
    </IonRow>
  )
}

export default AdminViewAsUser;