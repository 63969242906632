import { Route, Switch } from 'react-router-dom';
import { IonAlert, IonApp, IonRouterOutlet, useIonAlert, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useDispatch, useSelector } from 'react-redux';
import Home from './pages/Home';
import Login from './pages/Auth/Login';
import ProfileCalendars from './pages/Profile/ProfileCalendars';
import PlannerLibrary from './pages/Library/PlannerLibrary';
import Planner from './pages/Planner/Planner';
import PlannerSettings from './pages/Planner/PlannerSettings';
import PreviewAuth from './pages/Auth/PreviewAuth';
import AdminPanel from './pages/Admin/AdminPanel';
import CreateTicket from './pages/Support/CreateTicket';
import { PermissionProvider, useCheckPermission } from './services/userRolesContext';
import moment from 'moment';
import firebase from "./services/firebase";
import StackTrace from 'stacktrace-js'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom CSS */
import './theme/custom.css';

import PrivateRoute from './components/PrivateRoute';
import * as authActions from './store/actions/auth';
import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { CrashHandler } from './components/CrashHandler/CrashHandler';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.firebase.auth);
  const uid = useSelector((state: any) => state.auth.uid);
  const fbUid = useSelector((state: any) => state.firebase.auth.uid);
  const googleUsed = useSelector((state: any) => state.auth.googleUsed);
  const microsoftUsed = useSelector((state: any) => state.auth.microsoftUsed);
  // const [connection, dismissConnection] = useIonAlert();
  const userPermissions: any = useCheckPermission();

  useEffect(() => {
    dispatch(authActions.fb_user_auth());
  }, [])

  useEffect(() => {
    if (fbUid) {
      dispatch(authActions.update_user_activity());
      dispatch(authActions.set_custom_uid(fbUid));
    }
  }, [fbUid])

  useEffect(() => {
    if (googleUsed) dispatch(authActions.gapi_auth());
  }, [googleUsed])

  useEffect(() => {
    if (microsoftUsed) dispatch(authActions.msal_auth());
  }, [microsoftUsed])

  useEffect(() => {
    uid && dispatch(authActions.get_apple_credentials(uid));
  }, [uid])

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    StackTrace.fromError(error).then((r: any) => {
      const crushLog = {
        errorName: error.message,
        error: JSON.stringify(r),
        utcTime: moment().utc().format('DD-MM-YYYY HH:mm'),
      }
      firebase.firestore().collection('crushLogs').doc(moment().utc().format('DD-MM-YYYY'))
      .set({ [auth.email]: firebase.firestore.FieldValue.arrayUnion({ ...crushLog }) }, { merge: true })
      .catch((e:any) => console.log(e))
    })
      .catch(e => console.log('StackTrace Error:', e))
  }

  return (
    <ErrorBoundary FallbackComponent={CrashHandler} onError={myErrorHandler}>
      <PermissionProvider>
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/`}>
                <Home />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/planner/:id`}>
                <Planner />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/planner/settings/:id`}>
                <Route component={PlannerSettings} />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/profile/calendars`}>
                <ProfileCalendars />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/library`}>
                <PlannerLibrary />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/support`}>
                <CreateTicket />
              </PrivateRoute>
              <PrivateRoute path={`${process.env.PUBLIC_URL}/admin`}>
                <AdminPanel />
              </PrivateRoute>
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            </IonRouterOutlet>
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/preview/:builderId/:pageHash`} component={PreviewAuth} />
              <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
            </Switch>
          </IonReactRouter>

        </IonApp>
      </PermissionProvider>
    </ErrorBoundary>
  )
};

export default App;
