import { IonApp, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './crashHandler.css';
import { helpCircleOutline, refreshOutline } from 'ionicons/icons';
import firebase from "../../services/firebase";
import moment from 'moment'

type errorProps = {
  error: any;
  resetErrorBoundary: any;
}

const crushLogger = (error: Error, info: { componentStack: string }) => {
  
}

const CrashHandler: React.FC<errorProps> = ({error, resetErrorBoundary}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.firebase.auth)

  const crushLog = {
    errorName: error.message,
    fullStack: error.stack,
    time: moment().format('DD-MM-YYYY HH:mm'),

  }
  // firebase.firestore().collection('crushLogs').doc(moment().format('DD-MM-YYYY'))
  // .set({ [auth.email]: firebase.firestore.FieldValue.arrayUnion({ ...crushLog }) }, { merge: true })
  // .catch((e) => console.log(e))
  

  return (
    <IonApp>
      <IonPage id="mainContent">
        <IonContent className="ion-padding page-content-bg-gray ion-justify-center">
          <div className='errorWrapper'>
            <div className='errorWrapper2'>
              <IonCol size="12">
                <div className='errorHeader'>Oops..</div>
              </IonCol>
              <IonCol size="12" className='ion-margin-bottom'>
                <div className='errorSubHeader'>Something went wrong.</div>
              </IonCol>
              <IonCol size="12">
                <IonButton
                  color="success"
                  size='default'
                  className='ion-text-uppercase ion-margin-bottom'
                  onClick={(e: any) => { window.location.replace(`${process.env.PUBLIC_URL}/`); }}
                >
                  <IonIcon style={{ paddingRight: 5 + 'px' }} icon={refreshOutline} />
                  Reload App
                </IonButton>
                <br />
                <IonButton
                  color="tertiary"
                  fill='outline'
                  size='small'
                  className='ion-text-uppercase'
                  onClick={(e: any) => { window.location.replace(`${process.env.PUBLIC_URL}/support`); }}
                >
                  <IonIcon style={{ paddingRight: 5 + 'px' }} icon={helpCircleOutline} />
                  Contact Support
                </IonButton>
              </IonCol>
            </div>
          </div>

          {/* </div> */}
        </IonContent>
      </IonPage>
    </IonApp>
  )
}

export { CrashHandler, crushLogger }