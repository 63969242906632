import React, { useEffect, useState } from 'react';
import * as actionTypes from '../../store/actions/library';
import { useSelector, useDispatch } from 'react-redux';
import { useCheckPermission } from '../../services/userRolesContext';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonList,
  IonNav,
  IonPage,
  IonRow,
  useIonViewWillEnter
} from '@ionic/react';
import MainMenu from '../../components/UI/Menu/MainMenu';
import MainHeader from '../../components/UI/Header/MainHeader';
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';
import { Switch, useHistory, Route } from 'react-router';
import { IonReactRouter } from '@ionic/react-router';
import AdminCovers from './AdminCovers';
import AdminViewAsUser from './AdminViewAsUser';
import AdminUserList from './AdminUserList';
import AppCrashTest from './AppCrashTest';


const AdminPanel: React.FC = () => {
  const library = useSelector((state: any) => state.library);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [config, setConfig] = useState<Object>({});
  const userPermissions: any = useCheckPermission();
  const [permissionsLoaded, setPermissionsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (userPermissions.length && !permissionsLoaded) setPermissionsLoaded(true);
    if (permissionsLoaded) {
      if (!userPermissions.includes('adminViews')) {
        history.push(`${process.env.PUBLIC_URL}/`);
      }
    }
  })

  return (
    <>
      <MainMenu />
      <IonPage id="mainContent">
        <MainHeader title="Admin Panel" />
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="3">
                <IonRow>
                  <IonCol>Admin menu</IonCol>
                </IonRow>
                <IonRow>
                  <IonList>
                    <IonItem button onClick={() => history.push(`${process.env.PUBLIC_URL}/admin`)}>Admin panel</IonItem>
                    <IonItem button onClick={() => history.push(`${process.env.PUBLIC_URL}/admin/covers`)}>Covers</IonItem>
                    <IonItem button onClick={() => history.push(`${process.env.PUBLIC_URL}/admin/viewasuser`)}>View as user</IonItem>
                    <IonItem button onClick={() => history.push(`${process.env.PUBLIC_URL}/admin/userlist`)}>User list</IonItem>
                    <IonItem button onClick={() => history.push(`${process.env.PUBLIC_URL}/admin/crashtest`)}>App Crash Test</IonItem>
                    <IonItem>Planners</IonItem>
                  </IonList>
                </IonRow>
              </IonCol>
              <IonCol size="9">

                <Switch>
                  <Route exact path={`${process.env.PUBLIC_URL}/admin`}>Admin home</Route>
                  <Route exact path={`${process.env.PUBLIC_URL}/admin/covers`} component={AdminCovers} />
                  <Route exact path={`${process.env.PUBLIC_URL}/admin/viewasuser`} component={AdminViewAsUser} />
                  <Route exact path={`${process.env.PUBLIC_URL}/admin/userlist`} component={AdminUserList} />
                  <Route exact path={`${process.env.PUBLIC_URL}/admin/crashtest`} component={AppCrashTest} />
                  {/* <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} /> */}
                </Switch>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  )
}

export default AdminPanel;