import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as calendarActions from '../../../store/actions/userCalendars';
import EventModal from './EventModal/EventModal';
import AddEventModal from './EventModal/AddEventModal';
import './PlannerEvents.css';
import { Events, MonthlyEvents } from './EventsGenerator';
import { IonAlert } from '@ionic/react';
import { useHistory } from 'react-router';

type EventsProps = {
  actualPage?: number;
}

const PlannerEvents: React.FC<EventsProps> = ({ actualPage = 0 }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state: any) => state.auth);
  const appleId = useSelector((state: any) => state.auth.appleCredentials?.appleId);
  const password = useSelector((state: any) => state.auth.appleCredentials?.password);
  const curPage = useSelector((state: any) => state.plannerControls.curPage);
  const userData = useSelector((state: any) => state.userData);
  const plannerCalendars = useSelector((state: any) => state.userData.activePlanner.plannerCalendars);
  const { plannerEvents, eventsLoading, eventsStartDate, eventsEndDate } = useSelector((state: any) => state.userCalendars);
  const [curEvent, setCurEvent] = useState<string>('');
  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [addEventModal, setAddEventModal] = useState<any>(false);
  const scheduleElType = useRef<any>();
  const scheduleEl = useRef<any>();
  const [events, setEvents] = useState<any>();
  const [showNoCalAlert, setShowNoCalAlert] = useState(false);

  useEffect(() => {
    const pageHtml = document.querySelector(actualPage == 1 ? '.plannerPages.right' : '.plannerPages');
    if (!pageHtml) return;
    const weekly: any = pageHtml.querySelectorAll('div[data-type=scheduleWeekly]:not(.notSchedule)');
    const daily: any = pageHtml.querySelectorAll('div[data-type=scheduleDaily]:not(.notSchedule)');
    const monthly: any = pageHtml.querySelectorAll('div[data-type=scheduleMonthly]:not(.notSchedule)');
    let schedule: any = '';
    if(weekly.length) {
      schedule = [...weekly];
      scheduleElType.current = 'scheduleWeekly';
    }else if(daily.length) {
      schedule = [...daily];
      scheduleElType.current = 'scheduleDaily';
    }else if(monthly.length) {
      schedule = [...monthly];
      scheduleElType.current = 'scheduleMonthly';
    }
    // const schedule: any = pageHtml.querySelectorAll('div[data-type=scheduleWeekly]:not(.notSchedule)') || pageHtml.querySelectorAll('div[data-type=scheduleDaily]:not(.notSchedule)') || pageHtml.querySelectorAll('div[data-type=scheduleMonthly]:not(.notSchedule)');
    // scheduleElType.current = schedule[0]?.getAttribute('data-type');
    if (schedule.length) scheduleEl.current = [...schedule];
  })

  useEffect(() => {
    setTimeout(() => {
      if (!scheduleEl.current || !scheduleEl.current.length || !plannerEvents || !plannerCalendars || !userData || !scheduleElType.current) return;
      const newEvents: any = [];
      scheduleEl.current.forEach((schEl: any) => {
        if (scheduleElType.current === 'scheduleWeekly') {
          newEvents.push(new Events(schEl, userData, curPage, actualPage, plannerEvents, plannerCalendars, (event: any) => setCurEvent(event), (startDate: string) => plannerCalendars.length > 0 ? setAddEventModal({ startDate, show: true }) : setShowNoCalAlert(true)));
        } else if (scheduleElType.current === 'scheduleDaily') {
          newEvents.push(new Events(schEl, userData, curPage, actualPage, plannerEvents, plannerCalendars, (event: any) => setCurEvent(event), (startDate: string) => plannerCalendars.length > 0 ? setAddEventModal({ startDate, show: true }) : setShowNoCalAlert(true)));
        } else if (scheduleElType.current === 'scheduleMonthly') {
          newEvents.push(new MonthlyEvents(schEl, plannerEvents, plannerCalendars, (event: any) => setCurEvent(event)));
        }
      })
      if (newEvents.length) setEvents(newEvents);
    }, 100);
  }, [scheduleEl, curPage, plannerEvents]);

  useEffect(() => {
    setTimeout(() => {
      if (eventsLoading) return;
      setEvents(null);
      if (!scheduleEl.current || !scheduleEl.current.length || !scheduleElType.current) return;
      const loadedStartDate = moment(eventsStartDate);
      const loadedEndDate = moment(eventsEndDate);
      const pageDate = moment(userData.activePlanner?.pages[curPage + actualPage].date).startOf('day');
      if (pageDate.isBetween(loadedStartDate, loadedEndDate)) {
        const newEvents: any = [];
        scheduleEl.current.forEach((schEl: any) => {
          if (scheduleElType.current === 'scheduleWeekly') {
            newEvents.push(new Events(schEl, userData, curPage, actualPage, plannerEvents, plannerCalendars, (event: any) => setCurEvent(event), (startDate: string) => plannerCalendars.length > 0 ? setAddEventModal({ startDate, show: true }) : setShowNoCalAlert(true)));
          } else if (scheduleElType.current === 'scheduleDaily') {
            newEvents.push(new Events(schEl, userData, curPage, actualPage, plannerEvents, plannerCalendars, (event: any) => setCurEvent(event), (startDate: string) => plannerCalendars.length > 0 ? setAddEventModal({ startDate, show: true }) : setShowNoCalAlert(true)));
          } else if (scheduleElType.current === 'scheduleMonthly') {
            newEvents.push(new MonthlyEvents(schEl, plannerEvents, plannerCalendars, (event: any) => setCurEvent(event)));
          }
        })
        if (newEvents.length) setEvents(newEvents);

      } else if (actualPage !== 1) {
        const toLoadStartDate = moment(pageDate).subtract(30, 'days').startOf('day').utc().format('YYYYMMDDTHHmmss[Z]');
        const toLoadEndDate = moment(pageDate).add(30, 'days').endOf('day').utc().format('YYYYMMDDTHHmmss[Z]');
        dispatch(calendarActions.get_planner_events(toLoadStartDate, toLoadEndDate, plannerCalendars, auth.googleAccessToken, auth.microsoftAccessToken, appleId, password))
      }
    }, 100);
  }, [curPage, scheduleEl])

  useEffect(() => {
    if (curEvent) {
      setShowEventModal(true);
    }
  }, [curEvent]);

  return (
    <>
      {events && events.length && events.map((events: any, index: number) => <div key={`${index}-calendarEvents`}>{events.renderEvents()}</div>)}
      {showEventModal && <EventModal uid={curEvent} hideModal={() => { setShowEventModal(false); setCurEvent(''); }} />}
      {addEventModal && plannerCalendars.length > 0
        ? <AddEventModal startDate={addEventModal.startDate} hideModal={() => { setAddEventModal(null); }} />
        : <IonAlert
          isOpen={showNoCalAlert}
          onDidDismiss={() => setShowNoCalAlert(false)}
          header={'No active calendars'}
          message={'Add at least one Apple/Google calendar to add event'}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'noCalPopupCancelBttn',
            },
            {
              text: 'Manage calendars',
              handler: () => history.push(`${process.env.PUBLIC_URL}/planner/settings/${userData.activePlanner.fbPlannerId}`)
            }
          ]}
        />
      }
    </>
  )
}

export default PlannerEvents;