import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { authReducer } from './store/reducers/auth';
import { libraryReducer } from './store/reducers/library';
import { userDataReducer } from './store/reducers/userData';
import { userCalendarsReducer } from './store/reducers/userCalendars';
import { plannerControlsReducer } from './store/reducers/plannerControls';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'firebase/firestore';
import { ReactReduxFirebaseProvider, firebaseReducer } from "react-redux-firebase";
import firebase from "./services/firebase";
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  attachAuthIsReady: true,
}

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  library: libraryReducer,
  userData: userDataReducer,
  plannerControls: plannerControlsReducer,
  userCalendars: userCalendarsReducer,
})

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?

//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//     trace: true
//   }) : compose;
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 15 })
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}
firebase.firestore();

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider >,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();