import axios from "axios";
import firebase from "./firebase";

const addNewCover = (builderId: string) => {
  return new Promise((resolve, reject) => {
    axios.get(`https://digital-planner.com/planner/api/cover?cover_id=${builderId}&cover_text=Custom Text`)
      .then((res) => {
        const newCover = { frontImage: res.data.url_front, backImage: res.data.url_back, builderId };
        firebase.firestore().collection('coversLibrary').add(newCover).then((res) => {
          if (res && res.id) {
            resolve(true);
          }
        })
          .catch((e) => { console.log(e); reject(false); });
      })
      .catch((e) => { console.log(e); reject(false); });
  })
}

export { addNewCover }