import React, { useEffect, useState } from 'react';
import * as plannerActions from '../../../store/actions/plannerControls';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuToggle,
  IonRange,
  IonSegment,
  IonSegmentButton,
  IonToast,
  IonToolbar
} from '@ionic/react';
import {
  chevronBackOutline,
  chevronForwardOutline,
  ellipse,
  ellipsisVertical,
  extensionPuzzleOutline,
  layersOutline,
  menuOutline,
  trash
} from 'ionicons/icons';
import DrawModeColorPicker from './DrawMode/ColorPicker';
import './PlannerControlsHeader.css';
import { useCheckPermission } from '../../../services/userRolesContext';
import StageTextControls from './DrawMode/StageTextControls';
import { useHistory } from 'react-router';
import StickerSelector from '../../Planner/StickerSelector';

interface HeaderProps {
  title: string;
  goToPage: Function;
}


const PlannerControlsHeader: React.FC<HeaderProps> = ({ title, goToPage }) => {
  const userPermissions: any = useCheckPermission();
  const plannerControls = useSelector((state: any) => state.plannerControls);
  const curPage = useSelector((state: any) => state.plannerControls.curPage);
  const plannerYear = useSelector((state: any) => parseInt(state.userData.activePlanner.plannerYear));
  const dispatch = useDispatch();
  // const changePlannerYear = useChangePlannerYear();
  const plannerPages = useSelector((state: any) => state.userData.activePlanner.pages);
  const pageType = useSelector((state: any) => plannerPages ? plannerPages[curPage].pageId : '');
  const pageTypeCount = useSelector((state: any) => plannerPages ? plannerPages[curPage].pageTypeCount : '');
  const plannerId = useSelector((state: any) => state.userData.activePlanner.fbPlannerId);
  const uid = useSelector((state: any) => state.auth.uid);
  const fbuid = useSelector((state: any) => state.firebase.auth.uid);
  const [activeColor, setActiveColor] = useState('dark');
  const history = useHistory();
  const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [showStickerSelector, setShowStickerSelector] = useState(false);
  const hideSelector = () => {
    setShowStickerSelector(false)
  }

  const changeStrokeColor = (oldColor: string, newColor: string) => {
    if (!isNaN(plannerControls.selectedLine.index)) {
      const newLines = JSON.parse(plannerControls.lines[pageType][pageTypeCount]);
      newLines[plannerControls.selectedLine.index].strokeColor = newColor;
      dispatch(plannerActions.set_planner_lines(uid, plannerId, pageType, pageTypeCount, newLines));
      dispatch(plannerActions.set_lines_changed(pageType, pageTypeCount));
    } else {
      dispatch(plannerActions.set_stroke_color(newColor))
    }
  }

  const changeStrokeWidth = (newStrokeWidth: number) => {
    if (isNaN(plannerControls.selectedLine.index)) {
      dispatch(plannerActions.set_stroke_width(newStrokeWidth))
    } else {
      const newLines = JSON.parse(plannerControls.lines[pageType][pageTypeCount]);
      newLines[plannerControls.selectedLine.index].strokeWidth = newStrokeWidth;
      dispatch(plannerActions.set_stroke_width(newStrokeWidth))
      dispatch(plannerActions.set_planner_lines(uid, plannerId, pageType, pageTypeCount, newLines));
      dispatch(plannerActions.set_lines_changed(pageType, pageTypeCount));
    }
  }

  const changeEraserWidth = (newEraserWidth: number) => {
    if (isNaN(plannerControls.selectedLine.index)) {
      dispatch(plannerActions.set_eraser_width(newEraserWidth))
    }
  }

  const changeSvgColor = (oldColor: string, newColor: string) => {
    dispatch(plannerActions.replace_svg_color({ oldColor, newColor }))
  }
  const removeLine = () => {
    const newLines = JSON.parse(plannerControls.lines[pageType][pageTypeCount]);
    newLines.splice(plannerControls.selectedLine.index, 1)
    dispatch(plannerActions.set_planner_lines(uid, plannerId, pageType, pageTypeCount, newLines));
    dispatch(plannerActions.set_lines_changed(pageType, pageTypeCount));
    dispatch(plannerActions.cleanup_selected_line());
  }

  const addStageText = () => {
    const textId = (plannerControls.stageTexts[pageType] && plannerControls.stageTexts[pageType][pageTypeCount]) ? plannerControls.stageTexts[pageType][pageTypeCount].length : 0
    const textEl = {
      type: 'text',
      text: 'Text',
      x: 100,
      y: 100,
      width: 200,
      height: 50,
      fontSize: plannerControls.lastTextStyles.fontSize || 20,
      rotation: 0,
      textColor: plannerControls.lastTextStyles.textColor || (darkMode ? '#ffffff' : '#000000'),
      textId,
      fontFamily: plannerControls.lastTextStyles.fontFamily || 'Ubuntu',
    }
    dispatch(plannerActions.set_draw_mode(true));
    dispatch(plannerActions.set_draw_method('controls'));
    dispatch(plannerActions.add_stage_text(uid, userPermissions, plannerId, plannerYear, pageType, pageTypeCount, { ...textEl }));
    dispatch(plannerActions.select_stage_element({ ...textEl }));
  }

  useEffect(() => {
    setActiveColor(plannerControls.drawMode ? 'light' : 'dark');
  }, [plannerControls.drawMode])

  return (
    <div className="plannerHeader">
      <IonToast
        isOpen={uid && fbuid && uid !== fbuid}
        message="Viewing as CUSTOMER!"
        color="danger"
        position="bottom"
        cssClass="viewAsToast"
        buttons={[
          {
            side: 'end',
            text: 'Change',
            handler: () => { console.log('click'); history.push(`${process.env.PUBLIC_URL}/admin/viewasuser`) }
          }
        ]}
      />
      <div className={`toolbar plannerControlsHeader ${plannerControls.drawMode ? 'draw' : ''}`}>
        <IonButtons slot="start">
          <IonMenuToggle menu="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={menuOutline} color={activeColor}></IonIcon>
            </IonButton>
          </IonMenuToggle>
        </IonButtons>

        {plannerControls.drawMode && (
          <div className="drawModeControlsWrapper">
            {(plannerControls.selectedElement.type == 'svg' || plannerControls.selectedElement.type == 'img') && (
              <IonButtons className="ion-padding-end" slot="start">
                {plannerControls.selectedElement.type == 'svg' && (
                  <div className="svgRecolorHolder">
                    {plannerControls.selectedElement.imageColors.length < 10 && plannerControls.selectedElement.imageColors.map((color: string, index: number) => (
                      <div key={'picker-' + color} className="svgRecolorItem">
                        <DrawModeColorPicker defaultColor={color} onChangeColor={changeSvgColor} />
                      </div>
                    ))}
                  </div>
                )}
                <div className="headerDelimeter" />
                <IonButton className="ion-no-padding" fill="clear" style={{ height: 'auto' }}
                  onClick={() => {
                    dispatch(plannerActions.remove_sticker({
                      id: plannerControls.selectedElement.stickerId,
                      page: plannerControls.selectedElement.stickerPage,
                      url: plannerControls.selectedElement.imageUrl,
                      pageType,
                      pageTypeCount
                    }));
                    dispatch(plannerActions.set_stickers_changed(pageType, pageTypeCount));
                  }}>
                  <IonIcon slot="icon-only" style={{ fontSize: 20 + 'px' }} className="ion-no-padding" icon={trash} color="warning" />
                </IonButton>
                <div className="headerDelimeter" />
              </IonButtons>
            )}
            {plannerControls.selectedElement.type == 'text' && (
              <StageTextControls activeColor={activeColor} />
            )}
            {!plannerControls.selectedElement.type && (
              <IonButtons className="ion-padding-end" slot="end">
                <>
                  {!isNaN(plannerControls.selectedLine.index) &&
                    (
                      <>
                        <IonButton onClick={() => removeLine()}>
                          <IonIcon slot="icon-only" icon={trash} size="small" color="danger" />
                        </IonButton>
                        <div className="headerDelimeter" />
                      </>
                    )}
                  {(!isNaN(plannerControls.selectedLine.index) ||
                    (isNaN(plannerControls.selectedLine.index)
                      && plannerControls.drawMethod != 'eraser'
                      && plannerControls.drawMethod != 'controls'
                    )) && (
                      <DrawModeColorPicker defaultColor={plannerControls.selectedLine.strokeColor || plannerControls.strokeColor} onChangeColor={changeStrokeColor} />
                    )}
                  {(!isNaN(plannerControls.selectedLine.index) || plannerControls.drawMethod != 'controls') && (
                    <>
                      <div className="headerDelimeter" />
                      {plannerControls.drawMethod == 'eraser'
                        ? <IonRange
                          style={{ maxWidth: 200 + 'px' }}
                          color={activeColor}
                          className="ion-no-padding"
                          min={5}
                          max={200}
                          step={10}
                          value={plannerControls.eraserWidth}
                          defaultValue={plannerControls.eraserWidth}
                          onIonChange={(e) => changeEraserWidth(e.detail.value as number)}>
                          <IonIcon size="small" slot="start" icon={ellipse} color={activeColor} className="colorCircleIcon" />
                          <IonIcon size="large" slot="end" icon={ellipse} color={activeColor} className="colorCircleIcon" />
                        </IonRange>

                        : <IonRange
                          style={{ maxWidth: 200 + 'px' }}
                          color={activeColor}
                          className="ion-no-padding"
                          min={1}
                          max={20}
                          step={1}
                          value={plannerControls.strokeWidth}
                          defaultValue={plannerControls.selectedLine.strokeWidth || plannerControls.strokeWidth}
                          onIonChange={(e) => changeStrokeWidth(e.detail.value as number)}>
                          <IonIcon size="small" slot="start" icon={ellipse} color={activeColor} className="colorCircleIcon" />
                          <IonIcon size="large" slot="end" icon={ellipse} color={activeColor} className="colorCircleIcon" />
                        </IonRange>
                      }
                      <div className="headerDelimeter" />
                    </>
                  )}
                </>
              </IonButtons>
            )}
          </div>
        )}
        <IonButtons slot="end" className="ion-padding-end plannerHeaderBttns">
          <IonButton className="headerIconBttn openStickerSelectorBttn" onClick={() => dispatch(plannerActions.set_show_sticker_selector(!plannerControls.showStickerSelector))} slot="icon-only">
            <IonIcon src={`${process.env.PUBLIC_URL}/assets/icon/app-sticker.svg`} color={activeColor} />
          </IonButton>
          <IonButton className="headerIconBttn addStageTextBttn" onClick={() => addStageText()} slot="icon-only">
            <IonIcon src={`${process.env.PUBLIC_URL}/assets/icon/app-add-text.svg`} color={activeColor} />
          </IonButton>
          <div className="headerDelimeter" />
          <IonSegment
            className="header-tool-segment ion-margin-end"
            value={plannerControls.drawMethod}
            onIonChange={e => {
              if (e.detail.value !== 'links' && !plannerControls.drawMode) dispatch(plannerActions.set_draw_mode(true));
              if (e.detail.value == 'links' && plannerControls.drawMode) dispatch(plannerActions.set_draw_mode(false));
              dispatch(plannerActions.set_draw_method(e.detail.value!))
            }
            }
          >
            <IonSegmentButton className="header-segment-buttons modePenBttn" value="pen">
              <IonIcon src={`${process.env.PUBLIC_URL}/assets/icon/app-draw.svg`} color={plannerControls.drawMethod == 'pen' ? '' : activeColor} />
            </IonSegmentButton>
            <IonSegmentButton className="header-segment-buttons modeEraserBttn" value="eraser">
              <IonIcon src={`${process.env.PUBLIC_URL}/assets/icon/app-eraser.svg`} color={plannerControls.drawMethod == 'eraser' ? '' : activeColor} />
            </IonSegmentButton>
            <IonSegmentButton className="header-segment-buttons modeControlsBttn" value="controls">
              <IonIcon src={`${process.env.PUBLIC_URL}/assets/icon/app-move.svg`} color={plannerControls.drawMethod == 'controls' ? '' : activeColor} />
            </IonSegmentButton>
            <IonSegmentButton className="header-segment-buttons modeLinksBttn" value="links">
              <IonIcon src={`${process.env.PUBLIC_URL}/assets/icon/app-interact.svg`} color={plannerControls.drawMethod == 'links' ? '' : activeColor} />
            </IonSegmentButton>
          </IonSegment>

          <IonButton
            className="FitPlannerToPage"
            slot="icon-only" onClick={() => dispatch(plannerActions.set_planner_fit(!plannerControls.plannerFit))}>
            <IonIcon src={`${process.env.PUBLIC_URL}/assets/icon/app-fit-page-width.svg`}
              // color={plannerControls.plannerFit ? 'primary' : 'dark'} 
              color={activeColor}
            />
          </IonButton>

          <div className="ion-margin-start headerPager" style={{ border: '1px solid rgb(245 242 242)', borderRadius: 5 + 'px', flexWrap: 'nowrap', display: 'flex' }}>
            <IonButton
              className="plannerPrevPage"
              slot="icon-only" onClick={() => goToPage(false, true)}>
              <IonIcon icon={chevronBackOutline} color={activeColor} />
            </IonButton>
            <IonMenuToggle menu="end">
              <IonButton className="plannerMenuIconBttn">
                <IonIcon slot="icon-only" className="plannerMenuIcon" src={`${process.env.PUBLIC_URL}/assets/icon/app-layouts.svg`} size="small" color={activeColor}></IonIcon>
              </IonButton>
            </IonMenuToggle>
            <IonButton
              className="plannerNextPage"
              slot="icon-only" onClick={() => goToPage(true, false)}>
              <IonIcon icon={chevronForwardOutline} color={activeColor} />
            </IonButton>
          </div>

        </IonButtons>
      </div>
    </div>
  )
}

export default PlannerControlsHeader;