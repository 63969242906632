import * as authActions from '../../store/actions/auth';
import * as plannerControls from '../../store/actions/plannerControls';
import * as userDataActions from '../../store/actions/userData';
import { useSelector, useDispatch } from 'react-redux';
import { isLoaded, isEmpty } from "react-redux-firebase";
import './Login.css';
import { Redirect, RouteComponentProps, useHistory } from 'react-router';
import { useEffect, useState } from 'react';

export interface previewAuthProps extends RouteComponentProps<{
  builderId: string;
  pageHash: string;
}> { }

const Login: React.FC<previewAuthProps> = ({ match }: any) => {
  const previewUsers = ['QmxCsE17eqYDeh8EcCXF8WoA40k2'];
  const history = useHistory();
  const authState = useSelector((state: any) => state.auth);
  const userData = useSelector((state: any) => state.userData);
  const authFB = useSelector((state: any) => state.firebase.auth);
  const uid = useSelector((state: any) => state.auth.uid);
  const dispatch = useDispatch();

  useEffect(() => {
    if(isLoaded(authFB) && !isEmpty(authFB) && !authState.loading && !previewUsers.includes(uid)){
      if(!userData.userPlanners.length) return;
      const planner = userData.userPlanners.find((planner: any) => planner.builderId == match.params.builderId);
      if (planner) {
        if(history.location.pathname.includes(`${process.env.PUBLIC_URL}/planner/${planner.fbPlannerId}/#${match.params.pageHash}`)) return;
        history.push(`${process.env.PUBLIC_URL}/planner/${planner.fbPlannerId}/#${match.params.pageHash}`);
      }else{
        dispatch(authActions.user_logout());
        dispatch(plannerControls.cleanup_planner_data());
        dispatch(userDataActions.cleanup_active_planner());
      }
    }else if(isLoaded(authFB) && isEmpty(authFB) && !authState.loading){
      dispatch(authActions.user_login('preview@evopaper.com', 'evopreview'))
    }
  })

  useEffect(() => {
    if(userData.userPlanners.length){
      const planner = userData.userPlanners.find((planner: any) => planner.builderId == match.params.builderId);
      if(planner && !history.location.pathname.includes(`${process.env.PUBLIC_URL}/planner/${planner.fbPlannerId}/#${match.params.pageHash}`)) {
        history.push(`${process.env.PUBLIC_URL}/planner/${planner.fbPlannerId}/#${match.params.pageHash}`);
      }
    }
  }, [userData.userPlanners])

  return (
    <></>
  );
};

export default Login;
