import { IonCol, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import './AdminStyles.css';

const AdminUserList: React.FC = () => {

  useEffect(() => {
    throw new Error('APP CRASH TEST')
  }, [])

  return (
    <IonRow>
      <IonCol size="12" className="ion-margin-bottom">
        App Crash Test
      </IonCol>
    </IonRow>
  )
}

export default AdminUserList;