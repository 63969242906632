import * as plannerActions from '../../../store/actions/plannerControls';
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCheckPermission } from '../../../services/userRolesContext';

const PlannerChangesWatcher = () => {
  const dispatch = useDispatch();
  const userPermissions: any = useCheckPermission();
  const plannerId = useSelector((state: any) => state.userData.activePlanner.fbPlannerId);
  const plannerYear = useSelector((state: any) => state.userData.activePlanner.plannerYear);
  const uid = useSelector((state: any) => state.auth.uid);
  const userData = useSelector((state: any) => state.userData);
  const plannerControls = useSelector((state: any) => state.plannerControls);
  const pageType = useRef<any>();
  const pageTypeCount = useRef<any>();
  const pageTypeCorrection = useRef<any>(0);
  const updateFrequency = 5000; // 5 sec
  //Lines changes
  const linesChanged = useSelector((state: any) => state.plannerControls.linesChanged);
  const awaitingForSaveLines = useRef<boolean>(false);
  const saveLinesTimer = useRef<any>();
  //Planner data changes
  const plannerDataChanged = useSelector((state: any) => state.plannerControls.plannerDataChanged);
  const awaitingForSavePlannerData = useRef<boolean>(false);
  const savePlannerDataTimer = useRef<any>();
  //Stickers changes
  const stickersChanged = useSelector((state: any) => state.plannerControls.stickersChanged);
  const awaitingForSaveStickers = useRef<boolean>(false);
  const saveStickersTimer = useRef<any>();
  //Stage Texts changes
  const stageTextsChanged = useSelector((state: any) => state.plannerControls.stageTextsChanged);
  const awaitingForSaveStageTexts = useRef<boolean>(false);
  const saveStageTextsTimer = useRef<any>();

  useEffect(() => {
    if (userData.activePlanner && userData.activePlanner.pages) {
      pageType.current = userData.activePlanner.pages[plannerControls.curPage].pageId;
      pageTypeCount.current = userData.activePlanner.pages[plannerControls.curPage].pageTypeCount;
      if (pageTypeCount.current % 2) {
        pageTypeCorrection.current = pageTypeCount.current - 1;
      } else {
        pageTypeCorrection.current = pageTypeCount.current;
      }
    }

  }, [plannerControls.curPage]);

  useEffect(() => {
    if (Object.keys(linesChanged).length !== 0) {
      if (awaitingForSaveLines.current) clearTimeout(saveLinesTimer.current);
      awaitingForSaveLines.current = true;
      const fixedLinesChanged = { ...linesChanged };
      if(plannerControls.drawing) {clearTimeout(saveLinesTimer.current);} else {
      saveLinesTimer.current = setTimeout(() => {
        dispatch(plannerActions.autosave_planner_lines_to_db(uid, userPermissions, plannerId, fixedLinesChanged, plannerControls.lines, plannerYear));
        dispatch(plannerActions.cleanup_lines_changes());
        awaitingForSaveLines.current = false;
      }, updateFrequency);
    }
    }
  }, [linesChanged]);

  useEffect(() => {
    if (Object.keys(plannerDataChanged).length !== 0) {
      if (awaitingForSavePlannerData.current) clearTimeout(savePlannerDataTimer.current);
      awaitingForSavePlannerData.current = true;
      const fixedDataChanged = { ...plannerDataChanged };
      savePlannerDataTimer.current = setTimeout(() => {
        dispatch(plannerActions.autosave_planner_data_to_db(uid, userPermissions, plannerId, fixedDataChanged, plannerControls.plannerData, plannerYear));
        dispatch(plannerActions.cleanup_planner_data_changes());
        awaitingForSavePlannerData.current = false;
      }, updateFrequency);
    }
  }, [plannerDataChanged]);

  useEffect(() => {
    if (Object.keys(stickersChanged).length !== 0) {
      if (awaitingForSaveStickers.current) clearTimeout(saveStickersTimer.current);
      awaitingForSaveStickers.current = true;
      const fixedStickersChanged = { ...stickersChanged };
      if(Object.keys(plannerControls.stickers).length === 0) return;
      saveStickersTimer.current = setTimeout(() => {
        dispatch(plannerActions.autosave_stickers_to_db(uid, userPermissions, plannerId, fixedStickersChanged, plannerControls.stickers, plannerYear));
        dispatch(plannerActions.cleanup_stickers_changes());
        awaitingForSaveStickers.current = false;
      }, updateFrequency);
    }
  }, [stickersChanged]);
  
  useEffect(() => {
    if (Object.keys(stageTextsChanged).length !== 0) {
      if (awaitingForSaveStageTexts.current) clearTimeout(saveStageTextsTimer.current);
      awaitingForSaveStageTexts.current = true;
      const fixedStageTextsChanged = { ...stageTextsChanged };
      if(Object.keys(plannerControls.stageTexts).length === 0) return;
      saveStageTextsTimer.current = setTimeout(() => {
        dispatch(plannerActions.autosave_stage_texts_to_db(uid, userPermissions, plannerId, fixedStageTextsChanged, plannerControls.stageTexts, plannerYear));
        dispatch(plannerActions.cleanup_stage_texts_changes());
        awaitingForSaveStageTexts.current = false;
      }, updateFrequency);
    }
  }, [stageTextsChanged]);

  return <></>
}

export default PlannerChangesWatcher;