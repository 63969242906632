import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonSegmentButton,
  IonSpinner,
  IonThumbnail,
  useIonViewWillEnter,
  IonSegment,
  useIonViewDidEnter
} from '@ionic/react';
import { settings } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import * as userDataActions from '../store/actions/userData';
import { isLoaded, isEmpty } from "react-redux-firebase";
import './Home.css';


import MainMenu from '../components/UI/Menu/MainMenu';
import MainHeader from '../components/UI/Header/MainHeader';
import LoadingSpinner from '../components/UI/Helpers/LoadingSpinner';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { logScreenViewEvent } from '../services/logger';
import { HomeGuides } from '../components/Guides/Guides';

const Home: React.FC<any> = () => {
  const history = useHistory();
  const auth = useSelector((state: any) => state.firebase.auth);
  const uid = useSelector((state: any) => state.auth.uid);
  const userData = useSelector((state: any) => state.userData);
  const dispatch = useDispatch();
  const [activePlanners, setActivePlanners] = useState<any>();
  const [archivedPlanners, setArchivedPlanners] = useState<any>();
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [userGuides, setUserGuides] = useState<any>();


  useIonViewWillEnter(() => {
    document.title = 'Home - Digital Planner App';
    logScreenViewEvent();
  }, [])

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth) && uid && !userData.userPlanners.length && !userData.loading)
      dispatch(userDataActions.load_user_planners(uid));
  }, [auth, uid])

  const createNewPlanner = (
    <IonCol size="4" sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="3" key='createNewPlanner' className="createNewPlanner">
      <IonCard>
        <IonCardContent className="ion-no-padding" onClick={() => history.push(`${process.env.PUBLIC_URL}/library`)}>
          <div><IonThumbnail style={{ width: 100 + '%', height: 'auto' }}><img src={`${process.env.PUBLIC_URL}/assets/img/create-planner.jpg`} /></IonThumbnail></div>
          <div className="ion-padding ion-text-center"> <h2 style={{ fontWeight: 500 }}>Create new planner</h2></div>
        </IonCardContent>
      </IonCard>
    </IonCol>
  )

  useEffect(() => {
    const activePlannersArr: any = [];
    const archivedPlannersArr: any = [];
    if (userData.userPlanners.length) {
      userData.userPlanners.forEach((planner: any, index: number) => {
        if (!planner.archived) {
          activePlannersArr.push((
            <IonCol size="4" sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="3" key={planner.fbPlannerId}>
              <IonCard>
                <IonCardContent className="ion-no-padding" onClick={() => {
                  dispatch(userDataActions.load_planner_source(auth.uid, planner));
                  history.push(`${process.env.PUBLIC_URL}/planner/${planner.fbPlannerId}/`);
                }}>
                  <div>
                    <div className="plannerSettingsBttn">
                      <IonIcon icon={settings} onClick={(e: any) => { e.stopPropagation(); history.push(`${process.env.PUBLIC_URL}/planner/settings/${planner.fbPlannerId}`); }} />
                    </div>
                    <IonThumbnail style={{ width: 100 + '%', height: 'auto' }}>
                      <img src={planner.plannerCover?.customFrontImage || `${process.env.PUBLIC_URL}/assets/img/default-cover.jpg`} />
                    </IonThumbnail>
                  </div>

                  <div className="ion-padding ion-text-center">
                    {/* Builder ID: <a href={'https://makeplanner.com/builder?id=' + planner.builderId} target="_blank">{planner.builderId}</a> */}
                    <h2 style={{ fontWeight: 500 }}>{planner.plannerName}</h2>
                  </div>

                </IonCardContent>
              </IonCard>
            </IonCol>
          ))
        }
      }
      );
      // activePlannersArr.push(createNewPlanner);

      userData.userPlanners.map((planner: any, index: number) => {
        if (planner.archived) {
          archivedPlannersArr.push((
            <IonCol size="4" sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="3" key={planner.fbPlannerId}>
              <IonCard>
                <IonCardContent className="ion-no-padding">
                  <div>
                    <div className="plannerSettingsBttn">
                      <IonIcon icon={settings} onClick={(e: any) => { e.stopPropagation(); history.push(`${process.env.PUBLIC_URL}/planner/settings/${planner.fbPlannerId}`); }} />
                    </div>
                    <IonThumbnail style={{ width: 100 + '%', height: 'auto' }}>
                      <img src={planner.plannerCover?.customFrontImage || `${process.env.PUBLIC_URL}/assets/img/default-cover.jpg`} />
                    </IonThumbnail>
                  </div>

                  <div className="ion-padding ion-text-center">
                    {/* Builder ID: <a href={'https://makeplanner.com/builder?id=' + planner.builderId} target="_blank">{planner.builderId}</a> */}
                    <h2 style={{ fontWeight: 500 }}>{planner.plannerName}</h2>
                  </div>

                </IonCardContent>
              </IonCard>
            </IonCol>
          ))
        }
      }
      );
      setActivePlanners(activePlannersArr);
      setArchivedPlanners(archivedPlannersArr);
    }
  }, [userData.userPlanners])

  useIonViewDidEnter(() => {
    setUserGuides((<HomeGuides />));
  })

  return (
    <> 
      {userGuides}
      <MainMenu />
      <IonPage id="mainContent">
        <MainHeader title="My Bookshelf" />
        <IonContent className="ion-padding page-content-bg-gray">
          {userData.loading
            ? <LoadingSpinner />
            : <IonGrid fixed={true}>
              <IonRow class="ion-justify-content-center">
                <IonCol sizeXs="12" sizeMd="6">
                  <IonSegment
                    className="guideStep1"
                    style={{
                      border: '1px solid #e4e4e6',
                      borderRadius: 10 + 'px'
                    }}
                    value={showArchived ? 'showArchived' : 'showActive'}
                    onIonChange={(e) => {
                      e.detail.value == 'showActive' ? setShowArchived(false) : setShowArchived(true);
                    }}>
                    <IonSegmentButton value="showActive" color={showArchived ? 'dark' : 'primary'} >Active ({activePlanners ? activePlanners.length - 1 : 0})</IonSegmentButton>
                    <IonSegmentButton value="showArchived" color={showArchived ? 'primary' : 'dark'} >Archived ({archivedPlanners ? archivedPlanners.length : 0})</IonSegmentButton>
                  </IonSegment>
                </IonCol>
              </IonRow>
              {!showArchived
                ? <IonRow className="ion-justify-content-center guideStep2">
                  {activePlanners}
                  {createNewPlanner}
                </IonRow>
                : <IonRow className="ion-justify-content-center">
                  {archivedPlanners && archivedPlanners.length
                    ? archivedPlanners
                    : <IonCol size="12" style={{ textAlign: 'center', paddingTop: 50 + 'px' }}>
                      <h2 className="ion-text-center">You don't have archived planners yet</h2>
                    </IonCol>
                  }
                </IonRow>
              }
            </IonGrid>
          }
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
