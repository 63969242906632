import {
  IonContent,
  IonPage,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonSpinner,
  IonText,
  IonCol,
  IonIcon,
  IonRow,
  IonThumbnail,
  IonGrid,
  useIonViewDidEnter
} from '@ionic/react';
import * as actionTypes from '../../store/actions/auth';
import { useSelector, useDispatch } from 'react-redux';
import { isLoaded, isEmpty } from "react-redux-firebase";
import './Login.css';
import { Redirect } from 'react-router';
import { useEffect, useState } from 'react';
import { arrowBack } from 'ionicons/icons';
import firebase from "../../services/firebase";
import { logScreenViewEvent } from '../../services/logger';

const Login: React.FC = () => {
  const authState = useSelector((state: any) => state.auth)
  const [userName, setUserName] = useState<string>('');
  const [userPass, setUserPass] = useState<string>('');
  const [warning, setWarning] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [infoMessage, setInfoMessage] = useState<string>('');
  const authFB = useSelector((state: any) => state.firebase.auth);
  const [recUserPass, setRecUserPass] = useState<string>('');
  const [recUserPass2, setRecUserPass2] = useState<string>('');
  const [regUserPass, setRegUserPass] = useState<string>('');
  const [regUserPass2, setRegUserPass2] = useState<string>('');
  const dispatch = useDispatch();
  const [passwordResetRequest, setPasswordResetRequest] = useState<boolean>(false);
  const [registrationMode, setRegistrationMode] = useState<boolean>(false);

  useIonViewDidEnter(() => {
    document.title = 'Digital Planner App - Login';
    logScreenViewEvent();
  }, [])

  const loginUser = (e: React.FormEvent) => {
    e.preventDefault();
    setWarning('');
    setLoading(true);
    try {
      dispatch(actionTypes.user_login(userName, userPass))
    } catch (error) {
      setWarning('No such user or wrong password');
      setLoading(false);
    }
  }
  
  const registerUser = (e: any) => {
    e.preventDefault();
    if(e.target.regUserPass.value !== e.target.regUserPass2.value) {
      setWarning('Passwords doesn\'t match');
      return;
    }
    setWarning('');
    setLoading(true);
    try {
      dispatch(actionTypes.user_registration(e.target.regUserEmail.value, e.target.regUserPass.value))
    } catch (error) {
      setWarning('Something went wrong. Please try later.');
      setLoading(false);
    }
  }

  const regRecognizedUser = (e: React.FormEvent) => {
    e.preventDefault();
    // setWarning('');
    setLoading(true);
    try {
      dispatch(actionTypes.register_recognized_user(userName, userPass, authState.remoteUid))
    } catch (error) {
      setWarning('Something went wrong');
      setLoading(false);
    }
  }

  const resetPassword = (e: React.FormEvent) => {
    e.preventDefault();
    if (!userName) return;
    firebase.auth().sendPasswordResetEmail(userName)
      .then(() => {
        setInfoMessage('Password reset instructions sent to your email');
        setPasswordResetRequest(false);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  return (
    // <IonPage >
    <>
      {isLoaded(authFB) && !isEmpty(authFB) && !authState.loading ? <Redirect to="/" /> : ''}
      <IonContent className="login-content page-content-bg-gray">
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="ion-text-center">
                <IonThumbnail style={{ width: 200 + 'px', height: 'auto', display: 'inline-block' }}>
                  <img src={`${process.env.PUBLIC_URL}/assets/icon/logo.png`} />
                </IonThumbnail>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="4">
              {!authState.loginEmail && !authState.loginPassWrong && !passwordResetRequest && !registrationMode &&
                <IonCard className="login-card">
                  <IonCardHeader>
                    <IonCardTitle className="ion-text-center">User Login</IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>
                    {authState.error && <IonItem color="warning"><IonLabel className="ion-text-wrap">{authState.error}</IonLabel></IonItem>}
                    {infoMessage && <IonItem color="success"><IonLabel className="ion-text-wrap">{infoMessage}</IonLabel></IonItem>}
                    <form onSubmit={loginUser} name="login" className="loginForm">
                      <IonList>
                        <IonItem class="ion-margin-bottom ion-no-padding">
                          <IonLabel position="floating">Your email:</IonLabel>
                          <IonInput type="email" value={userName} required onIonChange={(e: any) => setUserName(e.detail.value)}></IonInput>
                        </IonItem>
                        <IonItem class="ion-margin-bottom ion-no-padding">
                          <IonLabel position="floating">Your password:</IonLabel>
                          <IonInput type="password" value={userPass} required onIonChange={(e: any) => setUserPass(e.detail.value)}></IonInput>
                        </IonItem>
                        <IonButton type="submit" disabled={authState.loading} className="ion-margin-bottom" expand="block">
                          {authState.loading && <IonSpinner slot="start" name="lines-small" />}
                          Login
                        </IonButton>
                        <IonRow className="ion-justify-content-between">
                          {/* <IonCol size="12" className="ion-justify-content-center"> */}
                          <IonButton className="ion-no-padding" fill="clear" size="small" onClick={() => { setWarning(''); setPasswordResetRequest(true) }}>
                            Forgot password?
                          </IonButton>
                          <IonButton className="ion-no-padding" fill="clear" size="small" onClick={() => { setWarning(''); setRegistrationMode(true) }}>
                            Don't have an account?
                          </IonButton>
                          {/* </IonCol> */}
                        </IonRow>
                      </IonList>
                    </form>
                  </IonCardContent>
                </IonCard>
              }
              {authState.loginEmail && authState.loginPassWrong &&
                <IonCard className="login-card">
                  <IonCardHeader>
                    <IonCardTitle className="ion-text-center">Password confirmation</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonText>We've found your email at OnPlanners.com but password does't match. You can set a new password for app.</IonText>

                    <form onSubmit={regRecognizedUser} name="regRecognizedUser">
                      <IonList>
                        <IonItem class="ion-margin-bottom">
                          <IonLabel position="floating">Your email:</IonLabel>
                          <IonInput type="email" value={authState.loginEmail} required disabled={true}></IonInput>
                        </IonItem>
                        <IonItem class="ion-margin-bottom">
                          <IonLabel position="floating">New password:</IonLabel>
                          <IonInput type="password" value={recUserPass} required onIonChange={(e: any) => setRecUserPass(e.detail.value)}></IonInput>
                        </IonItem>
                        <IonItem class="ion-margin-bottom">
                          <IonLabel position="floating">Confirm new password:</IonLabel>
                          <IonInput type="password" value={recUserPass2} required onIonChange={(e: any) => setRecUserPass2(e.detail.value)}></IonInput>
                        </IonItem>
                        <IonButton type="submit" disabled={authState.loading} className="ion-margin-bottom" expand="block">
                          {authState.loading && <IonSpinner slot="start" name="lines-small" />}
                          Finish registration
                        </IonButton>
                        {/* <IonCol size="12"> */}
                        <IonButton fill="clear" size="small" onClick={() => { setWarning(''); dispatch(actionTypes.reset_recognized_user()) }}>
                          <IonIcon icon={arrowBack} slot="start" /> Try another password
                        </IonButton>
                        {/* </IonCol> */}
                      </IonList>
                    </form>
                  </IonCardContent>
                </IonCard>
              }

              {passwordResetRequest && (
                <IonCard className="login-card">
                  <IonCardHeader>
                    <IonCardTitle className="ion-text-center">Password Recovery</IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>
                    {authState.error && <IonItem color="warning"><IonLabel className="ion-text-wrap">{authState.error}</IonLabel></IonItem>}
                    <form onSubmit={resetPassword} name="login">
                      <IonList>
                        <IonItem class="ion-margin-bottom">
                          <IonLabel position="floating">Your email:</IonLabel>
                          <IonInput type="email" value={userName} required onIonChange={(e: any) => setUserName(e.detail.value)}></IonInput>
                        </IonItem>

                        <IonButton type="submit" disabled={authState.loading} className="ion-margin-bottom" expand="block">
                          {authState.loading && <IonSpinner slot="start" name="lines-small" />}
                          Reset password
                        </IonButton>
                      </IonList>
                      {/* <IonCol size="12"> */}
                      <IonButton fill="clear" size="small" onClick={() => setPasswordResetRequest(false)}>
                        <IonIcon icon={arrowBack} slot="start" /> Back to login
                      </IonButton>
                      {/* </IonCol> */}
                    </form>
                  </IonCardContent>
                </IonCard>
              )}

              {registrationMode && (
                <IonCard className="login-card">
                  <IonCardHeader>
                    <IonCardTitle className="ion-text-center">User Registration</IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>
                    {warning && <IonItem lines="none" color="warning" className="ion-margin-bottom"><IonLabel className="ion-text-wrap">{warning}</IonLabel></IonItem>}
                    <form onSubmit={registerUser} name="registration">
                      <IonList>
                        <IonItem class="">
                          <IonLabel position="floating">Your email:</IonLabel>
                          <IonInput type="email" name="regUserEmail" value={userName} required onIonChange={(e: any) => setUserName(e.detail.value)}></IonInput>
                        </IonItem>

                        <IonItem class="">
                          <IonLabel position="floating">Your password:</IonLabel>
                          <IonInput type="password" name="regUserPass" value={regUserPass} required onIonChange={(e: any) => setRegUserPass(e.detail.value)}></IonInput>
                        </IonItem>

                        <IonItem class="">
                          <IonLabel position="floating">Confirm password:</IonLabel>
                          <IonInput type="password" name="regUserPass2" value={regUserPass2} required onIonChange={(e: any) => setRegUserPass2(e.detail.value)}></IonInput>
                        </IonItem>

                        <IonButton type="submit" disabled={authState.loading} className="ion-margin-top ion-margin-bottom" expand="block">
                          {authState.loading && <IonSpinner slot="start" name="lines-small" />}
                          Finish registration
                        </IonButton>
                      </IonList>
                      <IonButton fill="clear" size="small" onClick={() => setRegistrationMode(false)}>
                        <IonIcon icon={arrowBack} slot="start" /> Back to login
                      </IonButton>
                    </form>
                  </IonCardContent>
                </IonCard>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {/* </IonPage> */}
    </>
  );
};

export default Login;
