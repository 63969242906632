import firebase from "./firebase";

export const logScreenViewEvent = (screenName) => {
  if (screenName) {
    firebase.analytics().logEvent('screen_view', {
      screen_name: screenName
    })
  } else {
    firebase.analytics().logEvent('screen_view')
  }
}