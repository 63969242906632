import * as actionTypes from '../actions/userData';

const initialState = {
    loading: false,
    activePlannerLoading: false,
    staticPagesLoading: false,
    activePlanner: [],
    userPlanners: [],
    pagerStep: 1,
}

export const userDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CLEANUP_ALL_DATA:
            return {
                ...initialState
            }
        case actionTypes.SET_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case actionTypes.CLEAN_UP_ACTIVE_PLANNER:
            return {
                ...state,
                activePlanner: []
            }
        case actionTypes.SET_ACTIVE_PLANNER_LOADING:
            return {
                ...state,
                activePlannerLoading: action.loading
            }
        case actionTypes.SET_STATIC_PAGES_LOADING:
            return {
                ...state,
                staticPagesLoading: action.loading
            }
        case actionTypes.SET_ACTIVE_PLANNER:
            return {
                ...state,
                activePlanner: action.activePlanner
            }
        case actionTypes.SAVE_NEW_PLANNER:
            return {
                ...state,
                userPlanners: [...state.userPlanners, action.newPlanner]
            }
        case actionTypes.LOAD_USER_PLANNERS:
            return {
                ...state,
                userPlanners: action.userPlanners
            }
        case actionTypes.DELETE_USER_PLANNER:
            return {
                ...state,
                userPlanners: [...state.userPlanners.filter((planner:any) => planner.fbPlannerId !== action.plannerId)],
            }
        case actionTypes.UPDATE_PLANNER_CALENDARS:
            const updatedPlanners = state.userPlanners.map((planner: any) => {
                if(planner.fbPlannerId == action.plannerId) {
                    return {...planner, plannerCalendars: action.plannerCalendars}
                }else{
                    return {...planner}
                }
            })

            return {
                ...state,
                userPlanners: [...updatedPlanners],
            }
        case actionTypes.SET_PAGER_STEP:
            return {
                ...state,
                pagerStep: action.step
            }
        case actionTypes.UPDATE_PLANNER_SECTIONS:
            const activePlanner = JSON.parse(JSON.stringify(state.activePlanner));
            activePlanner.staticPages.forEach((page: any) => page.pageId == action.pageId ? page.pageDisabled = action.newState : null);
            
            return {
                ...state,
                activePlanner: {...activePlanner}
            }
        case actionTypes.SET_PLANNER_NAME:
            const activePlanner2 = JSON.parse(JSON.stringify(state.activePlanner));
            activePlanner2.plannerName =  action.name;
            
            return {
                ...state,
                activePlanner: {...activePlanner2}
            }
        default:
            return {
                ...state
            }
    }

}