import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonRow, IonThumbnail, IonToggle, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MainMenu from '../../components/UI/Menu/MainMenu';
import MainHeader from '../../components/UI/Header/MainHeader';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from "react-redux-firebase";
import * as userDataActions from '../../store/actions/userData'
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';
import { archiveOutline, arrowBackOutline, book, calendar, powerOutline, trashBinOutline } from 'ionicons/icons';
import ChooseCoverPopup from '../Library/ChooseCoverModal';
import ChooseCalendarsPopup from '../Library/ChooseCalendarsModal';
import FontSelector from '../../components/Planner/FontSelector';
import { useCheckPermission } from '../../services/userRolesContext';
import * as plannerActions from '../../store/actions/plannerControls';
import './PlannerSettings.css';
import { logScreenViewEvent } from '../../services/logger';
import { PlannerSettingsGuides } from '../../components/Guides/Guides';

export interface plannerSettingsProps extends RouteComponentProps<{
  id: string;
}> { }

const PlannerSettings: React.FC<plannerSettingsProps> = ({ match }: any) => {
  const userPermissions: any = useCheckPermission();
  const [fbPlannerId, setFbPlannerId] = useState<string | null>();
  const dispatch = useDispatch();
  const history = useHistory();
  const uid = useSelector((state: any) => state.auth.uid);
  const uidRef = useRef(uid);
  const auth = useSelector((state: any) => state.firebase.auth);
  const userData = useSelector((state: any) => state.userData);
  const staticPages = useSelector((state: any) => state.userData.activePlanner.staticPages);
  const changedStaticPages = useRef<any>({});
  const plannerName = useSelector((state: any) => state.userData.activePlanner.plannerName);
  const [plannerCoverFront, setPlannerCoverFront] = useState(`${process.env.PUBLIC_URL}/assets/img/default-cover.jpg`);
  const [plannerCoverBack, setPlannerCoverBack] = useState(`${process.env.PUBLIC_URL}/assets/img/default-cover.jpg`);
  const userCalendars = useSelector((state: any) => state.userCalendars);
  const [showCoverSettingsModal, setShowCoverSettingsModal] = useState(false);
  const [showCalendarSettingsModal, setShowCalendarSettingsModal] = useState(false);
  const [coverModalConfig, setPlannerModalConfig] = useState<Object>({});
  const pageActive = useRef(false);
  const plannerIdRef = useRef<any>();
  const sectionsUpdateParams = useRef<any>();

  const [activePlanner, setActivePlanner] = useState<any>();

  useIonViewDidEnter(() => {
    document.title = 'Planner Settings - Digital Planner App';
    logScreenViewEvent();
  }, [])

  useIonViewDidEnter(() => {
    let idRegexp = new RegExp(`${process.env.PUBLIC_URL}\/planner\/settings\/(.[^/]*)\/?`, "g");
    let plannerId: any = idRegexp.exec(match.path);
    pageActive.current = true;
    if (plannerId && plannerId[1]) {
      setFbPlannerId(plannerId[1]);
    }
  })
  useIonViewWillLeave(() => {
    saveChangedSections();
    pageActive.current = false;
    setFbPlannerId(null);
    setActivePlanner(null);
    dispatch(userDataActions.cleanup_active_planner());
    dispatch(plannerActions.cleanup_planner_data());
    dispatch(plannerActions.set_cur_page(0));
    dispatch(plannerActions.set_zoom_by_height(0));
    dispatch(plannerActions.set_planner_fit(false));
  })

  const saveChangedSections = () => {
    if(Object.keys(changedStaticPages.current).length) {
      console.log('saving sections')
      dispatch(userDataActions.update_planner_sections_mass(sectionsUpdateParams.current.uid, sectionsUpdateParams.current.userPermissions, sectionsUpdateParams.current.plannerId, sectionsUpdateParams.current.changedStaticPages, sectionsUpdateParams.current.activePlanner));
      changedStaticPages.current = {}
    }
  }

  useEffect(() => {
    if (!pageActive.current) return;
    // if(fbPlannerId == match.params.id) return;
    setFbPlannerId(match.params.id);
  })

  useEffect(() => {
    if (userData.activePlanner && userData.activePlanner.plannerCover) {
      setPlannerCoverFront(userData.activePlanner.plannerCover.customFrontImage || userData.activePlanner.plannerCover.frontImage);
      setPlannerCoverBack(userData.activePlanner.plannerCover.customBackImage || userData.activePlanner.plannerCover.backImage);
    }
  }, [userData.activePlanner.plannerCover])

  useEffect(() => {
    if (!pageActive.current) return;
    if (!userData.userPlanners.length) {
      if (isLoaded(auth) && !isEmpty(auth) && !userData.loading) {
        dispatch(userDataActions.load_user_planners(uid));
      }
      return;
    }
    if (activePlanner && activePlanner.fbPlannerId == fbPlannerId) return;
    userData.userPlanners.forEach((planner: any) => {
      if (planner.fbPlannerId == fbPlannerId) {
        setActivePlanner(planner);
        dispatch(plannerActions.set_planner_font(planner.plannerFont));
      };
    });
  }, [userData.userPlanners, fbPlannerId])

  useEffect(() => {
    if (!activePlanner) return;
    if (!pageActive.current) return;
    if (userData.staticPagesLoading || (userData.activePlanner.fbPlannerId == activePlanner.fbPlannerId && staticPages)) return;
    dispatch(userDataActions.load_planner_static_pages(uid, activePlanner));
  })

  const updateVisibility = (page: any, newState: boolean) => {
    let curFbPlannerId = '';
    if (!fbPlannerId) {
      let idRegexp = new RegExp(`${process.env.PUBLIC_URL}\/planner\/settings\/(.[^/]*)\/?`, "g");
      let plannerId: any = idRegexp.exec(match.path);
      pageActive.current = true;
      if (plannerId && plannerId[1]) {
        curFbPlannerId = plannerId[1];
      }
    }
    changedStaticPages.current = {...changedStaticPages.current, [page.pageId]: newState}
    console.log('update', page.pageId, newState);
    console.log({...changedStaticPages.current, [page.pageId]: newState})
    plannerIdRef.current = fbPlannerId || curFbPlannerId;
    // uidRef.current = uid;
    sectionsUpdateParams.current = {uid: uid, userPermissions, plannerId: fbPlannerId || curFbPlannerId, changedStaticPages: changedStaticPages.current, activePlanner}
    // dispatch(userDataActions.update_planner_sections(uid, userPermissions, fbPlannerId || curFbPlannerId, page.pageId, newState, activePlanner));
  }

  const saveCustomizedCover = (cover: any) => {
    if (activePlanner.plannerCover.customFrontImage && activePlanner.plannerCover.customFrontImage != cover.customFrontImage) {
      dispatch(userDataActions.delete_old_cover(activePlanner.plannerCover.customFrontImage.replace('https://digital-planner.com/sites/default/files/planner-app-covers/', '')));
    }
    dispatch(userDataActions.set_custom_planner_cover(uid, userPermissions, fbPlannerId, cover));
    setShowCoverSettingsModal(false);
    // dispatch(userDataActions.load_user_planners(uid));
    setPlannerCoverFront(cover.customFrontImage);
    setPlannerCoverBack(cover.customBackImage);
  }

  const saveSelectedCalendars = useCallback((plannerCalendarsList: any, plannerId: string) => {
    if (plannerId != fbPlannerId) return;
    const checkedCalendars = plannerCalendarsList.filter((calendar: any) => {
      if (calendar.selected === true) return calendar.url;
      return false;
    });
    dispatch(userDataActions.update_planner_calendars(uid, userPermissions, fbPlannerId, checkedCalendars));
  }, [fbPlannerId, userPermissions]);

  const archivePlanner = () => {
    dispatch(userDataActions.archive_user_planner(uid, fbPlannerId!));
    dispatch(userDataActions.load_user_planners(uid));
    history.push(`${process.env.PUBLIC_URL}/`);
  }
  const activatePlanner = () => {
    dispatch(userDataActions.activate_user_planner(uid, fbPlannerId!));
    dispatch(userDataActions.load_user_planners(uid));
    history.push(`${process.env.PUBLIC_URL}/`);
  }
  const gotoPlanner = () => {
    saveChangedSections();
    setTimeout(() => {
      history.push(`${process.env.PUBLIC_URL}/planner/${activePlanner.fbPlannerId}`);
    }, 1000);
  }

  return (
    <>
      <PlannerSettingsGuides />
      <MainMenu />
      <IonPage id="mainContent">
        <MainHeader title={`${plannerName || ''} Settings`} />
        <IonContent className="planner-settings page-content-bg-gray">
          <IonGrid fixed={true}>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="6">
                <IonButton className="ion-no-padding-start" fill="outline" onClick={() => gotoPlanner()}><IonIcon icon={arrowBackOutline} className="ion-padding-end" /> Back to planner</IonButton>
              </IonCol>
              <IonCol sizeXs="12" sizeMd="6" className="ion-text-right">
                {/* <div> */}
                {activePlanner
                  ? (<>
                    {activePlanner.archived === true
                      ? <IonButton color="warning" disabled={false} onClick={() => { activatePlanner(); }}>
                        <IonIcon slot="start" color="light" icon={powerOutline}></IonIcon> Activate planner
                      </IonButton>
                      : <IonButton color="warning" disabled={false} onClick={() => { archivePlanner(); }}>
                        <IonIcon slot="start" color="light" icon={archiveOutline}></IonIcon> Archive planner
                      </IonButton>
                    }
                  </>)
                  : <></>}
                {/* </div> */}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="6">
                <div className="plannerSettingsName">
                  <h3>Planner Name</h3>
                  <IonInput className="ion-padding-start" style={{ backgroundColor: '#ffffff' }} onIonBlur={(e: any) => fbPlannerId && dispatch(userDataActions.set_planner_name(uid, fbPlannerId, userPermissions, e.detail.target.value))} value={plannerName || ''} />
                </div>
                <div className="plannerSettingsFont">
                  <h3>Planner Texts Font</h3>
                  <FontSelector target="plannerFields" />
                </div>
                <div className="plannerSettingsCalendars">
                  <h3>Planner calendars</h3>
                  {userData.staticPagesLoading
                    ? <LoadingSpinner type="inline" />
                    : <ChooseCalendarsPopup
                      config={{ plannerId: fbPlannerId }}
                      saveSelectedCalendars={(plannerCalendarsList: any, plannerId: string) => saveSelectedCalendars(plannerCalendarsList, plannerId)}
                    />
                  }
                </div>

                <div className="ion-margin-top" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                  <h3>Planner cover</h3>
                  <IonButton fill="outline" size="small"
                    onClick={() => {
                      setPlannerModalConfig({ plannerId: fbPlannerId });
                      setShowCoverSettingsModal(true);
                    }}>
                    Change
                  </IonButton>

                </div>
                {plannerCoverFront && (
                  <div className="plannerSettingsCover" style={{ display: 'flex', justifyContent: 'center' }}>
                    <IonThumbnail style={{ width: 100 + '%', maxWidth: 200 + 'px', height: 'auto', marginRight: 10 + 'px' }}>
                      <img src={plannerCoverFront || `${process.env.PUBLIC_URL}/assets/img/default-cover.jpg`} />
                    </IonThumbnail>
                    <IonThumbnail style={{ width: 100 + '%', maxWidth: 200 + 'px', height: 'auto' }}>
                      <img src={plannerCoverBack || `${process.env.PUBLIC_URL}/assets/img/default-cover.jpg`} />
                    </IonThumbnail>
                  </div>
                )}
                {showCoverSettingsModal &&
                  <ChooseCoverPopup
                    config={{ id: activePlanner.plannerCover.fbCoverId, originalCoverFile: plannerCoverFront }}
                    hideModal={() => setShowCoverSettingsModal(false)}
                    saveSelectedCover={(cover: any) => saveCustomizedCover(cover)}
                  />}
              </IonCol>
              <IonCol sizeXs="12" sizeMd="6">
                {/* {JSON.stringify(activePlanner, null, 2)} */}
                <div className="plannerSettingsSections">
                  <h3>Planner sections:</h3>
                  {userData.staticPagesLoading
                    ? <LoadingSpinner type="inline" />
                    : <IonList>
                      {staticPages && staticPages.map((page: any, index: number) => (
                        <IonItem key={page.pageId}>
                          <IonLabel>{page.pageName}</IonLabel>
                          <IonToggle checked={changedStaticPages.current.hasOwnProperty(`${page.pageId}`) ? !changedStaticPages.current[page.pageId] : !page.pageDisabled} onIonChange={e => updateVisibility(page, !e.detail.checked)} />
                        </IonItem>
                      ))}
                    </IonList>
                  }
                </div>
              </IonCol>

            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>)
}

export default PlannerSettings;