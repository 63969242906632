import * as actionTypes from '../actions/userCalendars';

const initialState = {
  eventsLoading: false,
  googleCalendarsList: [],
  appleCalendarsList: [],
  outlookCalendarsList: [],
  plannerEvents: [],
  eventsStartDate: null,
  eventsEndDate: null
}

export const userCalendarsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.CLEANUP_CALENDARS:
      return {
        ...initialState
      }
    case actionTypes.SET_EVENTS_LOADING:
      return {
        ...state,
        eventsLoading: action.loading
      }
    case actionTypes.SET_GOOGLE_USER_CALENDARS:
      return {
        ...state,
        googleCalendarsList: [...action.calendars]
      }
    case actionTypes.SET_OUTLOOK_USER_CALENDARS:
      return {
        ...state,
        outlookCalendarsList: [...action.calendars]
      }
    case actionTypes.SET_APPLE_USER_CALENDARS:
      return {
        ...state,
        appleCalendarsList: [...action.calendars]
      }
    case actionTypes.SET_PLANNER_EVENTS:
      return {
        ...state,
        plannerEvents: [...action.events],
        eventsStartDate: action.startDate,
        eventsEndDate: action.endDate
      }
    case actionTypes.CLEANUP_PLANNER_EVENTS:
      return {
        ...state,
        plannerEvents: []
      }
    case actionTypes.UPDATE_PLANNER_EVENT:
      const allEvents: any = [...state.plannerEvents];
      const found = allEvents.find((evt: any) => {
        if(evt.uid === action.event.uid){
          evt = {...action.event}
          return true;
        }
      })
      if(!found) allEvents.push({...action.event})
      return {
        ...state,
        plannerEvents: [...allEvents]
      }
    case actionTypes.DELETE_PLANNER_EVENT:
      const allEvents2 = [...state.plannerEvents];
      const index = allEvents2.findIndex((evt: any) => evt.uid === action.uid);
      allEvents2.splice(index, 1);
      return {
        ...state,
        plannerEvents: [...allEvents2]
      }
    default:
      return {
        ...state
      }
  }
}