import React, { useState } from 'react';
import { withRouter } from 'react-router';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonList,
  IonMenuToggle,
  IonIcon,
  IonLabel,
  IonItem
} from '@ionic/react';

import { bookOutline, calendarOutline, starOutline, settingsOutline, addOutline, helpCircleOutline } from 'ionicons/icons';
import { useCheckPermission } from '../../../services/userRolesContext';

const MainMenu: React.FC = ({ history }: any) => {
  const [activePage, setActivePage] = useState<String>(history.location.pathname);
  const userPermissions: any = useCheckPermission();
  const navigateToPage = (page: string) => {
    history.push(page);
    setActivePage(page);
  }
  return (
    <IonMenu contentId="mainContent" swipeGesture={false} side="start">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          <IonMenuToggle auto-hide="false">
            <IonItem button onClick={() => { navigateToPage(`${process.env.PUBLIC_URL}/`) }}>
              <IonIcon slot="start" icon={bookOutline}></IonIcon>
              <IonLabel color={activePage === `${process.env.PUBLIC_URL}/` ? 'primary' : ''}>My Bookshelf</IonLabel>
            </IonItem>
            <IonItem button onClick={() => { navigateToPage(`${process.env.PUBLIC_URL}/profile/calendars`) }}>
              <IonIcon slot="start" icon={calendarOutline}></IonIcon>
              <IonLabel color={activePage === `${process.env.PUBLIC_URL}/profile/calendars` ? 'primary' : ''}>My Calendars</IonLabel>
            </IonItem>
            <IonItem button onClick={() => { navigateToPage(`${process.env.PUBLIC_URL}/library`) }}>
              <IonIcon slot="start" icon={addOutline}></IonIcon>
              <IonLabel color={activePage === `${process.env.PUBLIC_URL}/library` ? 'primary' : ''}>Start New Planner</IonLabel>
            </IonItem>
            <IonItem button onClick={() => { navigateToPage(`${process.env.PUBLIC_URL}/support`) }}>
              <IonIcon slot="start" icon={helpCircleOutline}></IonIcon>
              <IonLabel color={activePage === `${process.env.PUBLIC_URL}/support` ? 'primary' : ''}>Support</IonLabel>
            </IonItem>
            {/* <IonItem button>
              <IonIcon slot="start" icon={settingsOutline}></IonIcon>
              <IonLabel>App Settings</IonLabel>
            </IonItem> */}
            {userPermissions.includes('adminViews') &&
              <IonItem button onClick={() => { navigateToPage(`${process.env.PUBLIC_URL}/admin`) }}>
                <IonIcon slot="start" icon={settingsOutline}></IonIcon>
                <IonLabel>Admin</IonLabel>
              </IonItem>
            }
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default withRouter(MainMenu);
