import React, { useEffect, useState } from 'react';
import * as actionTypes from '../../store/actions/library';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonThumbnail,
  useIonViewDidEnter
} from '@ionic/react';
import MainMenu from '../../components/UI/Menu/MainMenu';
import MainHeader from '../../components/UI/Header/MainHeader';
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';
import AddPlannerModal from './AddPlannerModal';
import { logScreenViewEvent } from '../../services/logger';


const PlannerLibrary: React.FC = () => {
  const library = useSelector((state: any) => state.library);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [config, setConfig] = useState<Object>({});

  useIonViewDidEnter(() => {
    document.title = 'Planners library - Digital Planner App';
    logScreenViewEvent();
  }, [])

  useEffect(() => {
    dispatch(actionTypes.load_planners_library());
  }, []);


  const planners = library.library.map((planner: any, index: number) => {
    if (planner.published) return (
      // <IonCol size="4" sizeXs="12" sizeSm="6" sizeMd="4" key={planner.id}>
      //   <IonCard button
      //     onClick={() => {
      //       setConfig({
      //         id: planner.id,
      //         plannerName: planner.plannerName,
      //         builderId: planner.builderId,
      //         dates: planner.config?.dates,
      //         weekStart: planner.config?.weekStart
      //       });
      //       setShowModal(true);
      //     }}>
      //     <IonCardHeader>
      //       <IonCardTitle className="ion-text-center">{planner.plannerName}</IonCardTitle>
      //     </IonCardHeader>
      //     <IonCardContent>
      //       Builder ID: {planner.builderId}
      //     </IonCardContent>
      //   </IonCard>
      // </IonCol>
      <IonCol size="4" sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="3" key={planner.id}>
        <IonCard>
          <IonCardContent className="ion-no-padding" onClick={() => {
            setConfig({
              id: planner.id,
              plannerName: planner.plannerName,
              builderId: planner.builderId,
              dates: planner.config?.dates,
              weekStart: planner.config?.weekStart
            });
            setShowModal(true);
          }}>
            <div>
              <IonThumbnail style={{ width: 100 + '%', height: 'auto' }}>
                <img src={planner.plannerImage || `${process.env.PUBLIC_URL}/assets/img/default-cover.jpg`} />
              </IonThumbnail>
            </div>

            <div className="ion-padding ion-text-center">
              <h1 style={{ fontWeight: 500 }}>{planner.plannerName}</h1>
            </div>
            <div className="ion-padding ion-text-center">
              <IonButton>Add this planner</IonButton>
            </div>

          </IonCardContent>
        </IonCard>
      </IonCol>
    )
  }
  )

  return (
    <>
      <MainMenu />
      <IonPage id="mainContent">
        <MainHeader title="Planner Library" />

        <IonContent className="ion-padding page-content-bg-gray">
          {library.loading
            ? <LoadingSpinner />
            : <IonGrid>
              <IonRow class="ion-justify-content-center">
                {planners}
              </IonRow>
            </IonGrid>
          }
          {showModal && <AddPlannerModal config={config} hideModal={() => setShowModal(false)} />}
        </IonContent>
      </IonPage>
    </>
  )
}

export default PlannerLibrary;