import React, { useEffect, useRef, useState } from 'react';
import { IonToast, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonSpinner, IonTextarea, useIonViewDidEnter } from '@ionic/react';
import MainMenu from '../../components/UI/Menu/MainMenu';
import MainHeader from '../../components/UI/Header/MainHeader';
import { useDispatch, useSelector } from 'react-redux';
import * as userDataActions from '../../store/actions/userData';
import firebase from "../../services/firebase";
import axios from 'axios';
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';
import { logScreenViewEvent } from '../../services/logger';

const CreateTicket: React.FC = () => {
  const fbuser = useSelector((state: any) => state.firebase.auth);
  const fbuid = useSelector((state: any) => state.firebase.auth.uid);
  const uid = useSelector((state: any) => state.auth.uid);
  // const remoteUid = useSelector((state: any) => state.auth.remoteUid);
  const userPlanners = useSelector((state: any) => state.userData.userPlanners);
  const dispatch = useDispatch();
  const remoteUid = useRef();
  const [loading, setLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const ticketFormRef = useRef<any>();

  useIonViewDidEnter(() => {
    document.title = 'Create ticket - Digital Planner App';
    logScreenViewEvent();
  }, [])

  useIonViewDidEnter(() => {
    if (userPlanners.length < 1) {
      dispatch(userDataActions.load_user_planners(uid || fbuid))
    }
    firebase.firestore().collection('userList').doc(fbuser.email)
      .get()
      .then((res) => {
        if (res.exists) {
          const data: any = res.data();
          if (data) remoteUid.current = data.remoteUid;
        }
      })
  })

  const createSupportTicket = (e: any) => {
    e.preventDefault();
    if (!remoteUid.current) return;
    setLoading(true);
    const userdata = {
      email: fbuser.email,
      firebase_uid: fbuser.uid,
      userAgent: navigator.userAgent,
      screen: `${window.innerWidth} x ${window.innerHeight}`,
      plannerId: e.target.planner.value,
    }

    const data = {
      name: e.target.title.value,
      uid: remoteUid.current,
      category: e.target.ticket_topic.value,
      status: 'open',
      body: e.target.message.value,
      json_data: { ...userdata }
    }

    const headers = {
      'content-type': 'text/plain'
    }

    axios.post('https://digital-planner.com/planner/api/bug-report/create', data, {
      headers: headers
    })
      .then((res) => {
        e.target.title.value = '';
        e.target.message.value = '';
        setShowToast(true);
      })
      .catch((e) => console.log(e))
    setLoading(false);
  }

  return <>
    <MainMenu />
    <IonPage id="mainContent">
      <MainHeader title="Support" />
      <IonContent className="planner-settings page-content-bg-gray">
        <IonGrid fixed={true}>
        <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Ticket succesfuly created."
        color="success"
        duration={10000}
      />
          <IonRow className="ion-justify-content-center">
            <IonCol sizeXs="12" sizeSm="8" sizeMd="8" sizeLg="8" sizeXl="6">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center">Create support ticket</IonCardTitle>
                </IonCardHeader>
                <form ref={ticketFormRef} onSubmit={createSupportTicket}>
                  <IonItem>
                    <IonLabel position="stacked">Topic</IonLabel>
                    <IonSelect name="ticket_topic" interface="popover">
                      <IonSelectOption value="2680">Bug report</IonSelectOption>
                      <IonSelectOption value="2681">Feature request</IonSelectOption>
                      <IonSelectOption value="2682">Need help</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Planner</IonLabel>
                    <IonSelect interface="popover" name="planner" value="none">
                      <IonSelectOption value="none">None</IonSelectOption>
                      {userPlanners.map((planner: any, index: number) => (
                        <IonSelectOption key={`${index}_planner`} value={planner.fbPlannerId}>{planner.plannerName} {planner.archived === true && '(archived)'}</IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Subject</IonLabel>
                    <IonInput name="title" required={true}></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Message</IonLabel>
                    <IonTextarea placeholder="Try to describe your issue as detailed as possible." name="message" autoGrow={true} required={true} rows={7}></IonTextarea>
                  </IonItem>
                  <div className="ion-text-center ion-padding ion-align-items-center">

                    <IonButton disabled={loading} size="default" type="submit">
                      {loading === true && <IonSpinner />}
                      Create ticket
                    </IonButton>
                  </div>
                </form>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>

  </>
}

export default CreateTicket;