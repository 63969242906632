import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSpinner } from '@ionic/react';
import { closeCircleOutline, searchOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as libraryActions from '../../store/actions/library';
import * as plannerControlsActions from '../../store/actions/plannerControls';
import { useCheckPermission } from '../../services/userRolesContext';
import './StickerSelector.css';

const StickerSelector: React.FC = () => {
  const userPermissions:any = useCheckPermission();
  const dispatch = useDispatch();
  const library = useSelector((state: any) => state.library);
  const uid = useSelector((state: any) => state.auth.uid);
  const activePlanner = useSelector((state: any) => state.userData.activePlanner);
  const plannerControls = useSelector((state: any) => state.plannerControls);
  const canvasCoords = useRef<any>();
  const [stickerSelectorOpacity, setStickerSelectorOpacity] = useState(100);
  const [stickerSearchQuery, setStickerSearchQuery] = useState('');
  const pageType = useSelector((state: any) => state.userData.activePlanner.pages[plannerControls.curPage].pageId);
  const pageTypeCount = useSelector((state: any) => state.userData.activePlanner.pages[plannerControls.curPage].pageTypeCount);

  useEffect(() => {
    !library.stickersNav.length && dispatch(libraryActions.api_load_stickers_nav());
    canvasCoords.current = document.querySelector('.plannerCanvas')?.getBoundingClientRect();
  }, []);

  useEffect(() => {})

  const searchStickersApi = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      dispatch(libraryActions.api_search_stickers_library(stickerSearchQuery));
    } catch (error) {
      console.error('Error');
    }
  }

  const addStickerToPlanner = (e: any) => {
    const stickerUrl = e.target.getAttribute('src');
    const sticker = {
      globalId: plannerControls.globalId,
      url: stickerUrl,
      x: 50,
      y: 50
    }
    dispatch(plannerControlsActions.add_sticker_to_planner(uid, userPermissions, activePlanner, sticker, pageType || 0, pageTypeCount || 0));
    dispatch(plannerControlsActions.set_draw_method('controls'));
    dispatch(plannerControlsActions.set_draw_mode(true));
    dispatch(plannerControlsActions.set_stickers_changed(pageType || 0, pageTypeCount || 0));
    // dispatch(plannerControlsActions.set_last_added_sticker(stickerUrl));
  }

  return (
    <>
      <div className="stickerSelector" style={{opacity: stickerSelectorOpacity}}>
          <IonButton fill="clear" className="closeButton" slot="icon-only" onClick={() => dispatch(plannerControlsActions.set_show_sticker_selector(false))}>
            <IonIcon size="large" color="dark" icon={closeCircleOutline} />
          </IonButton>
          <IonGrid>
            <IonRow>
              <IonCol size="3" className="stickersNavSidebar">
                <form onSubmit={searchStickersApi} className="stickersSearch">
                  <IonItem>
                    <IonInput type="text" value={stickerSearchQuery} required placeholder="Search stickers" onIonChange={e => setStickerSearchQuery(e.detail.value!)}></IonInput>
                    <IonButton type="submit" className="" fill="clear">
                      <IonIcon size="small" icon={searchOutline} color="dark" />
                    </IonButton>
                  </IonItem>
                </form>
                <div className="stickersNavigation">
                    {library.stickersNav?.map((category: any) => {
                      const childs = library.stickersTid == category.tid ? category.childs.map((childCat: any) => {
                        return (
                        <div key={childCat.tid} 
                        className={library.stickersChildTid == childCat.tid ? "stickersNavSubCat active" : "stickersNavSubCat"}
                        onClick={() => dispatch(libraryActions.api_get_stickers_category(childCat.tid, true))}>
                          {childCat.title}
                        </div>
                      )}) : '';

                      return(
                        <div key={category.tid}>
                          <IonItem 
                            className={library.stickersTid == category.tid ? "sticker-nav-cat ion-margin-right active" : "sticker-nav-cat ion-margin-right" } 
                            onClick={() => dispatch(libraryActions.api_get_stickers_category(category.tid))}>
                              {category.title}
                          </IonItem>
                          {childs}
                        </div>
                      )
                    })}
                </div>
              </IonCol>
              <IonCol size="9" className="stickersCollection">
              {library.stickersLoading
                ? <div className="stickersSpinner"><IonSpinner /></div>
                : library.stickersLibrary?.length 
                  ? library.stickersLibrary.map((sticker: any) => (
                    <div className="sticker" key={sticker.id} onClick={(e: any) => { addStickerToPlanner(e); }}>
                      <img src={'https://digital-planner.com/'+(sticker.filepath || sticker.filepath_border)} data-sticker-id={sticker.id} height="50" />
                    </div>
                  ))
                  : (<div className="stickerCategoryEmpty">No stickers in this category yet...</div>)
                }
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
    </>
  )
}

export default StickerSelector;