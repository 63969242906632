import axios from "axios";
import firebase from "../../services/firebase";

let homeSteps: Array<[]> = [];
let addPlannerSteps: Array<[]> = [];
let plannerSettingsSteps: Array<[]> = [];
let plannerSteps: Array<[]> = [];

axios.get('https://digital-planner.com/sites/app/tourTexts.json').then(res => {
  if (!res.data) return;
  homeSteps = res.data.homeSteps.map((item: any) => { item.content = (<div dangerouslySetInnerHTML={{ __html: item.content }}></div>); return item });
  addPlannerSteps = res.data.addPlannerSteps.map((item: any) => { item.content = (<div dangerouslySetInnerHTML={{ __html: item.content }}></div>); return item });
  plannerSettingsSteps = res.data.plannerSettingsSteps.map((item: any) => { item.content = (<div dangerouslySetInnerHTML={{ __html: item.content }}></div>); return item });
  plannerSteps = res.data.plannerSteps.map((item: any) => { item.content = (<div dangerouslySetInnerHTML={{ __html: item.content }}></div>); return item });
});

const getGuideSteps = (uid: string, type: string) => {
  return new Promise((resolve, reject) => {
    switch (type) {
      case 'planner':
        let dbPlannerSteps: any = [];
        firebase.firestore().collection('userData').doc(uid).collection('settings').doc('guidesSeen')
          .get().then(async (res) => {
            const data = res.data();
            data && data.planner ? dbPlannerSteps = [...data.planner] : dbPlannerSteps = [];
            return resolve(plannerSteps.filter((step: any) => !dbPlannerSteps.includes(step.target)))

          });
        return
      case 'home':
        let dbHomeSteps: any = [];
        firebase.firestore().collection('userData').doc(uid).collection('settings').doc('guidesSeen')
          .get().then(async (res) => {
            const data = res.data();
            data && data.home ? dbHomeSteps = [...data.home] : dbHomeSteps = [];
            return resolve(homeSteps.filter((step: any) => !dbHomeSteps.includes(step.target)))

          });
        return
      case 'addPlanner':
        let dbAddPlannerSteps: any = [];
        firebase.firestore().collection('userData').doc(uid).collection('settings').doc('guidesSeen')
          .get().then(async (res) => {
            const data = res.data();
            data && data.addPlanner ? dbAddPlannerSteps = [...data.addPlanner] : dbAddPlannerSteps = [];
            return resolve(addPlannerSteps.filter((step: any) => !dbAddPlannerSteps.includes(step.target)))

          });
        return
      case 'plannerSettings':
        let dbPlannerSettingsSteps: any = [];
        firebase.firestore().collection('userData').doc(uid).collection('settings').doc('guidesSeen')
          .get().then(async (res) => {
            const data = res.data();
            data && data.plannerSettings ? dbPlannerSettingsSteps = [...data.plannerSettings] : dbPlannerSettingsSteps = [];
            return resolve(plannerSettingsSteps.filter((step: any) => !dbPlannerSettingsSteps.includes(step.target)))

          });
        return
    }
  })
};

const saveGuidesState = (uid: string, guide: string, target: any) => {
  try {
    if (Array.isArray(target) && target.length) {
      const targets = target.map((item) => item.target)
      firebase.firestore().collection('userData').doc(uid).collection('settings').doc('guidesSeen')
        .get().then((res) => {
          let data = res.data();
          if (data) {
            data = [...data[`${guide}`], ...targets];
          } else {
            data = [...targets];
          }
          firebase.firestore().collection('userData').doc(uid).collection('settings').doc('guidesSeen')
            .set({ [`${guide}`]: data }, { merge: true });
        })
    } else {
      firebase.firestore().collection('userData').doc(uid).collection('settings').doc('guidesSeen')
        .set({ [`${guide}`]: firebase.firestore.FieldValue.arrayUnion(target) }, { merge: true })
    }
  } catch (e) {
    console.log(e)
  }
}

export { getGuideSteps, saveGuidesState }