import React, { useEffect, useRef, useState } from 'react';
import { Image, Transformer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import * as plannerActions from '../../store/actions/plannerControls';

type Props = {
  imageUrl: string;
  imageX: number;
  imageY: number;
  drawMethod: string;
  stickerId: number;
  initialScaleX: number;
  initialScaleY: number;
  initialRotation: number;
  stickerGlobalId: number | undefined;
}

const PlannerStageImage: React.FC<Props> = ({ imageUrl, imageX, imageY, drawMethod, stickerId, initialScaleX, initialScaleY, initialRotation, stickerGlobalId }) => {
  const dispatch = useDispatch();
  const plannerControls = useSelector((state: any) => state.plannerControls)
  const selectedElement = useSelector((state: any) => state.plannerControls.selectedElement)
  const curPage = useSelector((state: any) => state.plannerControls.curPage)
  const imageRef = useRef<any>(null);
  const imageRef2 = useRef<any>(null);
  const [image, setImage] = useState(undefined);
  const [isDragging, setIsDragging] = useState(false);
  const [posX, setPosX] = useState(imageX);
  const [posY, setPosY] = useState(imageY);
  const [imageSelected, setImageSelected] = useState(false);
  const [scaleX, setScaleX] = useState(initialScaleX);
  const [scaleY, setScaleY] = useState(initialScaleY);
  const [rotation, setRotation] = useState(initialRotation);
  const pageType = useSelector((state: any) => state.userData.activePlanner.pages ? state.userData.activePlanner.pages[curPage].pageId : 0);
  const pageTypeCount = useSelector((state: any) => state.userData.activePlanner.pages ? state.userData.activePlanner.pages[curPage].pageTypeCount : 0);
  const trRef = useRef<any>(null);
  const curStickerId = imageUrl + curPage + stickerId;
  const selectedStickerId = selectedElement.imageUrl + selectedElement.stickerPage + selectedElement.stickerId;

  useEffect(() => {
    trRef.current && trRef.current.forceUpdate();
  })

  useEffect(() => {
    loadImage();
    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleLoad);
      }
    }
  }, []);

  useEffect(() => {
    // on x/y changed from db live update
    setPosX(imageX);
    setPosY(imageY);
  }, [imageX, imageY]);

  useEffect(() => {
    setScaleX(initialScaleX);
    setScaleY(initialScaleY);
  }, [initialScaleX, initialScaleY])

  useEffect(() => {
    loadImage();
  }, [imageUrl])

  function handleLoad() {
    setImage(imageRef.current);
  }

  function loadImage() {
    const img = new window.Image();
    img.src = imageUrl;
    imageRef.current = img;
    imageRef.current.addEventListener('load', handleLoad);
  }

  useEffect(() => {
    if (curStickerId == selectedStickerId) {
      trRef.current.nodes([imageRef2.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selectedElement])

  const onImageDragEnd = (e: any) => {
    setIsDragging(false);
    const newX = parseInt(e.target.x());
    const newY = parseInt(e.target.y());
    setPosX(newX);
    setPosY(newY);
    // updatePos({ stickerId, x: newX, y: newY, url: e.target.attrs.image.src, scaleX, scaleY, rotation })
    dispatch(plannerActions.update_sticker_position(pageType, pageTypeCount, { stickerId, x: newX, y: newY, url: e.target.attrs.image.src, scaleX, scaleY, rotation }));
    dispatch(plannerActions.set_stickers_changed(pageType, pageTypeCount));
  }

  const selectImage = (e: any) => {
    if (plannerControls.drawMethod == 'controls') {
      if (!plannerControls.selectedElement.type
        || (plannerControls.selectedElement.stickerId != stickerId
          // && plannerControls.selectedElement.imageUrl != imageUrl
        )) {
        dispatch(plannerActions.select_stage_element({ imageUrl, type: 'img', stickerId, stickerPage: curPage }))
      }
    }
  }

  useEffect(() => {
    if (plannerControls.lastAddedSticker.id != undefined && plannerControls.lastAddedSticker.id == stickerGlobalId) {
      dispatch(plannerActions.select_stage_element({ imageUrl, type: 'img', stickerId, stickerPage: curPage }))
      plannerActions.set_last_added_sticker(false);
    }
  }, [plannerControls.lastAddedSticker])

  return (
    <>
      <Image
        strokeEnabled={selectedElement && curStickerId == selectedStickerId}
        stroke='#5261ff'
        strokeWidth={2}
        draggable={drawMethod === 'controls' && plannerControls.panning != true && plannerControls.pinching != true}
        x={posX}
        y={posY}
        ref={imageRef2}
        image={image}
        scaleX={scaleX}
        scaleY={scaleY}
        rotation={rotation}
        onClick={selectImage}
        onTap={selectImage}
        onDragMove={selectImage}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={e => onImageDragEnd(e)}
        onTransformEnd={(e: any) => {
          const node = imageRef2.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const rotation = node.getAbsoluteRotation();
          setRotation(rotation);
          setScaleX(scaleX);
          setScaleY(scaleY);
          dispatch(plannerActions.update_sticker_position(pageType, pageTypeCount, { stickerId, x: posX, y: posY, url: e.target.attrs.image.src, scaleX, scaleY, rotation }));
          dispatch(plannerActions.set_stickers_changed(pageType, pageTypeCount));
          // updatePos({ stickerId, x: posX, y: posY, url: e.target.attrs.image.src, scaleX, scaleY, rotation })
        }}
      />
      {selectedElement && curStickerId == selectedStickerId && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
}

export default PlannerStageImage