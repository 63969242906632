import React, { useEffect, useRef, useState } from 'react';
import { Image, Transformer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import useImage from 'use-image';
import * as svg from './helpers/svgTools';
import * as plannerActions from '../../store/actions/plannerControls';

type Props = {
  svgSource: string;
  imageUrl: string;
  imageX: number;
  imageY: number;
  drawMethod: string;
  stickerId: number;
  // updatePos: Function;
  initialScaleX: number;
  initialScaleY: number;
  initialRotation: number;
  stickerGlobalId: number | undefined;
}


const PlannerStageImage: React.FC<Props> = ({ svgSource, imageUrl, imageX, imageY, drawMethod, stickerId, initialScaleX, initialScaleY, initialRotation, stickerGlobalId }) => {
  const dispatch = useDispatch();
  const plannerControls = useSelector((state: any) => state.plannerControls)
  const selectedElement = useSelector((state: any) => state.plannerControls.selectedElement)
  const replaceColors = useSelector((state: any) => state.plannerControls.selectedElement.replaceColors)
  const curPage = useSelector((state: any) => state.plannerControls.curPage);
  let colors = svg.getColors(svgSource);
  const modifiedSVG = svg.replaceColors(svgSource, selectedElement.replaceColors || {}, selectedElement.stickerId, stickerId);
  const [image] = useImage(svg.svgToURL(modifiedSVG));
  const [isDragging, setIsDragging] = useState(false);
  const [posX, setPosX] = useState(imageX);
  const [posY, setPosY] = useState(imageY);
  const trRef = useRef<any>(null);
  const imageRef = useRef<any>(null);
  const [scaleX, setScaleX] = useState(initialScaleX);
  const [scaleY, setScaleY] = useState(initialScaleY);
  const [rotation, setRotation] = useState(initialRotation);
  const pageType = useSelector((state: any) => state.userData.activePlanner.pages ? state.userData.activePlanner.pages[curPage].pageId : 0);
  const pageTypeCount = useSelector((state: any) => state.userData.activePlanner.pages ? state.userData.activePlanner.pages[curPage].pageTypeCount : 0);
  const curStickerId = imageUrl + curPage + stickerId;
  const selectedStickerId = selectedElement.imageUrl + selectedElement.stickerPage + selectedElement.stickerId;

  useEffect(() => {
    if (replaceColors && selectedElement.stickerId == stickerId) {
      colors = svg.getColors(modifiedSVG);
      dispatch(plannerActions.select_stage_element({ imageUrl, modifiedSVG, imageColors: colors, type: 'svg', stickerId, stickerPage: curPage, replaceColors: selectedElement.replaceColors }))
      dispatch(plannerActions.update_svg_sticker(pageType, pageTypeCount, { stickerId, x: imageX, y: imageY, url: imageUrl, svgSource: modifiedSVG, type: 'svg' }))
    }
  }, [replaceColors, selectedElement.stickerId])

  const selectImage = (e: any) => {
    if (plannerControls.drawMethod == 'controls') {
      if ((!plannerControls.selectedElement.type)
        || (plannerControls.selectedElement.stickerId != stickerId
          // && plannerControls.selectedElement.imageUrl != imageUrl
        )) {
        dispatch(plannerActions.select_stage_element({ imageUrl, modifiedSVG, imageColors: colors, type: 'svg', stickerId, stickerPage: curPage }))
      }
    }
  }

  useEffect(() => {
    if (plannerControls.lastAddedSticker.id && plannerControls.lastAddedSticker.id == stickerGlobalId) {
      dispatch(plannerActions.select_stage_element({ imageUrl, modifiedSVG, imageColors: colors, type: 'svg', stickerId, stickerPage: curPage }))
      plannerActions.set_last_added_sticker(false);
    }
  }, [plannerControls.lastAddedSticker])

  useEffect(() => {
    if (trRef.current && selectedElement.imageUrl == imageUrl) {
      trRef.current.nodes([imageRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selectedElement])

  const onImageDragEnd = (e: any) => {
    setIsDragging(false);
    const newX = parseInt(e.target.x());
    const newY = parseInt(e.target.y());
    setPosX(newX);
    setPosY(newY);
    dispatch(plannerActions.update_svg_sticker(pageType, pageTypeCount, { stickerId, x: newX, y: newY, scaleX, scaleY, url: imageUrl, svgSource: modifiedSVG, type: 'svg' }))
    dispatch(plannerActions.set_stickers_changed(pageType, pageTypeCount));
  }

  useEffect(() => {
    // on x/y changed from db live update
    setPosX(imageX);
    setPosY(imageY);
  }, [imageX, imageY])

  return (
    <>
      <Image
        strokeEnabled={selectedElement && curStickerId == selectedStickerId}
        stroke='#5261ff'
        strokeWidth={2}
        image={image}
        ref={imageRef}
        draggable={drawMethod === 'controls' && plannerControls.panning != true && plannerControls.pinching != true}
        x={posX}
        y={posY}
        onDragMove={selectImage}
        scaleX={scaleX}
        scaleY={scaleY}
        rotation={rotation}
        onClick={selectImage}
        onTap={selectImage}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={e => onImageDragEnd(e)}
        onTransformEnd={(e: any) => {
          const node = imageRef.current;
          const trScaleX = node.scaleX();
          const trScaleY = node.scaleY();
          console.log(trScaleX, trScaleY)
          const rotation = node.getAbsoluteRotation();
          setRotation(rotation);
          setScaleX(trScaleX);
          setScaleY(trScaleY);
          dispatch(plannerActions.update_svg_sticker(pageType, pageTypeCount, { stickerId, x: posX, y: posY, url: imageUrl, svgSource: modifiedSVG, type: 'svg', scaleX: trScaleX, scaleY: trScaleY, rotation }));
          // if (!curStickersChanged[pageType] || (curStickersChanged[pageType] && !curStickersChanged[pageType][pageTypeCount])) {
          dispatch(plannerActions.set_stickers_changed(pageType, pageTypeCount));
          // }
        }}
      />

      {selectedElement && curStickerId == selectedStickerId && (
        <Transformer
          ref={trRef}
          shouldOverdrawWholeArea={true}

          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
}

export default PlannerStageImage