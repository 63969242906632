import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

import './ColorPicker.css';
import { IonPopover } from '@ionic/react';

type props = {
  defaultColor: string;
  onChangeColor: Function;
}

const DrawModeColorPicker: React.FC<props> = ({defaultColor, onChangeColor}) => {
  const [newColor, setNewColor] = useState(defaultColor);

  const [showPicker, setShowPicker] = useState(false);

  return (
    <div>
      <div className="pickerSwatch" onClick={() => setShowPicker(true)}>
        <div className="pickerColor" style={{ background: defaultColor }} />
      </div>
      { showPicker 
      ? <IonPopover isOpen={showPicker} onDidDismiss={() => {onChangeColor(defaultColor, newColor); setShowPicker(false)}} cssClass="colorPickerPopover">
          <ChromePicker disableAlpha={true} color={newColor} onChange={(color: any) => setNewColor(color.hex)} />
        </IonPopover>
      : null}
    </div>
  )
}

export default DrawModeColorPicker;