import axios from "axios";
import firebase from "../../services/firebase";
import { setPlannerFont, setStageFont } from "../../services/customFontsController";

export const SET_CUR_PAGE = 'SET_CUR_PAGE';
export const SET_ZOOM = 'SET_ZOOM';
export const SET_ZOOM_BY_HEIGHT = 'SET_ZOOM_BY_HEIGHT';
export const SET_PLANNER_FIT = 'SET_PLANNER_FIT';
export const SET_PINCHING = 'SET_PINCHING';
export const SET_PANNING = 'SET_PANNING';
export const SET_PLANNER_DISABLED = 'SET_PLANNER_DISABLED';
export const SET_PINCH_ZOOM = 'SET_PINCH_ZOOM';
export const SET_DRAW_MODE = 'SET_DRAW_MODE';
export const SET_STROKE_WIDTH = 'SET_STROKE_WIDTH';
export const SET_ERASER_WIDTH = 'SET_ERASER_WIDTH';
export const SET_STROKE_COLOR = 'SET_STROKE_COLOR';
export const SET_PLANNER_LINES = 'SET_PLANNER_LINES';
export const SET_DRAWING = 'SET_DRAWING';
export const LOAD_PLANNER_LINES = 'LOAD_PLANNER_LINES';
export const REMOVE_PAGE_LINES = 'REMOVE_PAGE_LINES';
export const SET_DRAW_METHOD = 'SET_DRAW_METHOD';
export const ADD_STICKER_TO_PLANNER = 'ADD_STICKER_TO_PLANNER';
export const LOAD_PLANNER_STICKERS = 'LOAD_PLANNER_STICKERS';
export const UPDATE_STICKER_POSITION = 'UPDATE_STICKER_POSITION';
export const UPDATE_SVG_STICKER = 'UPDATE_SVG_STICKER';
export const SELECT_STAGE_ELEMENT = 'SELECT_STAGE_ELEMENT';
export const REPLACE_SVG_COLOR = 'REPLACE_SVG_COLOR';
export const CLEANUP_SELECTED_ELEMENTS = 'CLEANUP_SELECTED_ELEMENTS';
export const REMOVE_STICKER = 'REMOVE_STICKER';
export const SET_ELEMENT_FOCUS = 'SET_ELEMENT_FOCUS';
export const CLEANUP_PLANNER_DATA = 'CLEANUP_PLANNER_DATA';
export const UPDATE_PLANNER_DATA = 'UPDATE_PLANNER_DATA';
export const LOAD_PLANNER_DATA = 'LOAD_PLANNER_DATA';
export const SELECT_LINE = 'SELECT_LINE';
export const CLEANUP_SELECTED_LINE = 'CLEANUP_SELECTED_LINE';
export const SET_PAN_DISABLED = 'SET_PAN_DISABLED';
export const SET_ZOOM_DISABLED = 'SET_ZOOM_DISABLED';
export const SET_LAST_ADDED_STICKER = 'SET_LAST_ADDED_STICKER';
export const LOAD_PLANNER_TEXTS = 'LOAD_PLANNER_TEXTS';
export const ADD_STAGE_TEXT = 'ADD_STAGE_TEXT';
export const UPDATE_STAGE_TEXT = 'UPDATE_STAGE_TEXT';
export const REMOVE_STAGE_TEXT = 'REMOVE_STAGE_TEXT';
export const CHANGE_PLANNER_FONT = 'CHANGE_PLANNER_FONT';
export const FONT_LOADING = 'FONT_LOADING';
export const SET_LINES_CHANGED = 'SET_LINES_CHANGED';
export const SET_PLANNER_DATA_CHANGED = 'SET_PLANNER_DATA_CHANGED';
export const SET_STICKERS_CHANGED = 'SET_STICKERS_CHANGED';
export const SET_STAGE_TEXTS_CHANGED = 'SET_STAGE_TEXTS_CHANGED';
export const CLEANUP_LINES_CHANGED = 'CLEANUP_LINES_CHANGED';
export const CLEANUP_PLANNER_DATA_CHANGED = 'CLEANUP_PLANNER_DATA_CHANGED';
export const CLEANUP_STICKERS_CHANGED = 'CLEANUP_STICKERS_CHANGED';
export const CLEANUP_STAGE_TEXTS_CHANGED = 'CLEANUP_STAGE_TEXTS_CHANGED';
export const CLEANUP_ALL_DATA = 'CLEANUP_ALL_DATA';
export const SET_SHOW_STICKER_SELECTOR = 'SET_SHOW_STICKER_SELECTOR';

export const cleanup_all_data = () => {
  return((dispatch: any) => {
    dispatch({type: CLEANUP_ALL_DATA})
  })
}

const setFontLoading = (loading: boolean) => ({
  type: FONT_LOADING,
  loading
})

const addStickerToPlanner = (sticker: any, pageType: any, pageTypeCount: number) => ({
  type: ADD_STICKER_TO_PLANNER,
  sticker,
  pageType,
  pageTypeCount
});
const addStageText = (textEl: any, pageType: any, pageTypeCount: number) => ({
  type: ADD_STAGE_TEXT,
  textEl,
  pageType,
  pageTypeCount
});

const selectStageElement = (element: any) => ({
  type: SELECT_STAGE_ELEMENT,
  element
})

const removeSticker = (sticker: any) => ({
  type: REMOVE_STICKER,
  sticker
})

const removeStageText = (textEl: any) => ({
  type: REMOVE_STAGE_TEXT,
  textEl
})


export const set_cur_page = (curPage: number) => {
  return (dispatch: any) => {
    dispatch({ type: SET_CUR_PAGE, curPage })
  }
}
export const set_zoom = (zoom: number) => {
  return (dispatch: any) => {
    dispatch({ type: SET_ZOOM, zoom })
  }
}
export const set_zoom_by_height = (zoom: number) => {
  return (dispatch: any) => {
    dispatch({ type: SET_ZOOM_BY_HEIGHT, zoom })
  }
}
export const set_planner_fit = (fit: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_PLANNER_FIT, fit })
  }
}
export const set_pinching = (pinching: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_PINCHING, pinching })
  }
}
export const set_panning = (panning: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_PANNING, panning })
  }
}
export const set_planner_disabled = (disabled: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_PLANNER_DISABLED, disabled })
  }
}
export const set_pinch_zoom = (pinchZoom: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_PINCH_ZOOM, pinchZoom })
  }
}
export const set_draw_mode = (drawMode: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_DRAW_MODE, drawMode })
  }
}
export const set_stroke_width = (strokeWidth: number) => {
  return (dispatch: any) => {
    dispatch({ type: SET_STROKE_WIDTH, strokeWidth })
  }
}
export const set_eraser_width = (eraserWidth: number) => {
  return (dispatch: any) => {
    dispatch({ type: SET_ERASER_WIDTH, eraserWidth })
  }
}
export const set_stroke_color = (strokeColor: string) => {
  return (dispatch: any) => {
    dispatch({ type: SET_STROKE_COLOR, strokeColor })
  }
}

export const set_drawing = (drawing: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_DRAWING, drawing })
  }
}

export const cleanup_selected_line = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_SELECTED_LINE })
  }
}

export const set_planner_lines = (uid: string, plannerId: string, pageType: any, pageTypeCount: number, lines: Array<[]>) => {
  return (dispatch: any) => {
    // console.log(pageType, pageTypeCount, lines);
    dispatch({ type: SET_PLANNER_LINES, pageType, pageTypeCount, lines });
  }
}
export const remove_page_lines = (pageType: any, pageTypeCount: number) => {
  return (dispatch: any) => {
    dispatch({ type: REMOVE_PAGE_LINES, pageType, pageTypeCount });
  }
}
export const set_pan_disabled = (disabled: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_PAN_DISABLED, disabled });
  }
}
export const set_zoom_disabled = (disabled: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_ZOOM_DISABLED, disabled });
  }
}

export const set_show_sticker_selector = (show: boolean) => {
  return (dispatch: any) => {
    dispatch({ type: SET_SHOW_STICKER_SELECTOR, show });
  }
}
// export const save_planner_lines_to_db = (uid: string, permissions: any, plannerId: string, pageType: any, pageTypeCount: number, lines: Array<[]>, year: any) => {
//   return (dispatch: any) => {
//     if (!permissions.includes('writeDb')) return;
//     firebase.firestore()
//       .collection('userData')
//       .doc(uid)
//       .collection('planners')
//       .doc(plannerId)
//       .collection(`pagesDrawings-${year}`)
//       .doc(`${pageType}`)
//       .set({ [`${pageTypeCount}`]: JSON.stringify(lines) }, { merge: true })
//       .catch(e => console.log(e));
//   }
// }

export const autosave_planner_lines_to_db = (uid: string, permissions: any, plannerId: string, pages: any, lines: any, year: any) => {
  return (dispatch: any) => {
    if (!permissions.includes('writeDb')) return;
    Object.entries(pages).forEach(([pageType, pagesChanged]: any) => {
      pagesChanged.forEach((pageTypeCount: any) => {
        firebase.firestore()
          .collection('userData')
          .doc(uid)
          .collection('planners')
          .doc(plannerId)
          .collection(`pagesDrawings-${year}`)
          .doc(`${pageType}`)
          .set({ [`${pageTypeCount}`]: lines[pageType][pageTypeCount] }, { merge: true })
          .catch(e => console.log(e));
      })
    })
  }
}

export const load_planner_lines = (uid: string, plannerId: string, year: any) => {
  return (dispatch: any) => {
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .collection(`pagesDrawings-${year}`)
      .onSnapshot((data: any) => {
      // .get().then((data: any) => {
        const pagesData: any = {};
        const dataQueue: any = [];
        data.forEach((pageLines: any) => {
          dataQueue.push(new Promise(async (resolve, reject) => {
            pagesData[pageLines.id] = await pageLines.data();
            resolve(true);
          }));
        });
        Promise.all(dataQueue).then(() => {
          dispatch({ type: LOAD_PLANNER_LINES, pagesData })
        })
      })
  }
}

export const set_draw_method = (method: string) => {
  return (dispatch: any) => {
    dispatch({ type: SET_DRAW_METHOD, method });
  }
}

export const add_sticker_to_planner = (uid: any, permissions: any, plannerConfig: any, sticker: any, pageType: any, pageTypeCount: number) => {
  return (dispatch: any) => {
    const year = plannerConfig.plannerYear;
    if (sticker.url.includes('.svg')) {
      axios.get('https://digital-planner.com/planner/api/stickers/svg?path=' + sticker.url.split('.com/')[1])
        .then(res => {
          if (res.data.items) {
            const svgSource = res.data.items.replace('viewBox=', 'width="100" height="100" viewBox=');
            sticker = { ...sticker, type: 'svg', source: svgSource };
            dispatch(addStickerToPlanner(sticker, pageType, pageTypeCount));
            if (!permissions.includes('writeDb')) return;
            firebase.firestore()
              .collection('userData')
              .doc(uid)
              .collection('planners')
              .doc(plannerConfig.fbPlannerId)
              .collection(`plannerStickers-${year}`)
              .doc(`${pageType}`)
              .set({ [`${pageTypeCount}`]: firebase.firestore.FieldValue.arrayUnion(sticker) }, { merge: true });
          }
        })
    } else {
      dispatch(addStickerToPlanner(sticker, pageType, pageTypeCount));
      if (!permissions.includes('writeDb')) return;
      firebase.firestore()
        .collection('userData')
        .doc(uid)
        .collection('planners')
        .doc(plannerConfig.fbPlannerId)
        .collection(`plannerStickers-${year}`)
        .doc(`${pageType}`)
        .set({ [`${pageTypeCount}`]: firebase.firestore.FieldValue.arrayUnion({ ...sticker, type: 'img' }) }, { merge: true });
    }
  }
}

export const load_planner_stickers = (uid: string, plannerId: string, year: string) => {
  return (dispatch: any) => {
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .collection(`plannerStickers-${year}`)
      .get().then((data: any) => {
        const plannerStickers: any = {};
        const dataQueue: any = [];
        data.forEach((pageStickers: any) => {
          dataQueue.push(new Promise(async (resolve, reject) => {
            plannerStickers[pageStickers.id] = await pageStickers.data();
            resolve(true);
          }))
        })
        Promise.all(dataQueue).then(() => {
          dispatch({ type: LOAD_PLANNER_STICKERS, plannerStickers })
        })
      });
  }
}

// export const update_db_stickers = (uid: string, permissions: any, plannerId: string, year: string, pageType: any, pageTypeCount: number, pageStickers: object) => {
//   return (dispatch: any) => {
//     dispatch({ type: CLEANUP_SELECTED_ELEMENTS });
//     if (!permissions.includes('writeDb')) return;
//     firebase.firestore()
//       .collection('userData')
//       .doc(uid)
//       .collection('planners')
//       .doc(plannerId)
//       .collection(`plannerStickers-${year}`)
//       .doc(`${pageType}`)
//       .set({ [`${pageTypeCount}`]: pageStickers })
//       .catch((e: any) => {
//         console.log(e);
//       })
//     // console.log('sticker db update', pageStickers)
//   }
// }

export const autosave_stickers_to_db = (uid: string, permissions: any, plannerId: string, pages: any, stickers: any, year: string) => {
  return (dispatch: any) => {
    // dispatch({ type: CLEANUP_SELECTED_ELEMENTS });
    if (!permissions.includes('writeDb')) return;
    Object.entries(pages).forEach(([pageType, pagesChanged]: any) => {
      pagesChanged.forEach((pageTypeCount: any) => {
        console.log(stickers, pageType, pageTypeCount)
        firebase.firestore()
          .collection('userData')
          .doc(uid)
          .collection('planners')
          .doc(plannerId)
          .collection(`plannerStickers-${year}`)
          .doc(`${pageType}`)
          .set({ [`${pageTypeCount}`]: stickers[pageType][pageTypeCount] }, { merge: true })
          .catch(e => console.log(e));
      })
    })
  }
}

export const update_sticker_position = (pageType: any, pageTypeCount: number, sticker: object) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_STICKER_POSITION, sticker, pageType, pageTypeCount });
  }
}
export const update_svg_sticker = (pageType: any, pageTypeCount: number, sticker: object) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_SVG_STICKER, sticker, pageType, pageTypeCount });
  }
}
export const select_stage_element = (element: any) => {
  return (dispatch: any) => {
    dispatch(selectStageElement(element));
  }
}
export const set_last_added_sticker = (id?: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_LAST_ADDED_STICKER, id })
  }
}
export const replace_svg_color = (newColors: any) => {
  return (dispatch: any) => {
    dispatch({ type: REPLACE_SVG_COLOR, newColors });
  }
}
export const cleanup_selected_elements = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_SELECTED_ELEMENTS });
  }
}

export const remove_sticker = (sticker: any) => {
  return (dispatch: any) => {
    dispatch(removeSticker(sticker))
  }
}

export const select_line = (line: any) => {
  return (dispatch: any) => {
    dispatch({ type: SELECT_LINE, line })
  }
}

export const cleanup_planner_data = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_PLANNER_DATA });
  }
}

export const set_element_focus = (focusState: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_ELEMENT_FOCUS, focusState })
  }
}

export const update_planner_data = (pageType: any, pageTypeCount: any, element: any) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_PLANNER_DATA, pageType, pageTypeCount, element })
  }
}

export const load_planner_data = (uid: string, plannerId: string, year: any) => {
  return (dispatch: any) => {
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .collection(`plannerData-${year}`)
      .onSnapshot((data: any) => {
        let plannerData: any = {};
        const dataQueue: any = [];
        data.forEach((pageData: any) => {
          dataQueue.push(new Promise(async (resolve, reject) => {
            const data = await pageData.data();
            for (const [key, value] of Object.entries(data)) {
              data[key] = JSON.parse(`${value}`);
            }
            plannerData[pageData.id] = data;
            resolve(true);
          })
          )
        });
        Promise.all(dataQueue).then(() => {
          dispatch({ type: LOAD_PLANNER_DATA, plannerData })
        })
      })
  }
}

// export const update_planner_data_db = (uid: string, permissions: any, plannerId: string, year: any, pageType: any, pageTypeCount: any, pageData: any) => {
//   return (dispatch: any) => {
//     if (!permissions.includes('writeDb')) return;
//     firebase.firestore()
//       .collection('userData')
//       .doc(uid)
//       .collection('planners')
//       .doc(plannerId)
//       .collection(`plannerData-${year}`)
//       .doc(`${pageType}`)
//       .set({ [`${pageTypeCount}`]: JSON.stringify(pageData) }, { merge: true })
//       .catch(e => console.log(e))
//   }
// }


export const autosave_planner_data_to_db = (uid: string, permissions: any, plannerId: string, pages: any, pageData: any, year: any) => {
  return (dispatch: any) => {
    if (!permissions.includes('writeDb')) return;

    Object.entries(pages).forEach(([pageType, pagesChanged]: any) => {
      pagesChanged.forEach((pageTypeCount: any) => {
        firebase.firestore()
          .collection('userData')
          .doc(uid)
          .collection('planners')
          .doc(plannerId)
          .collection(`plannerData-${year}`)
          .doc(`${pageType}`)
          .set({ [`${pageTypeCount}`]: JSON.stringify(pageData[pageType][pageTypeCount]) }, { merge: true })
          .catch(e => console.log(e));
      })
    })
  }
}

export const load_planner_texts = (uid: string, plannerId: string, year: string) => {
  return (dispatch: any) => {
    setFontLoading(true);
    const fontsToLoad: any = [];
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .collection(`plannerTexts-${year}`)
      .get().then((data: any) => {
        const plannerTexts: any = {};
        const dataQueue: any = [];
        data.forEach((pageTexts: any) => {
          dataQueue.push(new Promise(async (resolve, reject) => {
            plannerTexts[pageTexts.id] = await pageTexts.data();
            Object.keys(plannerTexts[pageTexts.id]).map((key, i) => {
              plannerTexts[pageTexts.id][key].forEach((text: any) => {
                if (text.fontFamily && !fontsToLoad.includes(text.fontFamily)) {
                  fontsToLoad.push(text.fontFamily);
                }
              })
            })
            resolve(true);
          }))
        })
        Promise.all(dataQueue).then(() => {
          const fontsLoadingQueue: any = [];
          fontsToLoad.forEach((font: any) => {
            fontsLoadingQueue.push(new Promise(async (resolve, reject) => {
              setStageFont(font, 'add').then((res) => res && resolve(true))
            }))
          })
          Promise.all(dataQueue).then(() => {
            dispatch({ type: LOAD_PLANNER_TEXTS, plannerTexts });
            setFontLoading(false);
          })
        })
      });
  }
}

export const add_stage_text = (uid: string, permissions: any, plannerId: string, plannerYear: number, pageType: any, pageTypeCount: any, textEl: any) => {
  return (dispatch: any) => {
    if (!permissions.includes('writeDb')) return;
    dispatch(addStageText(textEl, pageType, pageTypeCount));
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .collection(`plannerTexts-${plannerYear}`)
      .doc(`${pageType}`)
      .set({ [`${pageTypeCount}`]: firebase.firestore.FieldValue.arrayUnion({ ...textEl }) }, { merge: true });
  }
}

export const update_stage_text_el = (pageType: any, pageTypeCount: any, textEl: any) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_STAGE_TEXT, textEl, pageType, pageTypeCount });
  }
}
export const update_db_stageTexts = (uid: string, permissions: any, plannerId: string, year: string, pageType: any, pageTypeCount: number, stageTexts: object) => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_SELECTED_ELEMENTS });
    if (!permissions.includes('writeDb')) return;
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .collection(`plannerTexts-${year}`)
      .doc(`${pageType}`)
      .set({ [`${pageTypeCount}`]: stageTexts })
      .catch((e: any) => {
        console.log(e);
      })
    // console.log('sticker db update', pageStickers)
  }
}

export const autosave_stage_texts_to_db = (uid: string, permissions: any, plannerId: string, pages: any, stageTexts: any, year: string) => {
  return (dispatch: any) => {
    if (!permissions.includes('writeDb')) return;

    Object.entries(pages).forEach(([pageType, pagesChanged]: any) => {
      pagesChanged.forEach((pageTypeCount: any) => {
        // console.log(stageTexts[pageType][pageTypeCount])
        firebase.firestore()
          .collection('userData')
          .doc(uid)
          .collection('planners')
          .doc(plannerId)
          .collection(`plannerTexts-${year}`)
          .doc(`${pageType}`)
          .set({ [`${pageTypeCount}`]: stageTexts[pageType][pageTypeCount] }, { merge: true })
          .catch(e => console.log(e));
      })
    })
  }
}

export const remove_stage_text = (textEl: any) => {
  return (dispatch: any) => {
    dispatch(removeStageText(textEl))
  }
}

export const update_planner_font = (uid: string, plannerId: string, permissions: any, font: any) => {
  return (dispatch: any) => {
    if (!font) return;
    setPlannerFont(font.url).then((res) => {
      if (res) {
        dispatch({ type: CHANGE_PLANNER_FONT, font });
        if (!permissions.includes('writeDb')) return;
        const dbPlanner = firebase.firestore()
          .collection('userData')
          .doc(uid)
          .collection('planners')
          .doc(plannerId);
          dbPlanner.get().then(res => {
            if(res.exists) dbPlanner.set({ plannerFont: font }, { merge: true });
          })
          
      };
    })
  }
}

export const set_planner_font = (font: any) => {
  return (dispatch: any) => {
    if (!font) return;
    setPlannerFont(font.url).then((res) => {
      if (res) {
        dispatch({ type: CHANGE_PLANNER_FONT, font });
      };
    })
  }
}

export const set_lines_changed = (pageType: number, pageTypeCorrection: number) => {
  return (dispatch: any) => {
    dispatch({ type: SET_LINES_CHANGED, pageType, pageTypeCorrection })
  }
}

export const set_planner_data_changed = (pageType: any, pageTypeCount: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_PLANNER_DATA_CHANGED, pageType, pageTypeCount })
  }
}

export const set_stickers_changed = (pageType: any, pageTypeCount: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_STICKERS_CHANGED, pageType, pageTypeCount })
  }
}

export const set_stage_texts_changed = (pageType: any, pageTypeCount: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_STAGE_TEXTS_CHANGED, pageType, pageTypeCount })
  }
}

export const cleanup_lines_changes = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_LINES_CHANGED })
  }
}

export const cleanup_planner_data_changes = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_PLANNER_DATA_CHANGED })
  }
}

export const cleanup_stickers_changes = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_STICKERS_CHANGED })
  }
}

export const cleanup_stage_texts_changes = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEANUP_STAGE_TEXTS_CHANGED })
  }
}