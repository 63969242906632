import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as plannerActions from '../../store/actions/plannerControls';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { createGesture, Gesture } from '@ionic/react';
import { useHistory } from 'react-router';
import { useWindowResize } from '../../services/WindowResizeHook';

type Props = {
  children: React.ReactNode;
  width: number;
  height: number;
  pagerStep: number
}

const PlannerZoomController: React.FC<Props> = ({ children, width, height, pagerStep }) => {
  const plannerControls = useSelector((state: any) => state.plannerControls);
  const curPage = useSelector((state: any) => state.plannerControls.curPage);
  const windowSize = useWindowResize();
  const pinchZoommed = useSelector((state: any) => state.plannerControls.pinchZoommed);
  const pinchZoommedRef = useRef();
  const drawModeRef = useRef();
  const pinchingRef = useRef();
  const drawMode = useSelector((state: any) => state.plannerControls.drawMode);
  const drawMethod = useSelector((state: any) => state.plannerControls.drawMethod);
  const selectedElement = useSelector((state: any) => Object.keys(state.plannerControls.selectedElement).length > 0);
  const pages = useSelector((state: any) => state.userData.activePlanner.pages);
  const dispatch = useDispatch();
  const swipeEl = useRef<any>(null);
  const lastPanningState = useRef(false);
  const panDisabled = useSelector((state: any) => state.plannerControls.panDisabled);
  const zoomDisabled = useSelector((state: any) => state.plannerControls.zoomDisabled);
  const trWr = useRef<any>();
  const centerX = useRef<number>();
  const history = useHistory();
  const zoomEl = useRef<any>();
  
  

  useEffect(() => {
    pinchZoommedRef.current = pinchZoommed;
    drawModeRef.current = drawMode;
    pinchingRef.current = plannerControls.pinching;
  })

  useEffect(() => {
    // console.log(trWr.current)
    if(trWr.current) {trWr.current.resetTransform()};
  }, [windowSize])

  useEffect(() => {
    centerX.current = -((window.innerWidth * plannerControls.zoomFitByHeight) - window.innerWidth) / 2;
  }, [plannerControls.plannerFit])

  const onPinchStart = () => {
    dispatch(plannerActions.set_pinching(true));
  }
  const onPinchStop = () => {
    setTimeout(() => {
      dispatch(plannerActions.set_pinching(false));
    }, 200);
  }
  const onPanStart = (e: any) => {
    if (plannerControls.drawMethod == 'pen' || plannerControls.drawMethod == 'eraser') return;
    const minX = Math.abs(e.instance.bounds.minPositionX);
    const maxX = Math.abs(e.instance.bounds.maxPositionX);
    const posX = Math.abs(e.state.positionX + e.state.positionX * 0.05);

    if ((pinchZoommedRef.current && isNaN(plannerControls.selectedLine.index) && !plannerControls.selectedElement.type)) {
      if (posX > minX || posX == maxX) {
        // console.log('set panning false');
        plannerControls.panning && dispatch(plannerActions.set_panning(false));
        lastPanningState.current = false;
      } else {
        // console.log('set panning true');
        !plannerControls.panning && dispatch(plannerActions.set_panning(true));
        lastPanningState.current = true;
      }
    }
  }
  const onPanStop = (e: any) => {
    setTimeout(() => {
      if (e.state.scale == plannerControls.zoomFitByHeight) {
        e.setTransform(centerX.current, e.state.positionY, e.state.scale, 50)
      }
      plannerControls.panning && dispatch(plannerActions.set_panning(false));
    }, 200);
  }

  const onZoomChange = (e: any) => {
    if (e.state.scale > 1 && !pinchZoommedRef.current) {
      dispatch(plannerActions.set_pinch_zoom(true));
    } else if (e.state.scale == 1 && pinchZoommedRef.current) {
      dispatch(plannerActions.set_pinch_zoom(false));
    }
    return false;
  }

  useEffect(() => {
    const gesture: Gesture = createGesture({
      el: swipeEl.current!,
      gestureName: 'pager-swipe',
      onStart: ev => onPagerSwipeStart(ev),
      // onMove: ev => onPagerSwipeMove(ev),
      onEnd: ev => onPagerSwipeEnd(ev),
    })
    gesture.enable(true);
    setTimeout(() => {
    //   plannerControls.plannerDisabled && dispatch(plannerActions.set_planner_disabled(false));
    }, 200);

    const onPagerSwipeStart = (detail: any) => {
      dispatch(plannerActions.set_planner_disabled(true));
    }

    const onPagerSwipeEnd = (detail: any) => {
      if (drawMode || pinchZoommed) {
        dispatch(plannerActions.set_planner_disabled(false));
        return
      };
      // if (pinchZoommed) return;
      if (trWr.current && trWr.current.state.scale == plannerControls.zoomFitByHeight) {
        trWr.current.setTransform(centerX.current, trWr.current.state.positionY, trWr.current.state.scale, 50)
      }
      if (!pinchZoommed || lastPanningState.current == false) {
        const curLocation = history.location.pathname;
        if (detail.deltaX < (-window.innerWidth * 0.1) && pages[curPage + pagerStep]) {
          dispatch(plannerActions.set_cur_page(curPage + pagerStep));
          const nextPageIds = pages[curPage + pagerStep].ids;
          const nextHash = nextPageIds[nextPageIds.length - 1];
          nextHash ? history.push(curLocation + '#' + nextHash) : history.push(curLocation.split('#')[0]);
        } else if (detail.deltaX > (window.innerWidth * 0.1) && pages[curPage - pagerStep]) {
          dispatch(plannerActions.set_cur_page(curPage - pagerStep));
          const nextPageIds = pages[curPage - pagerStep].ids;
          const nextHash = nextPageIds[nextPageIds.length - 1];
          nextHash ? history.push(curLocation + '#' + nextHash) : history.push(curLocation.split('#')[0]);
        }
        lastPanningState.current = false;
      }
      setTimeout(() => {
        dispatch(plannerActions.set_planner_disabled(false));
      }, 200);
    }
  }, [curPage, pinchZoommed, drawMode, pagerStep, plannerControls.zoomFitByHeight]);

  useEffect(() => {
    if ((drawModeRef.current && plannerControls.drawMethod == 'controls' && plannerControls.selectedElement.type)
      || (drawModeRef.current && plannerControls.drawMethod == 'controls' && !isNaN(plannerControls.selectedLine.index))
      || (drawModeRef.current && plannerControls.drawMethod != 'controls')) {
      !panDisabled && dispatch(plannerActions.set_pan_disabled(true));
    } else if (plannerControls.elementOnFocus) {
      !panDisabled && dispatch(plannerActions.set_pan_disabled(true));
    } else {
      panDisabled && dispatch(plannerActions.set_pan_disabled(false));
    }
  })

  useEffect(() => {
    if (trWr.current) {
      if (plannerControls.plannerFit) {
        trWr.current.setTransform(centerX.current, 0, plannerControls.zoomFitByHeight, 100);
      } else {
        trWr.current.resetTransform(100);
      }
    }
  }, [plannerControls.plannerFit])

  return (
    <TransformWrapper
      ref={trWr}
      wheel={{ step: 0.02, disabled: zoomDisabled, wheelDisabled: false }}
      panning={{ disabled: panDisabled, velocityDisabled: true, excluded: ['stageEditableEl'] }}
      onZoom={onZoomChange}
      onPinchingStart={onPinchStart}
      onPinchingStop={onPinchStop}
      onPanningStart={onPanStart}
      onPanningStop={onPanStop}
      centerZoomedOut={true}
      centerOnInit={true}
      doubleClick={{ disabled: true }}
      zoomAnimation={{ disabled: true }}
      alignmentAnimation={{ disabled: true, size: 0 }}
      velocityAnimation={{ disabled: true, equalToMove: true }}
      minScale={plannerControls.plannerFit ? plannerControls.zoomFitByHeight : 1}
      initialScale={plannerControls.plannerFit ? plannerControls.zoomFitByHeight : 1}
    >
      <TransformComponent >
        <div style={{
          width: width + 'px',
          height: height + 'px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }} ref={swipeEl}>
          {children}
        </div>
      </TransformComponent>
    </TransformWrapper>
  )
}
export default PlannerZoomController;