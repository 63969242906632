import { IonSpinner } from '@ionic/react';
import React from 'react';

interface spinnerProps {
  type?: string;
}

const LoadingSpinner: React.FC<spinnerProps> = ({type}) => {
  let spinnerWrapped: any = '';
  if(type && type == 'inline'){
    spinnerWrapped = (
      <div className="ion-text-center ion-justify-content-center ion-align-items-center ion-padding-top ion-padding-bottom" style={{width: 100+'%', display: 'flex'}}>
        <IonSpinner />
      </div>
    )
  }else{
    spinnerWrapped = (<div className="ion-text-center ion-justify-content-center ion-align-items-center" style={{ minHeight: 100 + 'vh', display: 'flex' }}>
    <IonSpinner />
  </div>)
  }

  return (
    <>
      {spinnerWrapped}
    </>
  )
}

export default LoadingSpinner;