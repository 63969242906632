import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonIcon, IonPopover, IonSelect, IonSelectOption } from '@ionic/react';
import * as plannerActions from '../../../../store/actions/plannerControls';
import {
  trash
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import DrawModeColorPicker from './ColorPicker';
import FontSelector from '../../../Planner/FontSelector';
import { setStageFont, stageFonts, plannerFonts } from "../../../../services/customFontsController";
import { invertColor } from '../../../../services/colorConversters';

type props = {
  activeColor: string;
}

const StageTextControls: React.FC<props> = ({ activeColor }) => {
  const dispatch = useDispatch();
  const plannerControls = useSelector((state: any) => state.plannerControls);
  const userData = useSelector((state: any) => state.userData);
  const curPage = useSelector((state: any) => state.plannerControls.curPage);
  const plannerPages = useSelector((state: any) => state.userData.activePlanner.pages);
  const pageType = useRef<any>();
  const pageTypeCount = useRef<any>();
  const plannerId = useSelector((state: any) => state.userData.activePlanner.fbPlannerId);
  const uid = useSelector((state: any) => state.auth.uid);
  const plannerYear = useSelector((state: any) => parseInt(state.userData.activePlanner.plannerYear));
  const selectedElement = useSelector((state: any) => state.plannerControls.selectedElement);
  const curSelected = useRef(selectedElement.textId);
  const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const updatingTextEl = useRef(false);

  useEffect(() => {
    if (userData.activePlanner && userData.activePlanner.pages) {
      pageType.current = userData.activePlanner.pages[curPage].pageId;
      pageTypeCount.current = userData.activePlanner.pages[curPage].pageTypeCount;
    }
  })

  useEffect(() => {
    curSelected.current = selectedElement.textId;
  }, [selectedElement])

  const updateTextEl = (selectedId: any, newFontSize?: number, newColor?: string, newFont?: any) => {
    if (updatingTextEl.current) return;
    updatingTextEl.current = true;
    if (typeof newFontSize == 'string') newFontSize = parseInt(newFontSize)
    setTimeout(() => {
      // console.log(selectedElement)
      if (selectedId == curSelected.current) {
        const updatedTextEl = {
          type: 'text',
          textId: selectedElement.textId,
          width: selectedElement.width,
          height: selectedElement.height,
          x: selectedElement.x,
          y: selectedElement.y,
          rotation: selectedElement.rotation,
          fontSize: newFontSize || selectedElement.fontSize,
          text: selectedElement.text,
          textColor: newColor || (darkMode ? invertColor(selectedElement.textColor) : selectedElement.textColor),
          fontFamily: (newFont && newFont.name) || selectedElement.fontFamily,
        }
        if (newFont) {
          setStageFont(newFont, 'add').then((res) => {
            if (res) {
              dispatch(plannerActions.update_stage_text_el(pageType.current, pageTypeCount.current, { ...updatedTextEl }));
              dispatch(plannerActions.select_stage_element({ ...updatedTextEl, textColor: darkMode ? invertColor(updatedTextEl.textColor) : updatedTextEl.textColor }));
              dispatch(plannerActions.set_stage_texts_changed(pageType.current, pageTypeCount.current));
            }
          })
        } else {
          dispatch(plannerActions.update_stage_text_el(pageType.current, pageTypeCount.current, { ...updatedTextEl }))
          dispatch(plannerActions.select_stage_element({ ...updatedTextEl, textColor: darkMode ? invertColor(updatedTextEl.textColor) : updatedTextEl.textColor }));
          dispatch(plannerActions.set_stage_texts_changed(pageType.current, pageTypeCount.current));
        }
      }
      updatingTextEl.current = false;
    }, 700);
  }
  const fontSizeOptions: any = [];
  for (let i: number = 10; i <= 100; i += 2) {
    fontSizeOptions.push(<IonSelectOption id={`fontOption${i}`} key={i} value={i}>{i}</IonSelectOption>);
  };

  const scrollToSelected = (e: any) => {
    setTimeout(() => {
      // const selected:HTMLElement|null = (e.target as HTMLElement).querySelector(`#fontOption${selectedVal}`);
      const selected: HTMLElement | null = document.querySelector('.item-radio-checked');
      // console.log(selected)
      if (selected) {
        console.log(selected && selected.getBoundingClientRect().top)
        selected.scrollIntoView();
      }
    }, 300);
  }

  let curTextColor = '';
  if (selectedElement && plannerControls.stageTexts && selectedElement.textId && pageType.current != undefined && pageTypeCount.current != undefined) {
    curTextColor = plannerControls.stageTexts[pageType.current || 0][pageTypeCount.current || 0][selectedElement.textId].textColor;
  } else {
    curTextColor = '#000000';
  }

  return (
    <>
      {plannerControls.selectedElement.type == 'text' && (
        <div className="stageTextControls ion-margin-start">
          <IonButtons className="ion-padding-end" slot="start">
            <IonButton className="ion-no-padding" fill="clear" style={{ height: 'auto' }}
              onClick={() => {
                dispatch(plannerActions.remove_stage_text({
                  id: plannerControls.selectedElement.textId,
                  pageType: pageType.current,
                  pageTypeCount: pageTypeCount.current
                }))
                dispatch(plannerActions.set_stage_texts_changed(pageType.current, pageTypeCount.current))
              }}>
              <IonIcon slot="icon-only" style={{ fontSize: 20 + 'px' }} className="ion-no-padding" icon={trash} color="warning" />
            </IonButton>
            <div className="headerDelimeter" />
            <FontSelector target="stageTexts" updateTextEl={(newFont: any) => updateTextEl(selectedElement.textId, undefined, undefined, newFont)} />
            <IonSelect interface="popover" onIonFocus={(e) => scrollToSelected(e)} value={parseInt(plannerControls.stageTexts[userData.activePlanner.pages[curPage].pageId][userData.activePlanner.pages[curPage].pageTypeCount][selectedElement.textId].fontSize)}
              onIonChange={e => {
                updateTextEl(selectedElement.textId, e.detail.value);
              }}>
              {fontSizeOptions}
            </IonSelect>
            <div className="headerDelimeter" />
            <DrawModeColorPicker
              defaultColor={darkMode ? invertColor(curTextColor) : curTextColor}
              onChangeColor={(oldColor: string, newColor: string) => updateTextEl(selectedElement.textId, undefined, darkMode ? invertColor(newColor) : newColor)} />
            <div className="headerDelimeter" />
          </IonButtons>
        </div>
      )}
    </>
  )
}

export default StageTextControls;