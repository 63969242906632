import React, { useEffect, useRef, useState } from 'react';
import * as userDataActions from '../../store/actions/userData';
import * as libraryActions from '../../store/actions/library';
import { useSelector, useDispatch } from 'react-redux';
import './ChooseCoverModal.css';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from '@ionic/react';
import { addOutline, arrowBackOutline, checkmarkCircle } from 'ionicons/icons';
import { useHistory } from 'react-router';
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';
import axios from 'axios';
import { logScreenViewEvent } from '../../services/logger';

type Props = {
  config: any;
  hideModal: Function;
  saveSelectedCover: Function;
}

const ChooseCoverModal: React.FC<Props> = ({ config, hideModal, saveSelectedCover }) => {
  const history = useHistory();
  const auth = useSelector((state: any) => state.firebase.auth);
  const userData = useSelector((state: any) => state.userData);
  const { loading, coversLibrary } = useSelector((state: any) => state.library);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);
  const [selectedCover, setSelectedCover] = useState();
  const [coverParams, setCoverParams] = useState<any>();
  const [customCoverText, setCustomCoverText] = useState<any>();
  const [customizationStep, setCustomizationStep] = useState(false);
  const [loadingCustomPreview, setLoadingCustomPreview] = useState(false);
  const [lastGeneratedCover, setLastGeneratedCover] = useState<string | null>();
  const [prevText, setPrevText] = useState<any>();
  const coverSaved = useRef<boolean>(false);
  let covers: any = '';

  useIonViewDidEnter(() => {
    document.title = 'Choose planner cover - Digital Planner App';
    logScreenViewEvent();
  }, [])

  useEffect(() => {
    dispatch(libraryActions.load_covers_library());
    // console.log(config.originalCoverFile)
  }, []);

  useEffect(() => {
    if (selectedCover) {
      setCoverParams(coversLibrary.find((cover: any) => selectedCover === cover.id))
      setCustomizationStep(true);
    }
  }, [selectedCover])

  const updateCoverPreview = () => {
    setLoadingCustomPreview(true);
    setPrevText(customCoverText);
    if (lastGeneratedCover) dispatch(userDataActions.delete_old_cover(lastGeneratedCover));
    const getCoverUrl = `https://digital-planner.com/planner/api/cover?cover_id=${coverParams.builderId}&cover_text=${customCoverText ? customCoverText : '&nbsp;'}`;
    axios.get(getCoverUrl).then((response) => {
      const coverUpdate = { ...coverParams, customFrontImage: response.data.url_front, customBackImage: response.data.url_back };
      setCoverParams(coverUpdate);
      setLoadingCustomPreview(false);
      setLastGeneratedCover(response.data.url_front.replace('https://digital-planner.com/sites/default/files/planner-app-covers/', ''));
    })
      .catch((e) => { setLoadingCustomPreview(false); console.log(e); })
  }

  const closeModal = () => {
    if (lastGeneratedCover && !coverSaved.current) dispatch(userDataActions.delete_old_cover(lastGeneratedCover));
    hideModal();
  }

  return (
    <IonModal isOpen={showModal} cssClass="add-planner-modal" onWillDismiss={() => closeModal()}>
      <IonHeader>
        <IonToolbar>
          <IonTitle style={{ textAlign: 'center' }}>Choose Planner Cover</IonTitle>
          <IonButtons slot="start">
            {customizationStep && <IonButton onClick={() => setCustomizationStep(false)}><IonIcon icon={arrowBackOutline} style={{ fontSize: 22 + 'px' }} /></IonButton>}
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => closeModal()}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <>
          {customizationStep
            ? <>
              <IonRow>
                <IonCol size="6">
                  {loadingCustomPreview
                    ? <LoadingSpinner type="inline" />
                    : <img src={coverParams.customFrontImage || coverParams.frontImage} />
                  }
                </IonCol>
                <IonCol size="6">
                  <img src={coverParams.customBackImage || coverParams.backImage} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <IonItem>
                    <IonLabel position="floating">Custom cover text:</IonLabel>
                    <IonInput value={customCoverText} onIonChange={(e) => setCustomCoverText(e.detail.value)} />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" style={{ textAlign: 'center' }}>
                  {customCoverText != prevText || loadingCustomPreview
                    ? <IonButton onClick={() => updateCoverPreview()} disabled={loadingCustomPreview} color="secondary">Preview</IonButton>
                    : null
                  }
                  {customCoverText && !loadingCustomPreview && customCoverText === prevText
                    ? <IonButton onClick={() => { coverSaved.current = true; saveSelectedCover(coverParams); closeModal(); }}>Save</IonButton>
                    : null
                  }
                </IonCol>
              </IonRow>
            </>
            : loading
              ? <LoadingSpinner />
              : <IonRow>
                {coversLibrary?.map((cover: any) => {
                  return (
                    <IonCol size="4" key={cover.id}>
                      <div className="">
                        <IonThumbnail className="coverThumbnail">
                          {selectedCover === cover.id && <IonIcon className="selectedIcon" color="light" size="large" icon={checkmarkCircle} />}
                          <img src={cover.frontImage} onClick={() => { setSelectedCover(cover.id) }} />
                        </IonThumbnail>
                      </div>
                    </IonCol>
                  )
                })}
              </IonRow>
          }
        </>
      </IonContent>
    </IonModal>
  )
}

export default ChooseCoverModal;