import moment from 'moment';
import { convertStylesStringToObject } from '../../../services/htmlStylesParser';
import './PlannerEvents.css';
import { hexToHsl, hslToHex } from '../../../services/colorConversters';

const timeSlots: any = {
  '15m': 15,
  '30m': 30,
  '1h': 60,
  '2h': 120
}
const timeSlots2: any = {
  '15m': 15,
  '30m': 30,
  '1h': 100,
  '2h': 200
}

export class Events {
  scheduleEl: HTMLElement;
  events: any = [];
  setCurEvent: Function;
  addEvent: Function
  wrapper: any;
  wrapperStyles: any = {};
  dates: any = [];
  timeStart: number = 0;
  timeEnd: number = 0;
  eventFeeds: any = [];
  oneBlockHeight: number = 0;
  calendars: any = [];
  touchClickX: number = 0;
  fullDay: boolean = false;

  constructor(scheduleEl: HTMLElement, userData: any, curPage: number, actualPage: number, events: any, calendars: any, setCurEvent: Function, addEvent: Function) {
    this.scheduleEl = scheduleEl;
    this.calendars = calendars;
    this.setCurEvent = setCurEvent;
    this.addEvent = addEvent;
    this.fullDay = scheduleEl.querySelector('.js-data-event')?.getAttribute('data-time-period') == '24h' ? true : false;

    this.getDaysCount(userData, curPage, actualPage);
    this.setEventsHolder();
    this.setEvents([...events]);
    this.setEventFeeds();
    this.recalcEventsPositions();
  }

  getDaysCount(userData: any, curPage: number, actualPage: number) {
    // const days: any = [];
    let times: any = [];
    this.scheduleEl.querySelectorAll('.js-data-event').forEach((block: Element) => {
      const pageDate = moment(userData.activePlanner?.pages[curPage + actualPage].date);
      const blockDayNum = parseInt(block.getAttribute('data-block-number')!);
      const blockDate = pageDate.clone().add(blockDayNum, 'days').format('YYYYMMDD');
      const blockPeriod = block.getAttribute('data-time-period')!;
      const blockRowNum = parseInt(block.getAttribute('data-row-number')!);
      const blockStartTime = parseInt(block.getAttribute('data-time')!.replace(':', '')) + (timeSlots2[blockPeriod] * blockRowNum);
      const blockEndTime = blockStartTime + timeSlots2[blockPeriod];
      times.push(blockStartTime)
      times.push(blockEndTime);
      this.dates.indexOf(blockDate) < 0 && this.dates.push(blockDate);
    })
    this.timeStart = Math.min(...times);
    this.timeEnd = Math.max(...times);
  }

  setEvents(events: any) {
    this.dates.forEach((date: string) => {
      const formattedDate = moment(date).format('YYYYMMDD');
      const dayStartMoment = moment(date).startOf('day');
      const dayEndMoment = moment(date).endOf('day');
      events.forEach((event: any) => {
        // const eventBg = this.calendars.filter((calendar: any) => calendar.url == event.calendar);
        const hslBg = hexToHsl(event.color ? event.color.replace('FF', '') : '#FFFFFF');
        hslBg.l *= 0.7;
        const borderColor = hslToHex(hslBg.h, hslBg.s, hslBg.l);

        const eventStartDate = moment(event.start);
        const eventEndDate = moment(event.end);
        let eventStartTime = parseInt(moment(event.start).format('HH'));
        let eventEndTime = parseInt(moment(event.end).format('HH'));

        const eventStartMinutes = parseInt(eventStartDate.format('mm')) > 0 ? (parseInt(eventStartDate.format('mm')) / 60 * 100).toString() : '00';
        const eventEndMinutes = parseInt(eventEndDate.format('mm')) > 0 ? (parseInt(eventEndDate.format('mm')) / 60 * 100).toString() : '00';
        eventStartTime = parseInt(eventStartTime.toString() + eventStartMinutes);
        eventEndTime = parseInt(eventEndTime.toString() + eventEndMinutes);

        if (eventStartDate.isSameOrBefore(dayStartMoment) && eventEndDate.isSameOrAfter(dayEndMoment)) {
          // all day event
          // console.log('all day')
          if (!this.events[`${formattedDate}`]) this.events[`${formattedDate}`] = [];
          let styles: any = {};
          this.fullDay
            ? styles = {
              position: 'relative',
              height: ((eventEndTime - this.timeStart) / 100) * this.oneBlockHeight + 'px',
              backgroundColor: event.color,
              borderColor: borderColor
            }
            : styles = {
              position: 'absolute',
              top: (this.timeStart / 100) * this.oneBlockHeight + 'px',
              height: (this.timeEnd - this.timeStart) / 100 * this.oneBlockHeight + 'px',
              backgroundColor: event.color,
              borderColor: borderColor
            }
          this.events[`${formattedDate}`].push({
            eventData: event,
            styles: {...styles}
          })
        }
        else if (eventStartDate.isSameOrAfter(dayStartMoment) && eventStartDate.isSameOrBefore(dayEndMoment) && eventEndDate.isSameOrAfter(dayEndMoment)) {
          // big outcoming event
          // console.log('big outcoming')
          if (!this.events[`${formattedDate}`]) this.events[`${formattedDate}`] = [];
          let styles: any = {};
          this.fullDay
            ? styles = {
              position: 'relative',
              height: ((eventEndTime - this.timeStart) / 100) * this.oneBlockHeight + 'px',
              backgroundColor: event.color,
              borderColor: borderColor
            }
            : styles = {
              position: 'absolute',
              top: (eventStartTime / 100) * this.oneBlockHeight + 'px',
              height: (this.timeEnd - this.timeStart) / 100 * this.oneBlockHeight + 'px',
              backgroundColor: event.color,
              borderColor: borderColor
            }
          this.events[`${formattedDate}`].push({
            eventData: event,
            styles: {...styles}
          })
        }
        else if (eventStartDate.isSameOrBefore(dayStartMoment) && eventEndDate.isSameOrAfter(dayStartMoment) && eventEndDate.isSameOrBefore(dayEndMoment)) {
          // big incoming event
          // console.log('big incoming')
          if (!this.events[`${formattedDate}`]) this.events[`${formattedDate}`] = [];
          let styles: any = {};
          this.fullDay
            ? styles = {
              position: 'relative',
              height: ((eventEndTime - this.timeStart) / 100) * this.oneBlockHeight + 'px',
              backgroundColor: event.color,
              borderColor: borderColor
            }
            : styles = {
              position: 'absolute',
              top: (eventStartTime / 100) * this.oneBlockHeight + 'px',
              height: ((eventEndTime - eventStartTime) / 100) * this.oneBlockHeight + 'px',
              backgroundColor: event.color,
              borderColor: borderColor
            }
          this.events[`${formattedDate}`].push({
            eventData: event,
            styles: {...styles}
          })
        }
        else if (eventStartDate.isSameOrAfter(dayStartMoment) && eventEndDate.isSameOrBefore(dayEndMoment)) {
          // small event
          // console.log('small')
          if (!this.events[`${formattedDate}`]) this.events[`${formattedDate}`] = [];
          let styles: any = {};
          this.fullDay
            ? styles = {
              position: 'relative',
              height: ((eventEndTime - eventStartTime) / 100) * this.oneBlockHeight + 'px',
              backgroundColor: event.color,
              borderColor: borderColor
            }
            : styles = {
              position: 'absolute',
              top: (eventStartTime / 100) * this.oneBlockHeight + 'px',
              height: ((eventEndTime - eventStartTime) / 100) * this.oneBlockHeight + 'px',
              backgroundColor: event.color,
              borderColor: borderColor
            }
          this.events[`${formattedDate}`].push({
            eventData: event,

            styles: {...styles}
          })
        }
      })
    })
  }

  setEventFeeds() {
    const dayWidth = this.wrapperStyles.width / this.dates.length;
    this.dates.forEach((date: string, index: number) => {
      // const width = dayWidth - dayWidth * 0.03;
      const width = dayWidth - 8;
      const left = (dayWidth * index) + 5 + 'px';
      const top = -(this.timeStart / 100 * this.oneBlockHeight) + 'px';
      const height = this.fullDay ? '100%' : this.oneBlockHeight * 24 + 'px';

      const feedStyles: any = {
        position: 'absolute',
        width: width,
        left: left,
        top: top,
        height: height,
      }
      this.events[`${date}`] && this.events[`${date}`].sort((a: any, b: any) => parseInt(a.styles.top) - parseInt(b.styles.top));
      const addEventBttns = [];
      for (let index = 0; index < 24; index++) {
        if(this.fullDay && index > 0) break;
        const startDate = this.fullDay ? moment(date).set('hours', 13).set('minutes', 0).toISOString() : moment(date).set('hours', index).set('minutes', 0).toISOString();
        addEventBttns.push((
          <div
            key={startDate}
            className="addEventBttn"
            onClick={(e) => { this.addEvent(startDate) }}
            onTouchStart={(e) => { this.touchClickX = e.changedTouches[0].clientX; }}
            onTouchEnd={(e) => { if (Math.abs(e.changedTouches[0].clientX - this.touchClickX) < (window.innerWidth * 0.01) && e.touches.length < 1) this.addEvent(startDate); }}
            style={{
              width: 100 + '%',
              height: this.fullDay ? '100%' : 4.166666 + '%',
              cursor: 'pointer',
              position: this.fullDay ? 'absolute' : 'relative'
            }}></div>
        ))
      }
      const feed = (
        <div style={{ ...feedStyles }} key={`feed-${index}`} className="eventFeed">
          {addEventBttns.map((bttn) => (bttn))}
          {this.events[`${date}`] && this.events[`${date}`].map((event: any, index: number) => {
            return (
              <div key={event.eventData.created + index} className="event" style={{
                ...event.styles,
                // position: 'relative',
                width: this.fullDay ? '80%' : 100 / this.events[`${date}`].length - 2 + '%',
                left: this.fullDay ? '0px' : ((feedStyles.width / this.events[`${date}`].length) * index) + 'px',
                marginBottom: this.fullDay ? '5px' : 0,
              }}
                onClick={() => { this.setCurEvent(event.eventData.uid) }}
                onTouchStart={(e) => { this.touchClickX = e.changedTouches[0].clientX; }}
                onTouchEnd={(e) => { if (Math.abs(e.changedTouches[0].clientX - this.touchClickX) < (window.innerWidth * 0.01) && e.touches.length < 1) this.setCurEvent(event.eventData.uid); }}
              >
                <span className='eventSummary'>{event.eventData.summary}</span>
                <span className='eventSummary'>{moment(event.eventData.start).format('HH:mm')} - {moment(event.eventData.end).format('HH:mm')}</span>
              </div>
            )
          })}
        </div>
      )
      this.eventFeeds.push(feed);
    })
  }

  recalcEventsPositions() {
    if(this.fullDay) return;
    setTimeout(() => {
      const summaryHeight = 20;
      document.querySelectorAll('.calendarEvents .eventFeed').forEach((eventFeed: Element) => {
        const feedEvents: any = [];
        eventFeed.querySelectorAll('.event').forEach((event: Element) => {
          const styles: any = convertStylesStringToObject(event.getAttribute('style')!);
          feedEvents.push({ start: parseInt(styles.top), eventEl: event, moved: false })
        })
        const moved: any = [];
        let index = 0;
        if (feedEvents.length < 1) return;
        feedEvents.forEach((event: any) => {
          const overlapped = feedEvents.findIndex((event2: any) => (event2.eventEl !== event.eventEl) && (event2.start < (event.start + summaryHeight)) && (event2.start > (event.start - summaryHeight)));
          if (overlapped < 0 && moved.indexOf(event.eventEl) < 0) {
            // console.log(event.eventEl)
            event.eventEl.style.left = 10 * index + '%';
            event.eventEl.style.width = (100 - (10 * index) > 80 ? 80 : 100 - (10 * index)) + '%';
            moved.push(event.eventEl)
            index++;
          }
        })
      })
    }, 100);
  }

  // setEventsHolder() {
  //   const plannerPagesWrapperLeft = document.querySelector('.plannerPagesWrapper')?.getBoundingClientRect().left || 0;
  //   const styles = this.scheduleEl.getBoundingClientRect();
  //   console.log(styles);
  //   const offsetTop = this.scheduleEl.offsetTop;
  //   const theadHeight: any = this.scheduleEl.querySelector('.pl-header')?.clientHeight || 0;
  //   const timeline = this.scheduleEl.querySelector('.js-data-time');
  //   this.wrapperStyles = { ...styles };
  //   if (timeline) {
  //     const timelineStyles = timeline.getBoundingClientRect();
  //     this.wrapperStyles.width = styles.width - timelineStyles.width;
  //     this.wrapperStyles.left = styles.left + timelineStyles.width - plannerPagesWrapperLeft;
  //     this.wrapperStyles.height = styles.height - theadHeight;
  //     this.wrapperStyles.top = offsetTop + theadHeight;
  //   }
  //   this.oneBlockHeight = this.wrapperStyles.height / ((this.timeEnd - this.timeStart) / 100);
  // }
  setEventsHolder() {
    const scheduleBody: HTMLElement = this.scheduleEl.querySelector('.pl-body')!;
    const styles = scheduleBody.getBoundingClientRect();
    const offsets = getOffsetTo(this.scheduleEl.querySelector('.pl-body'), 0, 0);
    const timeline: HTMLElement = this.scheduleEl.querySelector('.js-data-time')!;
    this.wrapperStyles = { ...styles };
    if (timeline && offsets) {
      this.wrapperStyles.width = scheduleBody.offsetWidth - timeline.offsetWidth;
      this.wrapperStyles.left = offsets.left + timeline.offsetWidth;
      this.wrapperStyles.height = scheduleBody.offsetHeight;
      this.wrapperStyles.top = offsets.top;
    }
    this.oneBlockHeight = this.wrapperStyles.height / ((this.timeEnd - this.timeStart) / 100);
  }

  renderEvents() {
    this.wrapper = (<div className="calendarEvents"
      style={{
        top: (this.wrapperStyles.top || 0) + 'px',
        left: (this.wrapperStyles.left || 0) + 'px',
        width: this.wrapperStyles.width + 'px',
        height: this.wrapperStyles.height + 'px',
        position: 'absolute',
        // backgroundColor: '#fafafa',
        // overflow: this.fullDay ? 'scroll' : 'hidden',
        overflow: 'hidden',
        zIndex: 999
      }}>
      {this.eventFeeds && this.eventFeeds.map((feed: any) => feed)}
    </div>)
    return this.wrapper;
  }
}


export class MonthlyEvents {
  scheduleEl: HTMLElement;
  events: any = [];
  setCurEvent: Function;
  wrappers: any = [];
  calendars: any = [];
  touchClickX: number = 0;

  constructor(scheduleEl: HTMLElement, events: any, calendars: any, setCurEvent: Function) {
    this.scheduleEl = scheduleEl;
    this.calendars = calendars;
    this.setCurEvent = setCurEvent;
    this.events = events;
    this.setDays();

  }

  setDays() {
    this.scheduleEl.querySelectorAll('.js-data-event').forEach((dayBlock: any, index: number) => {
      const dateHeight = dayBlock.parentElement.querySelector('.date').clientHeight;
      const offsets = getOffsetTo(dayBlock, 0 - dateHeight, 0);
      const wrapper = {
        width: dayBlock.clientWidth,
        height: dayBlock.clientHeight,
        top: (offsets && offsets.top) || 0,
        left: (offsets && offsets.left) || 0,
        date: dayBlock.getAttribute('data-date'),
        events: this.setEvents(dayBlock.getAttribute('data-date')),
      };
      this.wrappers.push(wrapper);
    })
  }

  setEvents(date: string) {
    const dateEvents: any = [];
    const dateMoment = moment(parseInt(date)).format('YYYYMMDD');
    this.events.forEach((event: any) => {
      const eventStartMoment = moment(event.start).format('YYYYMMDD');
      if (eventStartMoment === dateMoment) {
        dateEvents.push((
          <div
            className="monthEvent"
            key={event.uid}
            style={{ backgroundColor: event.color }}
            onClick={() => { this.setCurEvent(event.uid) }}
            onTouchStart={(e) => { this.touchClickX = e.changedTouches[0].clientX; }}
            onTouchEnd={(e) => { if (Math.abs(e.changedTouches[0].clientX - this.touchClickX) < (window.innerWidth * 0.01) && e.touches.length < 1) this.setCurEvent(event.uid); }}

          >
            <span className='eventSummary'>{event.summary} ({moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')})</span>
            {/* <span className='eventSummary'>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</span> */}
          </div>
        ))
      }
    })
    return dateEvents;
  }

  renderEvents() {
    return (
      this.wrappers.map((wrapper: any) => {
        return (
          <div
            className="monthCalendarEventsDay"
            style={{
              position: 'absolute',
              width: wrapper.width + 'px',
              height: wrapper.height + 'px',
              top: wrapper.top + 'px',
              left: wrapper.left + 'px',
              zIndex: 999,
              borderTop: '1px dotted #ccc',
              paddingLeft: 3 + 'px',
              paddingTop: 3 + 'px',
              paddingRight: 8 + 'px',
              paddingBottom: 3 + 'px',
            }}
            key={wrapper.date}
          >
            {wrapper.events.map((event: any) => (event))}
          </div>
        )
      })
    )
  }
}

function getOffsetTo(el: any, top: number, left: number): any {
  if (!el) return;
  let newTop = top;
  let newLeft = left;
  if (el.classList.value && el.classList.value.includes('plannerPagesWrapper')) {
    return { top: newTop, left: newLeft };
  }
  if (el.tagName !== 'TD') {
    newTop += el.offsetTop;
  }
  newLeft += el.offsetLeft;
  return getOffsetTo(el.parentElement, newTop, newLeft);
}