import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Line, Text, Transformer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import * as plannerActions from '../../store/actions/plannerControls';
import PlannerStageImage from './PlannerStageImage';
import PlannerStageSVG from './PlannerStageSVG';
import PlannerStageText from './PlannerStageText';
import { ReactReduxContext, Provider } from "react-redux";
import { useCheckPermission } from '../../services/userRolesContext';
import { invertColor } from '../../services/colorConversters';

type StageProps = {
  canvasWidth: number;
  canvasHeight: number;
  actualPage?: number;
}

const PlannerStage: React.FC<StageProps> = ({ canvasWidth, canvasHeight, actualPage = 0 }) => {
  const userPermissions: any = useCheckPermission();
  const plannerControls = useSelector((state: any) => state.plannerControls);
  const userData = useSelector((state: any) => state.userData);
  const curLinesChanged = useSelector((state: any) => state.plannerControls.linesChanged);
  const plannerPages = useSelector((state: any) => state.userData.activePlanner.pages);
  const drawMode = useSelector((state: any) => state.plannerControls.drawMode);
  const plannerId = useSelector((state: any) => state.userData.activePlanner.fbPlannerId);
  const plannerYear = useSelector((state: any) => state.userData.activePlanner.plannerYear);
  const uid = useSelector((state: any) => state.auth.uid);
  // const pageType = useSelector((state: any) => state.userData.activePlanner.pages[plannerControls.curPage].pageId);
  // const pageTypeCount = useSelector((state: any) => state.userData.activePlanner.pages[plannerControls.curPage].pageTypeCount);
  const pageType = useRef<any>();
  const pageTypeCount = useRef<any>();
  const pageTypeCorrection = useRef<any>(0);
  // const [pageTypeCorrection.current, setpageTypeCorrection.current]= useState(pageTypeCount);
  const tool = plannerControls.drawMethod;
  const [pageStickers, setPageStickers] = useState([])
  const dispatch = useDispatch();
  const [lines, setLines] = useState<any>([]);
  const [texts, setTexts] = useState<any>([]);
  const isDrawing = useRef(false);
  const pagerStep = useSelector((state: any) => state.userData.pagerStep);
  const stageRef = useRef<any>();
  const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  useEffect(() => {
    if(plannerControls.selectedElement) dispatch(plannerActions.select_stage_element({}));
    pageType.current = userData.activePlanner.pages[plannerControls.curPage].pageId;
    pageTypeCount.current = userData.activePlanner.pages[plannerControls.curPage].pageTypeCount;
    if (pageTypeCount.current % 2) {
      pageTypeCorrection.current = pageTypeCount.current - 1;
    } else {
      pageTypeCorrection.current = pageTypeCount.current;
    }
  }, [plannerControls.curPage])

  useEffect(() => {
    dispatch(plannerActions.load_planner_lines(uid, plannerId, plannerYear));
    dispatch(plannerActions.load_planner_stickers(uid, plannerId, plannerYear));
    dispatch(plannerActions.load_planner_texts(uid, plannerId, plannerYear));
    dispatch(plannerActions.load_planner_data(uid, plannerId, plannerYear))
  }, [plannerYear]);

  // useEffect(() => {
    // Moved to autosaver
    // const reduxLines = plannerControls.lines[pageType.current] && plannerControls.lines[pageType.current][pageTypeCorrection.current] && JSON.parse(plannerControls.lines[pageType.current][pageTypeCorrection.current]);
    // if (reduxLines && lines.length && !drawMode) {
    //   dispatch(plannerActions.save_planner_lines_to_db(uid, userPermissions, plannerId, pageType.current, pageTypeCorrection.current, lines, plannerYear));
    // }
    // Moved to autosaver
    // if (plannerControls.stickers[pageType.current] && plannerControls.stickers[pageType.current][pageTypeCorrection.current] && !drawMode) {
    //   dispatch(plannerActions.update_db_stickers(uid, userPermissions, plannerId, plannerYear, pageType.current, pageTypeCorrection.current, plannerControls.stickers[pageType.current][pageTypeCorrection.current]))
    // }
    // Moved to autosaver
    // if (plannerControls.stageTexts[pageType.current] && plannerControls.stageTexts[pageType.current][pageTypeCorrection.current] && !drawMode) {
    //   dispatch(plannerActions.update_db_stageTexts(uid, userPermissions, plannerId, plannerYear, pageType.current, pageTypeCorrection.current, plannerControls.stageTexts[pageType.current][pageTypeCorrection.current]))
    // }
  // }, [drawMode])

  useEffect(() => {
    (plannerControls.lines[pageType.current] && plannerControls.lines[pageType.current][pageTypeCorrection.current])
      ? setLines(JSON.parse(plannerControls.lines[pageType.current][pageTypeCorrection.current]))
      : setLines([])
  }, [plannerControls.lines, plannerControls.curPage]);

  const handleMouseDown = (e: any) => {
    if (!plannerControls.drawMode || plannerControls.panning || plannerControls.pinching || tool === 'controls') {
      if ((e.target === e.target.getStage()) && (plannerControls.selectedElement.type || !isNaN(plannerControls.selectedLine.index))) {
        dispatch(plannerActions.cleanup_selected_elements())
      }
      return
    };
    if (!plannerControls.panning && !plannerControls.pinching) {
      isDrawing.current = true;
      dispatch(plannerActions.set_drawing(true));
      const pos = e.target.getStage().getPointerPosition();
      setLines([...lines, { tool, strokeColor: darkMode ? invertColor(plannerControls.strokeColor) : plannerControls.strokeColor, strokeWidth: plannerControls.drawMethod === 'eraser' ? plannerControls.eraserWidth : plannerControls.strokeWidth, points: [pos.x / scale, pos.y / scale] }]);
      dispatch(plannerActions.set_lines_changed(pageType.current, pageTypeCorrection.current));
    }

  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing.current || !plannerControls.drawMode || plannerControls.panning || plannerControls.pinching) return;
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    if (!plannerControls.panning && !plannerControls.pinching) {
      let lastLine: any = lines[lines.length - 1];
      lastLine.points = lastLine.points.concat([point.x / scale, point.y / scale]);
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    }
  };

  const handleMouseUp = () => {
    if (!plannerControls.drawMode || plannerControls.panning || plannerControls.pinching) return;
    isDrawing.current = false;
    dispatch(plannerActions.set_drawing(false));
    dispatch(plannerActions.set_planner_lines(uid, plannerId, pageType.current, pageTypeCorrection.current, lines));
    if(plannerControls.drawMethod !== 'controls' ) {
      dispatch(plannerActions.set_lines_changed(pageType.current, pageTypeCorrection.current));
    }
  };

  // const updateStickerPos = (sticker: any) => {
  //   dispatch(plannerActions.update_sticker_position(pageType.current, pageTypeCorrection.current, sticker));
  //   if(!curStickersChanged[pageType.current] || (curStickersChanged[pageType.current] && !curStickersChanged[pageType.current][pageTypeCorrection.current])) {
  //     dispatch(plannerActions.set_stickers_changed(pageType.current, pageTypeCorrection.current));
  //   }
  // }

  const selectLine = (line: any, index: number, params: any) => {
    if (plannerControls.drawMethod == 'controls') {
      dispatch(plannerActions.select_line({ index, ...params }))
    }
  }

  // useEffect(() => {
  //   dispatch(plannerActions.set_planner_lines(uid, plannerId, pageType.current, pageTypeCorrection.current, lines));
  // }, [lines])

  let canvasRatio = canvasWidth / canvasHeight;
  let sceneHeight = 1000;
  let sceneWidth = sceneHeight * canvasRatio;
  let scale = (canvasWidth * plannerControls.zoom) / sceneWidth;
  let canvasOffset = pageTypeCount.current % 2 == 0 ? 0 : -sceneWidth * scale / 2;

  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <>
          <div className={plannerControls.drawMode ? 'plannerCanvas canvasActive' : 'plannerCanvas canvasStatic'}
            style={pagerStep != 2 ? { width: `${(sceneWidth * scale) / 2}px`, overflowX: 'hidden' } : {}}>
            <Stage
              ref={stageRef}
              style={{ marginLeft: canvasOffset + 'px' }}
              width={sceneWidth * scale}
              height={sceneHeight * scale}
              onMouseDown={handleMouseDown}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchMove={handleMouseMove}
              onTouchEnd={handleMouseUp}
              scale={{ x: scale, y: scale }}
            >
              <Provider store={store}>
                <Layer>
                  {plannerControls.stickers[pageType.current]
                    && plannerControls.stickers[pageType.current][pageTypeCorrection.current]
                    && plannerControls.stickers[pageType.current][pageTypeCorrection.current].map((sticker: any, index: number) => {
                      // console.log(sticker);
                      if (sticker.type == 'svg') {
                        return (
                          <PlannerStageSVG
                            key={`page${pageTypeCorrection.current}-${index}`}
                            svgSource={sticker.source}
                            imageUrl={sticker.url}
                            imageX={sticker.x}
                            imageY={sticker.y}
                            initialScaleX={sticker.scaleX}
                            initialScaleY={sticker.scaleY}
                            initialRotation={sticker.rotation}
                            drawMethod={tool}
                            stickerId={index}
                            stickerGlobalId={sticker.globalId}
                            // updatePos={(sticker: any) => updateStickerPos(sticker)}
                            />
                        )
                      } else {
                        return (
                          <PlannerStageImage
                            key={`page${pageTypeCorrection.current}-${index}`}
                            imageUrl={sticker.url}
                            imageX={sticker.x}
                            imageY={sticker.y}
                            initialScaleX={sticker.scaleX}
                            initialScaleY={sticker.scaleY}
                            initialRotation={sticker.rotation}
                            drawMethod={tool}
                            stickerId={index}
                            stickerGlobalId={sticker.globalId}
                            // updatePos={(sticker: any) => updateStickerPos(sticker)} 
                            />
                        )
                      }
                    })}
                </Layer>
                <Layer>
                  {plannerControls.stageTexts[pageType.current]
                    && plannerControls.stageTexts[pageType.current][pageTypeCorrection.current]
                    && plannerControls.stageTexts[pageType.current][pageTypeCorrection.current].map((text: any, i: number) => {
                      let textColor = '';
                      // if(text.textColor) {
                        if(darkMode) {
                          textColor = invertColor(text.textColor);
                        }else{
                          textColor = text.textColor;
                        }
                      // }else{
                      //   strokeColor = plannerControls.strokeColor;
                      // }
                      return (<PlannerStageText
                        key={`stageText-${i}`}
                        textId={i}
                        stageRef={stageRef}
                        originalText={text.text}
                        fontSize={text.fontSize}
                        textColor={textColor}
                        coords={{ x: text.x, y: text.y }}
                        size={{ width: text.width, height: text.height }}
                        rotation={text.rotation}
                        fontFamily={text.fontFamily}
                      />)
                    })}
                </Layer>
                <Layer imageSmoothingEnabled={true}>
                  {lines.map((line: any, i: any) => {
                    let strokeColor = '';
                    if(line.strokeColor) {
                      if(darkMode) {
                        strokeColor = invertColor(line.strokeColor);
                      }else{
                        strokeColor = line.strokeColor;
                      }
                    }else{
                      strokeColor = plannerControls.strokeColor;
                    }
                    return line.tool != 'eraser' ? (
                      <Line
                        draggable={line.tool != 'eraser' && tool === 'controls' && plannerControls.panning != true && plannerControls.pinching != true}
                        key={i}
                        points={line.points}
                        stroke={strokeColor}
                        x={line.x}
                        y={line.y}
                        strokeWidth={line.strokeWidth || plannerControls.strokeWidth}
                        // tension={0}
                        bezier={true}
                        lineCap="round"
                        globalCompositeOperation={
                          line.tool === 'eraser' ? 'destination-out' : 'source-over'
                        }
                        onClick={(e: any) => selectLine(e.target, i, {
                          strokeColor: line.strokeColor || plannerControls.strokeColor,
                          strokeWidth: line.strokeWidth || plannerControls.strokeWidth
                        })
                        }
                        onTap={(e: any) => selectLine(e.target, i, {
                          strokeColor: line.strokeColor || plannerControls.strokeColor,
                          strokeWidth: line.strokeWidth || plannerControls.strokeWidth
                        })
                        }
                        onDragMove={(e: any) => {
                          if(!plannerControls.selectedLine || plannerControls.selectedLine.index !== i){
                            selectLine(e.target, i, {
                            strokeColor: line.strokeColor || plannerControls.strokeColor,
                            strokeWidth: line.strokeWidth || plannerControls.strokeWidth
                            })
                          }
                        }}
                        onDragEnd={(e) => {
                          const lastLines = [...lines];
                          lastLines[i].x = e.target.attrs.x;
                          lastLines[i].y = e.target.attrs.y;
                          setLines([...lastLines]);
                          dispatch(plannerActions.set_planner_lines(uid, plannerId, pageType.current, pageTypeCorrection.current, [...lastLines]));
                          dispatch(plannerActions.set_lines_changed(pageType.current, pageTypeCorrection.current));
                        }}
                        shadowEnabled={plannerControls.selectedLine.index == i ? true : false}
                        shadowColor='#00ff00'
                        shadowBlur={5}
                        hitStrokeWidth={20}
                      />
                    ) : (
                      <Line
                        draggable={false}
                        key={i}
                        points={line.points}
                        stroke={line.strokeColor || plannerControls.strokeColor}
                        strokeWidth={line.strokeWidth || plannerControls.strokeWidth}
                        // tension={0}
                        bezier={true}
                        lineJoin="round"
                        lineCap="round"
                        globalCompositeOperation='destination-out'
                        listening={false}
                      />
                    )
                  }
                  )
                  }
                </Layer>
              </Provider>
            </Stage>
          </div>
        </>
      )}
    </ReactReduxContext.Consumer>
  )
}
export default PlannerStage;