import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ChooseCoverModal.css';
import {
  IonButton,
  IonCheckbox,
  IonItem,
  IonItemDivider,
  IonList,
  useIonViewDidEnter,
  useIonViewWillLeave
} from '@ionic/react';
import { useHistory } from 'react-router';
type Props = {
  config: any;
  saveSelectedCalendars: Function;
}

const ChooseCalendarsModal: React.FC<Props> = ({ config, saveSelectedCalendars }) => {
  const userCalendars = useSelector((state: any) => state.userCalendars);
  const userData = useSelector((state: any) => state.userData);
  const dispatch = useDispatch();
  const [calPageActive, setCalPageActive] = useState<boolean>(true);
  const history = useHistory();


  useIonViewDidEnter(() => {
    setCalPageActive(true);
  })

  useIonViewWillLeave(() => {
    setCalPageActive(false);
  })

  const currentPlanner = userData.userPlanners.filter((planner: any) => planner.fbPlannerId == config.plannerId)[0];
  let googlePlannerCalendarsList = userCalendars.googleCalendarsList.map((calendar: any, index: number) => {
    const selected = currentPlanner?.plannerCalendars?.filter((plannerCalendar: any) => {
      if (plannerCalendar.url == calendar.url) return true;
      return false;
    });
    return {
      ...calendar,
      selected: selected && selected[0] ? true : false,
      type: 'google'
    }
  })
  let outlookPlannerCalendarsList = userCalendars.outlookCalendarsList.map((calendar: any, index: number) => {
    const selected = currentPlanner?.plannerCalendars?.filter((plannerCalendar: any) => {
      if (plannerCalendar.url == calendar.url) return true;
      return false;
    });
    return {
      ...calendar,
      selected: selected && selected[0] ? true : false,
      type: 'outlook'
    }
  })
  let applePlannerCalendarsList = userCalendars.appleCalendarsList.map((calendar: any, index: number) => {
    const selected = currentPlanner?.plannerCalendars?.filter((plannerCalendar: any) => {
      if (plannerCalendar.url == calendar.url) return true;
      return false;
    });
    return {
      ...calendar,
      selected: selected && selected[0] ? true : false,
      type: 'apple'
    }
  })

  const updateCalendarsSelection = (e: any, url: string) => {
    if (!currentPlanner) return;
    googlePlannerCalendarsList.forEach((calendar: any, index: number) => {
      if (calendar.url === url) {
        googlePlannerCalendarsList[index].selected = e.detail.checked;
        googlePlannerCalendarsList[index].type = 'google';
      }
    })
    outlookPlannerCalendarsList.forEach((calendar: any, index: number) => {
      if (calendar.url === url) {
        outlookPlannerCalendarsList[index].selected = e.detail.checked;
        outlookPlannerCalendarsList[index].type = 'outlook';
      }
    })
    applePlannerCalendarsList.forEach((calendar: any, index: number) => {
      if (calendar.url === url) {
        applePlannerCalendarsList[index].selected = e.detail.checked;
        applePlannerCalendarsList[index].type = 'apple';
      }
    })
    const allCalendars = [...googlePlannerCalendarsList, ...outlookPlannerCalendarsList, ...applePlannerCalendarsList];
    saveSelectedCalendars(allCalendars, config.plannerId)
  }

  return (
    <>
      {currentPlanner &&
        <IonList>
          <IonItemDivider>Google Calendars:</IonItemDivider>
          {googlePlannerCalendarsList.length
            ? googlePlannerCalendarsList.map((calendar: any) => {
              return (
                <IonItem key={calendar.url}>
                  <IonCheckbox
                    slot="start"
                    style={{ backgroundColor: calendar.color }}
                    color="primary"
                    checked={calendar.selected}
                    onIonChange={e => { calPageActive && updateCalendarsSelection(e, calendar.url) }}
                  />
                  {calendar.name}
                </IonItem>
              )
            })
            : <IonItem lines="none">
              <IonButton className="ion-margin-top" size="default" onClick={() => history.push(`${process.env.PUBLIC_URL}/profile/calendars`)}>Connect Google Account</IonButton>
            </IonItem>
          }
          <IonItemDivider>Outlook Calendars:</IonItemDivider>
          {outlookPlannerCalendarsList.length
            ? outlookPlannerCalendarsList.map((calendar: any) => {
              return (
                <IonItem key={calendar.url}>
                  <IonCheckbox
                    slot="start"
                    style={{ backgroundColor: calendar.color }}
                    color="primary"
                    checked={calendar.selected}
                    onIonChange={e => { calPageActive && updateCalendarsSelection(e, calendar.url) }}
                  />
                  {calendar.name}
                </IonItem>
              )
            })
            : <IonItem lines="none">
              <IonButton className="ion-margin-top" size="default" onClick={() => history.push(`${process.env.PUBLIC_URL}/profile/calendars`)}>Connect Microsoft Account</IonButton>
            </IonItem>
          }
          <IonItemDivider className="ion-margin-top">Apple Calendars:</IonItemDivider>
          {applePlannerCalendarsList.length
            ? applePlannerCalendarsList.map((calendar: any) => {
              return (
                <IonItem key={calendar.url}>
                  <IonCheckbox
                    slot="start"
                    style={{ backgroundColor: calendar.color }}
                    color="primary"
                    checked={calendar.selected}
                    onIonChange={e => { calPageActive && updateCalendarsSelection(e, calendar.url) }}
                  />
                  {calendar.name}
                </IonItem>
              )
            })
            : <IonItem lines="none">
              <IonButton className="ion-margin-top" size="default" onClick={() => history.push(`${process.env.PUBLIC_URL}/profile/calendars`)}>Connect Apple Account</IonButton>
            </IonItem>
          }
        </IonList>
      }
    </>
  )
}

export default ChooseCalendarsModal;