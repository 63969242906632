import React, { useEffect, useRef, useState } from 'react';
import * as calendarActions from '../../../../store/actions/userCalendars';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { addOutline, calendar, checkboxOutline, saveOutline, trashBinOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import '../PlannerEvents.css';
import moment from 'moment';
type Props = {
  startDate: string;
  hideModal: Function;
}

const AddEventModal: React.FC<Props> = ({startDate, hideModal }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);
  const appleId = useSelector((state: any) => state.auth.appleCredentials ? state.auth.appleCredentials.appleId : null);
  const password = useSelector((state: any) => state.auth.appleCredentials ? state.auth.appleCredentials.password : null);
  const plannerCalendars = useSelector((state: any) => state.userData.activePlanner.plannerCalendars);
  const [showModal, setShowModal] = useState(true);
  const newEvent = useRef<any>({summary: 'Event Name'});

  const saveEvent = () => {
    if (newEvent.current.calendarType == 'google') {
      if (!auth.gapiAuthLoading && auth.googleAccessToken) {
        // console.log(newEvent.current)
        const config = {
          type: 'google',
          token: auth.googleAccessToken
        }
        dispatch(calendarActions.update_planner_event(newEvent.current, config));
        hideModal();
      }
    } else if (newEvent.current.calendarType == 'outlook') {
      if (!auth.msalAuthLoading && auth.microsoftAccessToken) {
        // console.log(newEvent.current)
        const config = {
          type: newEvent.current.calendarType,
          token: auth.microsoftAccessToken
        }
        dispatch(calendarActions.update_planner_event(newEvent.current, config));
        hideModal();
      }
    } else if (newEvent.current.calendarType == 'apple') {
      if (appleId && password) {
        const config = {
          type: 'apple',
          appleId,
          applePass: password
        }
        dispatch(calendarActions.update_planner_event(newEvent.current, config));
        hideModal();
      }
    }
  }

  if(!newEvent.current.calendar){
    newEvent.current.calendar = plannerCalendars[0].url;
    newEvent.current.calendarType = plannerCalendars[0].type;
    newEvent.current.timezone = plannerCalendars[0].timezone;
    newEvent.current.color = plannerCalendars[0].color;
  }

  if(!newEvent.current.start){
    newEvent.current.start = moment(startDate).toISOString();
  }
  if(!newEvent.current.end){
    newEvent.current.end = moment(startDate).add('hours', 1).toISOString();
  }

  const setCalendar = (url: string) => {
    newEvent.current.calendar = url;
    newEvent.current.calendarType = plannerCalendars.filter((cal: any) => cal.url === url)[0].type;
    newEvent.current.timezone = plannerCalendars.filter((cal: any) => cal.url === url)[0].timezone;
    newEvent.current.color = plannerCalendars.filter((cal: any) => cal.url === url)[0].color;
  }

  return (
    <>
    {showModal && (
        <IonModal isOpen={showModal} cssClass="add-planner-modal" onWillDismiss={() => { hideModal() }}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                <IonInput value={newEvent.current.summary || 'Event Name'} onIonChange={e => newEvent.current.summary = e.detail.value} />
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => hideModal()}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <IonItem key="eventCalendar">
              <IonLabel>Calendar:</IonLabel>
              <IonSelect  interface="popover" value={newEvent.current.calendar} onIonChange={e => setCalendar(e.detail.value)}>
                {plannerCalendars.map((calendar: any, index: number) => {
                  return (
                    <IonSelectOption value={calendar.url} key={calendar.url}>
                      {calendar.name} ({calendar.type.charAt(0).toUpperCase()}{calendar.type.slice(1)})
                    </IonSelectOption>
                  )
                })}
              </IonSelect>
            </IonItem>
            <IonItem key="eventStart">
              <IonLabel>From:</IonLabel>
              <IonDatetime
                displayFormat="MM-DD-YYYY HH:mm"
                value={newEvent.current.start ? moment(newEvent.current.start).toISOString() : moment(startDate).toISOString()}
                onIonChange={e => newEvent.current.start = moment(e.detail.value).format('YYYYMMDDTHHmmss')}
              />
            </IonItem>
            <IonItem key="eventEnd">
              <IonLabel>To:</IonLabel>
              <IonDatetime
                displayFormat="MM-DD-YYYY HH:mm"
                value={newEvent.current.end ? moment(newEvent.current.end).toISOString() : moment(startDate).add('hours', 1).toISOString()}
                onIonChange={e => newEvent.current.end = moment(e.detail.value).format('YYYYMMDDTHHmmss')}
              />
            </IonItem>

            <IonRow>
              <IonCol size="12">
                <IonButton className="ion-margin-top" expand="block" onClick={() => saveEvent()}>
                  <IonIcon icon={checkboxOutline} slot="start"></IonIcon> Add new event
                </IonButton>
              </IonCol>
            </IonRow>

          </IonContent>
        </IonModal>
        )}
    </>
  )
}

export default AddEventModal;