import React, { useEffect, useRef, useState } from 'react';
import * as calendarActions from '../../../../store/actions/userCalendars';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { addOutline, calendar, checkboxOutline, saveOutline, trashBinOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import '../PlannerEvents.css';
import moment from 'moment';
type Props = {
  uid: string;
  hideModal: Function;
}

const EventModal: React.FC<Props> = ({ uid, hideModal }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);
  const { appleId, password } = useSelector((state: any) => state.auth.appleCredentials ? state.auth.appleCredentials : {});
  //const appleId = useSelector((state: any) => state.auth.appleCredentials ? state.auth.appleCredentials.appleId : null);
  //const password = useSelector((state: any) => state.auth.appleCredentials ? state.auth.appleCredentials.password : null);
  const curEvent = useSelector((state: any) => state.userCalendars.plannerEvents.filter((event: any) => event.uid == uid)[0]);
  const [showModal, setShowModal] = useState(true);

  const saveEvent = () => {
    if (curEvent.calendarType == 'google') {
      if (!auth.gapiAuthLoading && auth.googleAccessToken) {
        const config = {
          type: 'google',
          token: auth.googleAccessToken
        }
        dispatch(calendarActions.update_planner_event(curEvent, config));
        hideModal();
      }
    } else if (curEvent.calendarType == 'outlook') {
      if (!auth.msalAuthLoading && auth.microsoftAccessToken) {
        const config = {
          type: curEvent.calendarType,
          token: auth.microsoftAccessToken
        }

        dispatch(calendarActions.update_planner_event(curEvent, config));
        hideModal();
      }
    } else if (curEvent.calendarType == 'apple') {
      if (appleId && password) {
        const config = {
          type: 'apple',
          appleId,
          applePass: password
        }
        dispatch(calendarActions.update_planner_event(curEvent, config));
        hideModal();
      }
    }
  }
  const deleteEvent = () => {
    // console.log(curEvent)
    if (curEvent.calendarType == 'google') {
      if (!auth.gapiAuthLoading && auth.googleAccessToken) {
        const config = {
          type: 'google',
          token: auth.googleAccessToken,
          uid: curEvent.uid,
          calendar: curEvent.calendar
        }
        dispatch(calendarActions.delete_planner_event(config));
        hideModal();
      }
    } else if (curEvent.calendarType == 'outlook') {
      if (!auth.msalAuthLoading && auth.microsoftAccessToken) {
        const config = {
          type: curEvent.calendarType,
          token: auth.microsoftAccessToken,
          uid: curEvent.uid,
          calendar: curEvent.calendar
        }
        dispatch(calendarActions.delete_planner_event(config));
        hideModal();
      }
    } else if (curEvent.calendarType == 'apple') {
      if (appleId && password) {
        const config = {
          type: 'apple',
          appleId,
          applePass: password,
          uid: curEvent.uid,
          calendar: curEvent.calendar
        }
        dispatch(calendarActions.delete_planner_event(config));
        hideModal();
      }
    }
  }

  return (
    <>
      {curEvent &&
        <IonModal isOpen={showModal} cssClass="add-planner-modal" onWillDismiss={() => { hideModal() }}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                <IonInput value={curEvent.summary} onIonChange={e => curEvent.summary = e.detail.value} />
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => hideModal()}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <IonItem key="eventCalendar">
              <IonLabel>Calendar:</IonLabel>
              <IonText>
                <span className="eventModalCalendarColor" style={{backgroundColor: curEvent.color}}></span>
                {curEvent.calendarName} ({curEvent.calendarType.charAt(0).toUpperCase()}{curEvent.calendarType.slice(1)})
              </IonText>
            </IonItem>
            <IonItem key="eventStart">
              <IonLabel>From:</IonLabel>
              <IonDatetime
                displayFormat="MM-DD-YYYY HH:mm"
                value={moment(curEvent.start).toISOString()}
                onIonChange={e => curEvent.start = moment(e.detail.value).format('YYYYMMDDTHHmmss')}
              />
            </IonItem>
            <IonItem key="eventEnd">
              <IonLabel>To:</IonLabel>
              <IonDatetime
                displayFormat="MM-DD-YYYY HH:mm"
                value={moment(curEvent.end).toISOString()}
                onIonChange={e => curEvent.end = moment(e.detail.value).format('YYYYMMDDTHHmmss')}
              />
            </IonItem>

            <IonRow>
              <IonCol size="6">
                <IonButton className="ion-margin-top" expand="block" onClick={() => deleteEvent()} fill="outline" color="danger">
                  <IonIcon icon={trashBinOutline} slot="start"></IonIcon> Delete
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton className="ion-margin-top" expand="block" onClick={() => saveEvent()}>
                  <IonIcon icon={checkboxOutline} slot="start"></IonIcon> Save
                </IonButton>
              </IonCol>
            </IonRow>

          </IonContent>
        </IonModal>
      }
    </>
  )
}

export default EventModal;