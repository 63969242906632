import firebase from "firebase/app";
import 'firebase/analytics'

import "firebase/auth";

// OLD 'MAKE PLANNER' Firebase app
// let firebaseConfig = {
//     apiKey: "AIzaSyBSUpWV3_MXns7Fu8OVVsoTu_serdzDID8",
//     authDomain: "makeplanner-5476c.firebaseapp.com",
//     projectId: "makeplanner-5476c",
//     storageBucket: "makeplanner-5476c.appspot.com",
//     messagingSenderId: "1049802044456",
//     appId: "1:1049802044456:web:98f263b9506c1ee164311d"
//   };

// NEW 'Digital Planner' Firebase app
let firebaseConfig = {
  apiKey: "AIzaSyCPYNgGhoR9uMmhubCWMOfs7jZkiK6mok4",
  authDomain: "digital-planner-321711.firebaseapp.com",
  projectId: "digital-planner-321711",
  storageBucket: "digital-planner-321711.appspot.com",
  messagingSenderId: "648240162949",
  appId: "1:648240162949:web:8535bd7cf71381597ba1ec",
  measurementId: 'G-RTVSZG7JBC'
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;