import firebase from "../../services/firebase";
import * as plannerControls from './plannerControls'
import { parse } from 'node-html-parser';
import axios from 'axios';
import moment from 'moment';
import { convertStylesStringToObject } from '../../services/htmlStylesParser';

declare global {
  interface Window {
    builderApi: any;
  }
}

export const SET_LOADING = 'SET_LOADING';
export const CLEAN_UP_ACTIVE_PLANNER = 'CLEAN_UP_ACTIVE_PLANNER';
export const CLEANUP_ALL_DATA = 'CLEANUP_ALL_DATA';
export const SET_ACTIVE_PLANNER_LOADING = 'SET_ACTIVE_PLANNER_LOADING';
export const SET_STATIC_PAGES_LOADING = 'SET_STATIC_PAGES_LOADING';
export const SET_ACTIVE_PLANNER = 'SET_ACTIVE_PLANNER';
export const SAVE_NEW_PLANNER = 'SAVE_NEW_PLANNER';
export const LOAD_USER_PLANNERS = 'LOAD_USER_PLANNERS';
export const DELETE_USER_PLANNER = 'DELETE_USER_PLANNER';
export const UPDATE_PLANNER_CALENDARS = 'UPDATE_PLANNER_CALENDARS';
export const UPDATE_PLANNER_SECTIONS = 'UPDATE_PLANNER_SECTIONS';
export const SET_PAGER_STEP = 'SET_PAGER_STEP';
export const SET_PLANNER_NAME = 'SET_PLANNER_NAME';

const setLoading = (loading: any) => ({
  type: SET_LOADING,
  loading
});
const setActivePlannerLoading = (loading: any) => ({
  type: SET_ACTIVE_PLANNER_LOADING,
  loading
});
const setStaticPagesLoading = (loading: any) => ({
  type: SET_STATIC_PAGES_LOADING,
  loading
});
const setActivePlanner = (activePlanner: any) => ({
  type: SET_ACTIVE_PLANNER,
  activePlanner
});
const setStaticPages = (activePlanner: any) => ({
  type: SET_ACTIVE_PLANNER,
  activePlanner
});
const saveNewPlanner = (newPlanner: object) => ({
  type: SAVE_NEW_PLANNER,
  newPlanner
});
const loadUserPlanners = (userPlanners: Array<[]>) => ({
  type: LOAD_USER_PLANNERS,
  userPlanners
});
const deleteUserPlanner = (plannerId: string) => ({
  type: DELETE_USER_PLANNER,
  plannerId
});
const updatePlannerCalendars = (plannerId: string, plannerCalendars: any) => ({
  type: UPDATE_PLANNER_CALENDARS,
  plannerId,
  plannerCalendars
});
const updatePlannerSections = (pageId: string, newState: boolean) => ({
  type: UPDATE_PLANNER_SECTIONS,
  pageId,
  newState
})

export const cleanup_all_data = () => {
  return((dispatch: any) => {
    dispatch({type: CLEANUP_ALL_DATA})
  })
}

export const add_new_planner = (plannerConfig: any) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const newPlanner = {
        originalPlannerId: plannerConfig.plannerId,
        plannerName: plannerConfig.plannerName,
        builderId: plannerConfig.builderId,
        plannerYear: plannerConfig.plannerYear,
        weekStart: plannerConfig.selectedWeekStart,
        plannerCover: plannerConfig.plannerCover,
        sectionsExcluded: []
      }
      firebase.firestore().collection('userData').doc(plannerConfig.uid).collection('planners').add(newPlanner)
        .then((ref: any) => {
          dispatch(saveNewPlanner({ ...newPlanner, fbPlannerId: ref.id }))
          dispatch(setLoading(false));
        })
        .catch(e => console.log(e))
    } catch (e) { console.log(e) }
  }
}

export const load_user_planners = (uid: string) => {
  return async (dispatch: any) => {
    if(!uid) return;
    dispatch(setLoading(true));
    let planners: Array<[]> = [];
    let covers: Array<[]> = [];
    firebase.firestore().collection('coversLibrary').get()
      .then((data: any) => {
        data.forEach((cover: any) => {
          covers.push({ ...cover.data(), fbCoverId: cover.id });
        });
      })

    firebase.firestore().collection('userData').doc(uid).collection('planners').get().then((data: any) => {
      data.forEach((planner: any) => {
        const plannerData = planner.data();
        let cover = covers.filter((cover: any) => cover.fbCoverId == plannerData.plannerCover);
        planners.push({ ...plannerData, fbPlannerId: planner.id });
      });
    })
      .then(() => {
        dispatch(loadUserPlanners(planners))
        dispatch(setLoading(false));
      })
      .catch(e => {dispatch(setLoading(false)); console.log(e)});
  }
}

export const archive_user_planner = (uid: string, plannerId: string) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    firebase.firestore().collection('userData').doc(uid).collection('planners').doc(plannerId)
      .set({ archived: true }, { merge: true })
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(e => { dispatch(setLoading(true)); console.log(e) });
  }
}
export const activate_user_planner = (uid: string, plannerId: string) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    firebase.firestore().collection('userData').doc(uid).collection('planners').doc(plannerId)
      .set({ archived: false }, { merge: true })
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(e => { dispatch(setLoading(true)); console.log(e) });
  }
}

export const delete_old_cover = (filename: string) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios.get(`https://digital-planner.com/planner/api/cover/remove?filename=${filename}`)
      .catch(() => console.log(`can\`t remove old cover ${filename}`));
  }
}

export const update_planner_sections = (uid: string, permissions: any, plannerId: string, pageId: string, newState: boolean, plannerConfig: any) => {
  return async (dispatch: any) => {
    const planner = firebase.firestore().collection('userData').doc(uid).collection('planners').doc(plannerId);
    planner.get().then((res: any) => {
      const sectionsIncluded = [...res.data().sectionsIncluded];
      if (newState === false) {
        sectionsIncluded.indexOf(pageId) < 0 && sectionsIncluded.push(pageId);
      } else {
        sectionsIncluded.splice(sectionsIncluded.indexOf(pageId), 1);
      }
      if (!permissions.includes('writeDb')) return;
      dispatch(updatePlannerSections(pageId, newState));
      dispatch(load_planner_source(uid, plannerConfig));
      planner.set({ sectionsIncluded: sectionsIncluded }, { merge: true })
    })
  }
}

export const update_planner_sections_mass = (uid: string, permissions: any, plannerId: string, updatedPages: any, plannerConfig: any) => {
  return async (dispatch: any) => {
    console.log(plannerId, uid)
    const planner = firebase.firestore().collection('userData').doc(uid).collection('planners').doc(plannerId);
    planner.get().then((res: any) => {
      const sectionsIncluded = [...res.data().sectionsIncluded];
      Object.entries(updatedPages).forEach((page: any) => {
        console.log(page[0], page[1]);
        if (page[1] === false) {
          sectionsIncluded.indexOf(page[0]) < 0 && sectionsIncluded.push(page[0]);
        } else {
          sectionsIncluded.splice(sectionsIncluded.indexOf(page[0]), 1);
        }
      })
      console.log('permissions', permissions)
      if (!permissions.includes('writeDb')) return;
      console.log(sectionsIncluded)
      // dispatch(updatePlannerSections(pageId, newState));
      // dispatch(load_planner_source(uid, plannerConfig));
      planner.set({ sectionsIncluded: sectionsIncluded }, { merge: true })
    })
  }
}

export const set_planner_name = (uid: string, plannerId: string, permissions: any, name: string) => {
  return (dispatch: any) => {
    if (!permissions.includes('writeDb')) return;
    if (!name) return;
    dispatch({ type: SET_PLANNER_NAME, name });
    const dbPlanner = firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId);
      dbPlanner.get().then(res => {
        if(res.exists) dbPlanner.set({ plannerName: name }, { merge: true });
      })
  }
}

type plannerConfig = {
  fbPlannerId: string;
  startDate: string;
  endDate: string;
  plannerYear: string;
  weekStart: string;
  builderId: number;
  plannerName: string;
  plannerCover: string;
  hash?: string;
  plannerCalendars: any;
}
const REPEAT_NONE = 0;
const REPEAT_PREV = 1;
const REPEAT_WEEK_START = 2;
const REPEAT_MONTH_START = 3;
const REPEAT_WEEK_END = 4;
const REPEAT_MONTH_END = 5;

export const load_planner_static_pages = (uid: string, plannerConfig: plannerConfig) => {
  // console.log(uid)
  return async (dispatch: any) => {
    dispatch(setStaticPagesLoading(true));
    const builderApi = window.builderApi;
    const sectionsIncluded: any = [];
    const plannerYear = plannerConfig.plannerYear || moment().format('YYYY');
    const weekStart = plannerConfig.weekStart == 'monday' ? 1 : 0;
    const params = `id=${plannerConfig.builderId}&config[year]=${plannerYear}&config[iso]=${weekStart}`;
    let plannerFont = '';
    // console.log(`https://onplanners.com/planner/api/build/static?${params}`);
    firebase.firestore().collection('userData').doc(uid).collection('planners').doc(plannerConfig.fbPlannerId).get()
      .then((doc: any) => {
        // console.log(doc)
        plannerFont = doc.data().plannerFont;
        const dbSectionsIncluded = doc.data().sectionsIncluded;

        if (dbSectionsIncluded && dbSectionsIncluded.length) {
          dbSectionsIncluded.forEach((section: any) => {
            sectionsIncluded.push(section);
          });
        }

        const sectionIncluded = (sectionId: string | number) => {
          let exclude = true;
          if (sectionsIncluded.length) {
            sectionsIncluded.forEach((curSection: any) => {
              if (exclude && curSection == sectionId)
                exclude = false;
            });
          } else {
            exclude = false;
          }
          return exclude;
        }

        axios.get(`https://digital-planner.com/planner/api/build/static?${params}`)
          .then(async (plannerData) => {
            if (plannerData.status !== 200) return;
            let staticPages: Array<any> = [];

            plannerData.data.pages.forEach((page: any, index: number) => {
              const pageDisabled = sectionIncluded(page.pageId);
              staticPages.push({
                pageId: page.pageId,
                pageName: page.pageName,
                pageDisabled: pageDisabled,
                pageSource: page.page,
                pageSource2: page.page2
              })
            })

            dispatch(setActivePlanner({
              staticPages: staticPages,
              plannerFont,
              ...plannerConfig,
              plannerCalendars: plannerConfig.plannerCalendars ? [...plannerConfig.plannerCalendars] : [],
            }));
            dispatch(setStaticPagesLoading(false));
          })
      })


  }
}

export const load_planner_source = (uid: string, plannerConfig: plannerConfig) => {
  return async (dispatch: any) => {
    // console.log('planner source loading');
    dispatch(setActivePlannerLoading(true));
    const builderApi = window.builderApi;
    //config[iso]=0 - Sunday; config[iso]=1 - Monday
    const sectionsIncluded: any = [];
    const plannerYear = plannerConfig.plannerYear || moment().format('YYYY');
    const weekStart = plannerConfig.weekStart == 'monday' ? 1 : 0;
    const params = `id=${plannerConfig.builderId}&config[year]=${plannerYear}&config[iso]=${weekStart}`;
    let plannerFont = '';
    // console.log(`https://onplanners.com/planner/api/build/static?${params}`);
    // console.log('uid:', uid, 'planner:', plannerConfig.fbPlannerId)
    firebase.firestore().collection('userData').doc(uid).collection('planners').doc(plannerConfig.fbPlannerId).get()
      .then((doc: any) => {
        if (doc.exists && doc.data().sectionsIncluded && doc.data().sectionsIncluded.length) {
          plannerFont = doc.data().plannerFont;
          doc.data().sectionsIncluded.forEach((section: any) => {
            sectionsIncluded.push(section);
          });
        }

        const sectionIncluded = (sectionId: string | number) => {
          let exclude = true;
          if (sectionsIncluded.length) {
            sectionsIncluded.forEach((curSection: any) => {
              if (exclude && curSection == sectionId)
                exclude = false;
            });
          } else {
            exclude = false;
          }
          return exclude;
        }

        axios.get(`https://digital-planner.com/planner/api/build/static?${params}`)
          .then(async (plannerData) => {
            if (plannerData.status !== 200) return;

            const isResponsive = plannerData.data.is_responsive;
            const plannerClass = plannerData.data.class;

            const plannerSize: any = {
              width: parseInt(plannerData.data.page_size.width),
              height: parseInt(plannerData.data.page_size.height),
            };
            const yearStartDate = moment(`${plannerYear}-01-01`);
            const stateCounts = {};

            let allPages: Array<any> = [];
            let staticPages: Array<any> = [];
            // console.log(plannerData.data.pages)
            plannerData.data.pages.forEach((page: any, index: number) => {
              const pageDisabled = sectionIncluded(page.pageId);
              staticPages.push({
                pageId: page.pageId,
                pageName: page.pageName,
                pageDisabled: pageDisabled,
                pageSource: page.page,
                pageSource2: page.page2
              })
              // console.log(pageDisabled)
              // if (page.repeat != REPEAT_NONE || pageDisabled) return;
              if (pageDisabled) return;


              const pagesArr: Array<any> = [];

              let pageTypeCounter = 0;
              for (let i = 0; i < page.pageCount; i++) {
                const pageDate = builderApi.processPageDate(page.dateStep, page.blockCount, yearStartDate, i, weekStart);
                let newIds = page.ids.map((id: any) => builderApi.processLazyToken(id, i, pageDate, stateCounts, weekStart));
                pagesArr.push({
                  pageId: page.pageId,
                  pageTypeCount: pageTypeCounter,
                  ids: newIds,
                  date: pageDate,
                  page: 'pageSource',
                })
                if (page.page2) {
                  pageTypeCounter++;
                  pagesArr.push({
                    pageId: page.pageId,
                    pageTypeCount: pageTypeCounter,
                    ids: newIds,
                    date: pageDate,
                    page: 'pageSource2',
                  })
                }
                pageTypeCounter++;
              }

              // let nextPage = plannerData.data.pages[index + 1];
              // if (nextPage && nextPage.repeat == REPEAT_PREV) {
              //   let newPagesArr: Array<any> = [];
              //   let newPageTypeCount = 0;
              //   let globalIndex = 0;
              //   pagesArr.forEach((page, pageIndex) => {
              //     const tempIndex = pageIndex;
              //     if (tempIndex % 2) {
              //       globalIndex += 1;
              //       return;
              //     }
              //     newPagesArr.push(page);
              //     newPagesArr.push(pagesArr[pageIndex + 1]);
              //     const pageDate = builderApi.processPageDate(nextPage.dateStep, nextPage.blockCount, yearStartDate, globalIndex, weekStart);
              //     let newIds = nextPage.ids.map((id: any) => builderApi.processLazyToken(id, index + 1, pageDate, stateCounts, weekStart));
              //     newPagesArr.push({
              //       pageId: nextPage.pageId,
              //       pageTypeCount: newPageTypeCount,
              //       ids: newIds,
              //       date: pageDate,
              //       page: 'pageSource'
              //     });
              //     newPageTypeCount++;
              //     newPagesArr.push({
              //       pageId: nextPage.pageId,
              //       pageTypeCount: newPageTypeCount,
              //       ids: newIds,
              //       date: pageDate,
              //       page: 'pageSource2'
              //     });
              //     newPageTypeCount++;
              //   })
              //   allPages = [...allPages, ...newPagesArr];
              // } else {
                allPages = [...allPages, ...pagesArr];
              // }
            })

            if (!sectionsIncluded.length) {
              const pagesIncluded = staticPages.map((page: any) => page.pageId);
              // TO FIX: creates empty planners!!!!!
              const plannerRef = firebase.firestore().collection('userData').doc(uid).collection('planners').doc(plannerConfig.fbPlannerId);
              plannerRef.get().then((dbplanner) => {
                if(dbplanner.exists){
                  plannerRef.set({ sectionsIncluded: pagesIncluded }, { merge: true });
                }
              })
            }

            dispatch(setActivePlanner({
              header: plannerData.data.header,
              staticPages: staticPages,
              pages: allPages,
              ...plannerConfig,
              plannerSize,
              isResponsive,
              plannerClass,
              plannerCalendars: plannerConfig.plannerCalendars ? [...plannerConfig.plannerCalendars] : [],
              plannerFont,
              plannerConfig: { ...plannerConfig },
            }));

            if (plannerConfig.hash) {
              const hash = plannerConfig.hash?.replace('#', '');
              const newPageIndex = allPages.findIndex((element: any) => {
                if (element.ids.indexOf(hash) > -1) { return true; }
                return false;
              })
              if (newPageIndex > -1) dispatch(plannerControls.set_cur_page(newPageIndex));
            }
          })
          .then(() => {
            dispatch(setActivePlannerLoading(false));
          })
          .catch(e => {
            console.log(e);
            dispatch(setActivePlannerLoading(false));
          })
      })
  }
}

export const cleanup_active_planner = () => {
  return (dispatch: any) => {
    dispatch({ type: CLEAN_UP_ACTIVE_PLANNER });
  }
}

export const update_planner_cover = (uid: string, permissions: any, plannerId: any, coverId: string) => {
  return (dispatch: any) => {
    if (!plannerId) return false;
    if (!permissions.includes('writeDb')) return;
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .set({ plannerCover: coverId }, { merge: true })
      .catch(e => console.log(e));
  }
}

export const set_custom_planner_cover = (uid: string, permissions: any, plannerId: any, cover: any) => {
  return (dispatch: any) => {
    if (!plannerId) return false;
    if (!permissions.includes('writeDb')) return;
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .set({ plannerCover: cover }, { merge: true })
      .catch(e => console.log(e));
  }
}

export const update_planner_calendars = (uid: string, permissions: any, plannerId: any, calendars: any) => {
  return (dispatch: any) => {
    if (!plannerId || !calendars) return false;
    dispatch(updatePlannerCalendars(plannerId, calendars))
    if (!permissions.includes('writeDb')) return;
    firebase.firestore()
      .collection('userData')
      .doc(uid)
      .collection('planners')
      .doc(plannerId)
      .set({ plannerCalendars: calendars }, { merge: true })
      .catch(e => console.log(e));
  }
}

export const set_pager_step = (step: number) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_PAGER_STEP,
      step
    })
  }
}