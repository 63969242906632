import * as actionTypes from '../actions/library';

const initialState = {
    loading: false,
    stickersLoading: false,
    library: [],
    coversLibrary: [],
    stickersNav: [],
    stickersTid: 0
}

export const libraryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case actionTypes.SET_STICKERS_LOADING:
            return {
                ...state,
                stickersLoading: action.loading
            }
        case actionTypes.LOAD_LIBRARY:
            return {
                ...state,
                library: action.library
            }
        case actionTypes.LOAD_COVERS_LIBRARY:
            return {
                ...state,
                coversLibrary: action.library
            }
        case actionTypes.LOAD_STICKERS_LIBRARY:
            const tidType = action.parent ? 'stickersChildTid' : 'stickersTid';
            return {
                ...state,
                stickersLibrary: action.library,
                [tidType]: action.tid,
            }
        case actionTypes.SET_STICKERS_NAV:
            return {
                ...state,
                stickersNav: action.cats
            }
        default:
            return {
                ...state
            }
    }

}