import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, LIFECYCLE, STATUS } from 'react-joyride';
import { menuController } from "@ionic/core";
import { getGuideSteps, saveGuidesState } from './plannerGuidesSteps';
import { useSelector } from 'react-redux';

const guideStyles = {
  options: {
    overlayColor: 'rgba(0, 0, 0, 0.2)',
    primaryColor: 'var(--ion-color-primary)',
  }
};

const handleJoyrideCallback = (data: any, uid: string, guideType: string, setStep: any, run: any, steps: any) => {
  const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
      if (action !== ACTIONS.PREV) saveGuidesState(uid, guideType, data.step.target);
      if (data.step.target == '.headerPager') {
        menuController.toggle('plannerMenu');
        setTimeout(() => {
          setStep(nextStep);
        }, 300);
      } else if (data.step.target == '.plannerSettingsMenuBttn') {
        menuController.toggle('plannerMenu');
        setStep(nextStep);
      } else {
        setStep(nextStep);
      }
    } else if ([STATUS.FINISHED].includes(status)) {
      saveGuidesState(uid, guideType, data.step.target);
      run(false);
    } else if ([STATUS.SKIPPED].includes(status)) {
      saveGuidesState(uid, guideType, steps);
      run(false);
    }
};

export const HomeGuides: React.FC<any> = () => {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const [userGuide, setUserGuide] = useState<any>([]);
  const [userGuideStep, setUserGuideStep] = useState<any>(0);
  const [userGuideRun, setUserGuideRun] = useState(false);

  useEffect(() => {
    getGuideSteps(uid, 'home').then(res => setUserGuide(res));
  }, []);

  useEffect(() => {
    if (userGuide.length) {
      setTimeout(() => {
        setUserGuideRun(true);
      }, 5000);
    }
  }, [userGuide])

  return (<>
    {uid && (
      <Joyride
        showProgress={true}
        scrollToFirstStep={false}
        showSkipButton={true}
        steps={userGuide}
        continuous={true}
        stepIndex={userGuideStep}
        run={userGuideRun}
        styles={guideStyles}
        locale={{last: 'Finish'}}
        callback={(data: any) => handleJoyrideCallback(data, uid, 'home', (step: number) => setUserGuideStep(step), (state: boolean) => setUserGuideRun(state), userGuide)}
      />
    )}
  </>)
}

export const AddPlannerGuides: React.FC<any> = () => {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const [userGuide, setUserGuide] = useState<any>([]);
  const [userGuideStep, setUserGuideStep] = useState<any>(0);
  const [userGuideRun, setUserGuideRun] = useState(false);

  useEffect(() => {
    getGuideSteps(uid, 'addPlanner').then(res => setUserGuide(res));
  }, []);

  useEffect(() => {
    if (userGuide.length) {
      setTimeout(() => {
        setUserGuideRun(true);
      }, 2000);
    }
  }, [userGuide])

  return (<>
    {uid && (
      <Joyride
        showProgress={true}
        scrollToFirstStep={false}
        showSkipButton={true}
        steps={userGuide}
        continuous={true}
        stepIndex={userGuideStep}
        run={userGuideRun}
        styles={guideStyles}
        locale={{last: 'Finish'}}
        callback={(data: any) => handleJoyrideCallback(data, uid, 'addPlanner', (step: number) => setUserGuideStep(step), (state: boolean) => setUserGuideRun(state), userGuide)}
      />
    )}
  </>)
}

export const PlannerSettingsGuides: React.FC<any> = () => {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const [userGuide, setUserGuide] = useState<any>([]);
  const [userGuideStep, setUserGuideStep] = useState<any>(0);
  const [userGuideRun, setUserGuideRun] = useState(false);

  useEffect(() => {
    getGuideSteps(uid, 'plannerSettings').then(res => setUserGuide(res));
  }, []);

  useEffect(() => {
    if (userGuide.length) {
      setTimeout(() => {
        setUserGuideRun(true);
      }, 2000);
    }
  }, [userGuide])

  return (<>
    {uid && (
      <Joyride
        showProgress={true}
        scrollToFirstStep={false}
        showSkipButton={true}
        steps={userGuide}
        continuous={true}
        stepIndex={userGuideStep}
        run={userGuideRun}
        styles={guideStyles}
        locale={{last: 'Finish'}}
        callback={(data: any) => handleJoyrideCallback(data, uid, 'plannerSettings', (step: number) => setUserGuideStep(step), (state: boolean) => setUserGuideRun(state), userGuide)}
      />
    )}
  </>)
}


export const PlannerGuides: React.FC<any> = () => {
  const uid = useSelector((state: any) => state.firebase.auth.uid);
  const [userGuide, setUserGuide] = useState<any>([])
  const [userGuideStep, setUserGuideStep] = useState<any>(0);
  const [userGuideRun, setUserGuideRun] = useState(false);

  useEffect(() => {
    getGuideSteps(uid, 'planner').then(res => setUserGuide(res));
  }, []);

  useEffect(() => {
    if (userGuide.length) {
      setTimeout(() => {
        setUserGuideRun(true);
      }, 5000);
    }
  }, [userGuide])

  return (<>
    {uid && (
      <Joyride
        showProgress={true}
        scrollToFirstStep={false}
        showSkipButton={true}
        steps={userGuide}
        continuous={true}
        stepIndex={userGuideStep}
        run={userGuideRun}
        styles={guideStyles}
        locale={{last: 'Finish'}}
        callback={(data: any) => handleJoyrideCallback(data, uid, 'planner', (step: number) => setUserGuideStep(step), (state: boolean) => setUserGuideRun(state), userGuide)}
      />
    )}
  </>)
}