import React, { useState } from 'react';
import * as authActions from '../../../store/actions/auth';
import * as plannerControls from '../../../store/actions/plannerControls';
import * as userDataActions from '../../../store/actions/userData';
import * as calendarsActions from '../../../store/actions/userCalendars';
import { useSelector, useDispatch } from 'react-redux';
import { IonButton, IonButtons, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonListHeader, IonMenuToggle, IonPopover, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { exitOutline, menuOutline, personCircleOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';

interface HeaderProps {
  title: string;
}


const MainHeader: React.FC<HeaderProps> = ({ title }) => {
  const auth = useSelector((state: any) => state.firebase.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const uid = useSelector((state: any) => state.auth.uid);
  const fbuid = useSelector((state: any) => state.firebase.auth.uid);
  const [showUserMenu, setShowUserMenu] = useState({ showPopover: false, event: undefined });
  
  const logout = () => {
    dispatch(authActions.user_logout());
    dispatch(plannerControls.cleanup_all_data());
    dispatch(userDataActions.cleanup_all_data());
    dispatch(calendarsActions.cleanup_all_data());
    history.push(`${process.env.PUBLIC_URL}/login`);
  }

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuToggle>
            <IonButton>
              <IonIcon slot="icon-only" icon={menuOutline}></IonIcon>
            </IonButton>
          </IonMenuToggle>
        </IonButtons>

        <IonTitle className="ion-text-center">{title}</IonTitle>

        <div slot="end">
          <IonButton shape="round" fill="clear" onClick={(e: any) => {
            e.persist();
            setShowUserMenu({ showPopover: true, event: e })
          }}>
            <IonIcon size="large" color="dark" slot="icon-only" icon={personCircleOutline} />
          </IonButton>
          <IonPopover
            showBackdrop={false}
            cssClass='my-custom-class'
            event={showUserMenu.event}
            isOpen={showUserMenu.showPopover}
            onDidDismiss={() => setShowUserMenu({ showPopover: false, event: undefined })}
          >
            <IonList>
              <IonItemGroup>
                <IonItemDivider>
                  <IonLabel>{auth.email}</IonLabel>
                </IonItemDivider>
                <IonItem detail={false} lines="none" button onClick={logout} className="ion-text-left">
                  <IonIcon color="danger" icon={exitOutline} slot="end"></IonIcon>
                  <IonLabel>Logout</IonLabel>
                </IonItem>
              </IonItemGroup>
            </IonList>
          </IonPopover>
        </div>
      </IonToolbar>
      <IonToast
        isOpen={uid && fbuid && uid !== fbuid}
        message="Viewing as CUSTOMER!"
        color="danger"
        position="bottom"
        cssClass="viewAsToast"
        buttons={[
          {
            side: 'end',
            role: '',
            text: 'Change',
            handler: () => { console.log('click'); history.push(`${process.env.PUBLIC_URL}/admin/viewasuser`) }
          }
        ]}
      />
    </IonHeader>
  )
}

export default MainHeader;