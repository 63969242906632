import firebase from "../../services/firebase";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

import { CallDavOutlook } from '../../services/caldav';
import * as calendarsActions from './userCalendars';

const msalConfig = {
  auth: {
    clientId: "44e6936b-f882-42f0-a781-1324d7e6539c",
    authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    //authority: "https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "http://localhost:8100/app/profile/calendars",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequest = {
  scopes: ["User.Read", "Calendars.ReadWrite"]
};

const msalClient = new PublicClientApplication(msalConfig);

export const MSAL_AUTH_LOADING = 'MSAL_AUTH_LOADING';
export const MSAL_AUTH = 'MSAL_AUTH';

const msalAuthLoading = (loading: boolean) => ({
  type: MSAL_AUTH_LOADING,
  msalAuthLoading: loading
});

const msalAuth = (microsoftAccessToken: string, microsoftUsed: boolean) => ({
  type: MSAL_AUTH,
  microsoftAccessToken,
  microsoftUsed
});

function initCalendars(dispatch: any, token: string) {
  dispatch(msalAuth(token, true));
  const callDavOutlook = new CallDavOutlook(token);
  callDavOutlook.listCalendars().then((calendars: any) => {
    if (calendars) {
      dispatch(calendarsActions.set_outlook_user_calendars(calendars))
    }
  })
}

export const msal_auth = () => {
  return async (dispatch: any) => {
    dispatch(msalAuthLoading(true));

    let msalAccount = msalClient.getAllAccounts()[0];
    msalClient.acquireTokenSilent({account: msalAccount, ...loginRequest}).then(tokenResponse => {
      initCalendars(dispatch, tokenResponse.accessToken);
      dispatch(msalAuthLoading(false));
    }).catch(async (error) => {
      dispatch(msalAuthLoading(false));
      if (error instanceof InteractionRequiredAuthError) {
        console.log('Interaction Required');
        // fallback to interaction when silent call fails
        //return msalInstance.acquireTokenPopup(request);
        dispatch(msal_signin());
      }
    }).catch(error => {
      dispatch(msalAuthLoading(false));
    });
  }
}

export const msal_signin = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(msalAuthLoading(true));

    msalClient.loginPopup(loginRequest).then((tokenResponse) => {
      let uid = getState().auth.uid;
      if(uid) {
        firebase.firestore().collection('userData').doc(uid)
          .collection('settings').doc('permissions').set({ microsoftUsed: true }, { merge: true });
      }

      initCalendars(dispatch, tokenResponse.accessToken);
      dispatch(msalAuthLoading(false));
    })
    .catch((e) => { dispatch(msalAuthLoading(false)); });
  }
}

export const msal_signout = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(msalAuthLoading(true));

    let uid = getState().auth.uid;
    if(uid) {
      firebase.firestore().collection('userData').doc(uid)
        .collection('settings').doc('permissions').set({ microsoftUsed: false }, { merge: true });
    }

    dispatch(calendarsActions.set_outlook_user_calendars([]))
    dispatch(msalAuth('', false));
    dispatch(msalAuthLoading(false));
  }
}
