import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import * as userDataActions from '../../../store/actions/userData';
import * as plannerActions from '../../../store/actions/plannerControls';
import { useChangePlannerYear } from '../../../services/ChangePlannerYear';
import { menuController } from "@ionic/core";
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonList,
  IonMenuToggle,
  IonIcon,
  IonLabel,
  IonItem,
  IonToggle,
  IonButton,
  IonSelect,
  IonSelectOption
} from '@ionic/react';

import { bookOutline, calendarOutline, starOutline, settingsOutline, addOutline, settings } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import './PlannerMenu.css';

const PlannerMenu: React.FC = () => {
  const [checked, setChecked] = useState<boolean>(true);
  const staticPages = useSelector((state: any) => state.userData.activePlanner.staticPages);
  const userData = useSelector((state: any) => state.userData);
  const plannerYear = useSelector((state: any) => parseInt(state.userData.activePlanner.plannerYear));
  const changePlannerYear = useChangePlannerYear();
  const uid = useSelector((state: any) => state.auth.uid);
  const plannerId = useSelector((state: any) => state.userData.activePlanner.fbPlannerId);
  const plannerConfig = useSelector((state: any) => state.userData.activePlanner.plannerConfig);
  const dispatch = useDispatch();
  const history = useHistory();

  const goToStaticPageId = (pageId: string | number) => {
    let idIndex: number = 0;
    const pageHash = history.location.hash;
    // console.log(pageHash)
    const m: any = pageHash.match(/(y\d{4})(-m\d+)?(-w\d+)?(-d\d+)?/);
    // console.log(m)
    let newPageIndex: number = -1;
    if (m && m.length) {
      newPageIndex = userData.activePlanner?.pages?.findIndex((page: any) => {
        if (
          page.pageId == pageId
          && (
            page.ids.findIndex((id: string, index: number) => {
              let newMatch: string = '';
              const m2: any = id.match(/(y\d{4})(-m\d+)?(-w\d+)?(-d\d+)?/);
              if (m2) {
                if (m2[1]) newMatch = newMatch + (m[1] ? m[1] : '');
                if (m2[2]) newMatch = newMatch + (m[2] ? m[2] : '');
                if (m2[3]) newMatch = newMatch + (m[3] ? m[3] : '');
                if (m2[4]) newMatch = newMatch + (m[4] ? m[4] : '');
                // console.log(newMatch, id, id.includes(newMatch))
                if (id.includes(newMatch)) {
                  idIndex = index;
                  return true;
                }
              }
              return false;
            }) > -1
          )
        ) { return true; }
        return false;
      })
    }
    if (newPageIndex < 0) {
      newPageIndex = userData.activePlanner?.pages?.findIndex((page: any) => {
        if (page.pageId == pageId) { return true; }
        return false;
      })
    }
    if (newPageIndex > -1) {
      const pageHash = userData.activePlanner?.pages[newPageIndex]?.ids[idIndex];
      const curLocation = history.location.pathname;
      pageHash ? history.push(curLocation + '#' + pageHash) : history.push(curLocation.split('#')[0]);
      dispatch(plannerActions.set_cur_page(newPageIndex));
      menuController.toggle('plannerMenu');
    } else { console.log(pageId + ': page not found'); }
  }

  return (
    <>
      {staticPages && (
        <IonMenu contentId="mainContent" swipeGesture={false} side="end" menuId="plannerMenu">
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Navigation</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonButton expand="block" color="primary" fill="solid" className="ion-margin plannerSettingsMenuBttn"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/planner/settings/${userData.activePlanner.fbPlannerId}`);
                menuController.toggle('plannerMenu');
              }}>
              <IonIcon slot="start" color="light" icon={settings}></IonIcon> Planner settings
            </IonButton>

            {(plannerYear > 1000) && (
              <IonButton expand="block" color="primary" fill="outline" className="ion-margin plannerYearSwitcher">
                <IonSelect style={{ padding: '0 70px' }} value={plannerYear} onIonChange={e => { menuController.close('plannerMenu'); changePlannerYear(e.detail.value) }}>
                  <IonSelectOption value={plannerYear - 2}>{plannerYear - 2}</IonSelectOption>
                  <IonSelectOption value={plannerYear - 1}>{plannerYear - 1}</IonSelectOption>
                  <IonSelectOption value={plannerYear}>{plannerYear}</IonSelectOption>
                  <IonSelectOption value={plannerYear + 1}>{plannerYear + 1}</IonSelectOption>
                  <IonSelectOption value={plannerYear + 2}>{plannerYear + 2}</IonSelectOption>
                </IonSelect>
              </IonButton>
            )}

            <IonList className="ion-margin-top plannerLayoutsLinks">
              {staticPages && staticPages.map((page: any, index: number) => {
                if (!page.pageDisabled) {
                  return (
                    <IonItem className="plannerMenuNavLink" key={page.pageId} onClick={() => goToStaticPageId(page.pageId)}>
                      <IonLabel className="label">{page.pageName}</IonLabel>
                    </IonItem>
                  )
                } else {
                  return (
                    <IonItem className="plannerMenuNavLink" key={page.pageId}>
                      <IonLabel className="label" style={{ textDecoration: 'line-through', color: 'var(--ion-color-medium)' }}>{page.pageName}</IonLabel>
                    </IonItem>
                  )
                }
              })}
            </IonList>
          </IonContent>
        </IonMenu>
      )}
    </>
  )

}

export default withRouter(PlannerMenu);
