import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as plannerActions from '../../../store/actions/plannerControls';
import sanitizeHtml from 'sanitize-html';

type handlerProps = {
  firstPageHtml: string | undefined;
  pageType: any;
  pageTypeCount: any;
  pageChanged: any;
  setPageChanged: Function;
}

const UserEditFieldsHandler: React.FC<handlerProps> = ({ firstPageHtml, pageType, pageTypeCount, pageChanged, setPageChanged }) => {
  const { plannerData, elementOnFocus, plannerTextStyle } = useSelector((state: any) => state.plannerControls);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstPageHtml != '') {
      const editableElements = window.document.querySelector('.plannerPagesWrapper')!.querySelectorAll('.js-app-user-editable');
      if (editableElements.length) {
        editableElements.forEach((el: any) => {
          el.setAttribute('contenteditable', true);
          if (el.getAttribute('data-use-original-style') != '1') {
            el.style.fontFamily = plannerTextStyle.family;
            el.style.fontSize = plannerTextStyle.size + 'em';
            // console.log(el.getAttribute('data-block-name'), el.getAttribute('data-block-name').includes('notes'));
            if (el.getAttribute('data-block-name')?.includes('note')) el.style.lineHeight = plannerTextStyle.lineHeight + 'em';
            // if(el.getAttribute('data-block-name') === null) console.log(el);
          }
        });
      }
    }
  }, [firstPageHtml, pageTypeCount])

  useEffect(() => {
    const editableElements = window.document.querySelector('.plannerPagesWrapper')!.querySelectorAll('.js-app-user-editable');
    if (editableElements.length) {
      editableElements.forEach((el: any) => {
        if (el.getAttribute('data-use-original-style') != '1') {
          el.style.fontFamily = plannerTextStyle.family;
          el.style.fontSize = plannerTextStyle.size + 'em';
          // console.log(el.getAttribute('data-block-name'), el.getAttribute('data-block-name').includes('notes'));
          if (el.getAttribute('data-block-name')?.includes('note')) el.style.lineHeight = plannerTextStyle.lineHeight + 'em';
          // if(el.getAttribute('data-block-name') === null) console.log(el);
        }
      });
    }
  }, [plannerTextStyle])

  useEffect(() => {
    if (firstPageHtml != '') {
      const plannerPages = window.document.querySelector('.plannerPagesWrapper');
      if (plannerPages) {
        const editableElements = plannerPages.querySelectorAll('.js-app-user-editable');
        if (editableElements.length) {
          editableElements.forEach((el: any) => {
            const elId = el.getAttribute('data-block-name') + '.' + el.getAttribute('name');
            if (plannerData
              && plannerData[pageType]
              && plannerData[pageType][pageTypeCount]
              && plannerData[pageType][pageTypeCount][elId]) {
              el.innerHTML = plannerData[pageType][pageTypeCount][elId];
            } else {
              // el.innerHTML = '';
            }
            el.addEventListener('focusin', onElFocus);
            el.addEventListener('focusout', onElFocus);
          });
          return () => {
            editableElements.forEach((el: any) => {
              el.removeEventListener('focusin', onElFocus);
              el.removeEventListener('focusout', onElFocus);
            });
          }
        }
      }
    }
  }, [firstPageHtml, plannerData, pageTypeCount])

  useEffect(() => {
    if (firstPageHtml != '') {
      const plannerPages = window.document.querySelector('.plannerPagesWrapper');
      if (plannerPages) {
        const plannerCheckboxes = plannerPages.querySelectorAll('.js-checkbox');
        if (plannerCheckboxes.length) {
          plannerCheckboxes.forEach((el: any) => {
            const elId = el.getAttribute('data-block-name') + '.' + el.getAttribute('name');
            if (plannerData
              && plannerData[pageType]
              && plannerData[pageType][pageTypeCount]
              && plannerData[pageType][pageTypeCount].hasOwnProperty(elId)) {
              const checkboxState = plannerData[pageType][pageTypeCount][elId];
              if (checkboxState == true) {
                el.classList.add('checked');
              } else {
                el.classList.remove('checked');
              }
            }
            el.addEventListener('click', onCheckboxClick)
            el.addEventListener('touchend', onCheckboxClick)
          });
          return () => {
            plannerCheckboxes.forEach((el: any) => {
              el.removeEventListener('click', onCheckboxClick)
              el.removeEventListener('touchend', onCheckboxClick)
            })
          }
        }
      }
    }
  }, [firstPageHtml, plannerData, pageTypeCount])

  useEffect(() => {
    const body = window.document.querySelector('body');
    if (body) {
      body.addEventListener('click', handleUnfocus);
      body.addEventListener('touchend', handleUnfocus);
      return () => {
        body.removeEventListener('click', handleUnfocus)
        body.removeEventListener('touchend', handleUnfocus)
      };
    }
  }, [])

  const onElFocus = (e: any) => {
    // console.log(e);
    if(e.touches && e.touches.length > 1) {e.preventDefault(); return;}
    let focus = (document.activeElement === e.target);
    dispatch(plannerActions.set_element_focus(focus));
    if (!focus) {
      const elData: any = {};
      elData.elName = e.target.getAttribute('name');
      elData.blockName = e.target.getAttribute('data-block-name');
      elData.elValue = sanitizeHtml(e.target.innerHTML);
      dispatch(plannerActions.update_planner_data(pageType, pageTypeCount, { name: `${elData.blockName}.${elData.elName}`, value: elData.elValue }))
      dispatch(plannerActions.set_planner_data_changed(pageType, pageTypeCount))
      setPageChanged(true);
    }
  }

  const handleUnfocus = (e: any) => {
    const plannerClicked = e.target.closest('.plannerWrapper');
    const parentEditable = e.target.closest('.js-app-user-editable');
    const targetIsStageText = e.target.getAttribute('stageText');
    if (plannerClicked && !parentEditable && !targetIsStageText) {
      // console.log('unfocus');
      (document.activeElement as HTMLElement).blur();
      if(elementOnFocus) dispatch(plannerActions.set_element_focus(false));
      return
    }
    if (plannerClicked
      && document.activeElement
      && e.target !== document.activeElement
      && parentEditable
      && parentEditable.getAttribute('data-block-name') !== document.activeElement.getAttribute('data-block-name')
      && document.activeElement.classList.contains('js-app-user-editable')) {
      // console.log('unfocus');
      (document.activeElement as HTMLElement).blur();
      dispatch(plannerActions.set_element_focus(false));
    }
  }



  const onCheckboxClick = (el: any) => {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
      dispatch(plannerActions.set_element_focus(false));
    }
    el.preventDefault();
    el.stopPropagation();
    const element = el.target.closest('.js-checkbox');
    element.classList.toggle('checked');
    const elData: any = {};
    elData.elName = element.getAttribute('name');
    elData.blockName = element.getAttribute('data-block-name');
    elData.elValue = element.classList.contains('checked');
    dispatch(plannerActions.update_planner_data(pageType, pageTypeCount, { name: `${elData.blockName}.${elData.elName}`, value: elData.elValue }))
    dispatch(plannerActions.set_planner_data_changed(pageType, pageTypeCount));
    setPageChanged(true);
  }

  return (
    <></>
  )
}

export default UserEditFieldsHandler;