export const plannerFonts: any = {
  'Ubuntu': { url: 'https://fonts.googleapis.com/css2?family=Ubuntu&display=swap', name: 'Ubuntu', size: 3.1, lineHeight: 2.2 },
  'Roboto': { url: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap', name: 'Roboto', size: 3.1, lineHeight: 2.2 },
  'Open Sans': { url: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap', name: 'Open Sans', size: 2.7, lineHeight: 2.5 },
  'Montserrat': { url: 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap', name: 'Montserrat', size: 2.8, lineHeight: 2.4 },
  'Raleway': { url: 'https://fonts.googleapis.com/css2?family=Raleway&display=swap', name: 'Raleway', size: 2.7, lineHeight: 2.5 },
  'Caveat': { url: 'https://fonts.googleapis.com/css2?family=Caveat&display=swap', name: 'Caveat', size: 4.2, lineHeight: 1.6 },
  'Courgette': { url: 'https://fonts.googleapis.com/css2?family=Courgette&display=swap', name: 'Courgette', size: 3.2, lineHeight: 2.1 },
  'Itim': { url: 'https://fonts.googleapis.com/css2?family=Itim&display=swap', name: 'Itim', size: 3.5, lineHeight: 1.9 },
  'Nanum Pen Script': { url: 'https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap', name: 'Nanum Pen Script', size: 4, lineHeight: 1.7 },
  'Architects Daughter': { url: 'https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap', name: 'Architects Daughter', size: 3.4, lineHeight: 2 },
  'Indie Flower': { url: 'https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap', name: 'Indie Flower', size: 3.6, lineHeight: 1.9 },
}

export const stageFonts: any = {
  'Ubuntu': { url: 'https://fonts.googleapis.com/css2?family=Ubuntu&display=swap', name: 'Ubuntu', size: 20, lineHeight: 2.2 },
  'Roboto': { url: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap', name: 'Roboto', size: 20, lineHeight: 2.2 },
  'Open Sans': { url: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap', name: 'Open Sans', size: 20, lineHeight: 2.5 },
  'Montserrat': { url: 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap', name: 'Montserrat', size: 20, lineHeight: 2.4 },
  'Raleway': { url: 'https://fonts.googleapis.com/css2?family=Raleway&display=swap', name: 'Raleway', size: 20, lineHeight: 2.5 },
  'Caveat': { url: 'https://fonts.googleapis.com/css2?family=Caveat&display=swap', name: 'Caveat', size: 20, lineHeight: 1.6 },
  'Courgette': { url: 'https://fonts.googleapis.com/css2?family=Courgette&display=swap', name: 'Courgette', size: 20, lineHeight: 2.1 },
  'Itim': { url: 'https://fonts.googleapis.com/css2?family=Itim&display=swap', name: 'Itim', size: 20, lineHeight: 1.9 },
  'Nanum Pen Script': { url: 'https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap', name: 'Nanum Pen Script', size: 20, lineHeight: 1.7 },
  'Architects Daughter': { url: 'https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap', name: 'Architects Daughter', size: 20, lineHeight: 2 },
  'Indie Flower': { url: 'https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap', name: 'Indie Flower', size: 20, lineHeight: 1.9 },
  'Tangerine': { url: 'https://fonts.googleapis.com/css2?family=Tangerine&display=swap', name: 'Tangerine', size: 20, lineHeight: 1.9 }
}

export const setPlannerFont = (fontUrl: string) => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById('plannerFont');
    if (!existingScript) {
      const link = document.createElement('link');
      link.href = fontUrl;
      link.id = 'plannerFont';
      link.rel = 'stylesheet';
      document.body.appendChild(link);
      link.onload = () => {
        resolve(true);
      };
    } else {
      const link = document.getElementById('plannerFont');
      if (link) {
        link.remove();
        const newlink = document.createElement('link');
        newlink.href = fontUrl;
        newlink.id = 'plannerFont';
        newlink.rel = 'stylesheet';
        document.body.appendChild(newlink);
        newlink.onload = () => {
          resolve(true);
        };
      }
    }
  })
}

export const setStageFont = (fontParams: any, action: string) => {
  return new Promise((resolve, reject) => {
    const font = fontParams.name ? fontParams : stageFonts[fontParams];
    const fontNameParam = font.name.replace(' ', '');
    if (action == 'add') {
      const existingScript = document.getElementById(`stageFont-${fontNameParam}`);
      if (!existingScript) {
        const link = document.createElement('link');
        link.href = font.url;
        link.id = `stageFont-${fontNameParam}`;
        link.rel = 'stylesheet';
        document.body.appendChild(link);
        link.onload = () => {
          resolve(true);
        };
      }else{
        resolve(true);
      }
    }
  })
}