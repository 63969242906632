function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
export function getColors(svgString) {
  const matchedColors = svgString.match(/#(?:[0-9a-fA-F]{3}){1,2}/g);
  const usedColors = matchedColors ? matchedColors.filter(onlyUnique) : [];
  return usedColors;
}

export function svgToURL(s) {
  const uri = window.btoa(unescape(encodeURIComponent(s)));
  return "data:image/svg+xml;base64," + uri;
}

export function replaceColors(svgString, colorsMap, selectedId, originalId) {
  if (selectedId == originalId) {
    // console.log(svgString)
    if (svgString && colorsMap.oldColor && colorsMap.newColor) {
      const newSvg = svgString.replace(new RegExp(colorsMap.oldColor, 'g'), colorsMap.newColor);
      return newSvg;
    } else {
      return svgString;
    }
  }else{
    return svgString;
  }
}