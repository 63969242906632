import { IonSelect, IonSelectOption } from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as plannerActions from '../../store/actions/plannerControls';
import { useCheckPermission } from '../../services/userRolesContext';
import { stageFonts, plannerFonts } from "../../services/customFontsController";
import './StickerSelector.css';

type Props = {
  target: string;
  updateTextEl?: Function;
}

const FontSelector: React.FC<Props> = ({ target, updateTextEl }) => {
  const userPermissions: any = useCheckPermission();
  const dispatch = useDispatch();
  const uid = useSelector((state: any) => state.auth.uid);
  const pageType = useRef<any>();
  const pageTypeCount = useRef<any>();
  const activePlanner = useSelector((state: any) => state.userData.activePlanner);
  const plannerControls = useSelector((state: any) => state.plannerControls);
  const curPage = useSelector((state: any) => state.plannerControls.curPage);
  const selectedElement = useSelector((state: any) => state.plannerControls.selectedElement);
  // const curSelected = useRef(selectedElement.textId);

  useEffect(() => {
    if (activePlanner && activePlanner.pages) {
      pageType.current = activePlanner.pages[curPage].pageId;
      pageTypeCount.current = activePlanner.pages[curPage].pageTypeCount;
    }
  })

  // pageType.current = activePlanner.pages[curPage].pageId;
  // pageTypeCount.current = activePlanner.pages[curPage].pageTypeCount;

  // useEffect(() => {
  //   curSelected.current = selectedElement.textId;
  // }, [selectedElement])

  // const updateTextEl = (font: any, selectedId: any) => {
  //   setTimeout(() => {
  //     if (selectedId == curSelected.current) {
  //       const newTextEl = { ...selectedElement, fontFamily: font.name };
  //       setStageFont(font, 'add').then((res) => {
  //         if (res) {
  //           dispatch(plannerActions.update_stage_text_el(pageType.current, pageTypeCount.current, { ...newTextEl }));
  //         }
  //       })
  //         .catch((e) => console.log(e))
  //     }
  //   }, 700);
  // }

  return (
    <>
      {target === 'plannerFields' && (
        <IonSelect style={{ backgroundColor: '#ffffff', paddingRight: 10 + 'px' }} interface="popover" value={plannerControls.plannerTextStyle.family} onIonChange={(e: any) => {
          e.detail.value && dispatch(plannerActions.update_planner_font(uid, activePlanner.fbPlannerId, userPermissions, { ...plannerFonts[e.detail.value] }));
        }}>
          {Object.keys(plannerFonts).map((font, i) => (
            <IonSelectOption value={font} key={font}>{font}</IonSelectOption>
          ))}
        </IonSelect>
      )}
      {target === 'stageTexts' && (
        <IonSelect interface="popover" value={plannerControls.stageTexts[activePlanner.pages[curPage].pageId][activePlanner.pages[curPage].pageTypeCount][selectedElement.textId].fontFamily || 'Ubuntu'} onIonChange={(e: any) => {
          e.detail.value && updateTextEl && updateTextEl({ ...stageFonts[e.detail.value] });
        }}>
          {Object.keys(stageFonts).map((font, i) => (
            <IonSelectOption value={font} key={font}>{font}</IonSelectOption>
          ))}
        </IonSelect>
      )}
    </>
  )
}

export default FontSelector;