import React, { useEffect, useState } from 'react';
import * as actionTypes from '../../store/actions/userData';
import { useSelector, useDispatch } from 'react-redux';
import { IonButton, 
  IonButtons, 
  IonContent, 
  IonDatetime, 
  IonHeader, 
  IonIcon, 
  IonInput, 
  IonItem, 
  IonLabel, 
  IonModal, 
  IonSelect, 
  IonSelectOption, 
  IonSpinner, 
  IonTitle, 
  IonToolbar, 
  useIonViewDidEnter} from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import ChooseCoverPopup from './ChooseCoverModal';
import moment from 'moment';
import { logScreenViewEvent } from '../../services/logger';
import { AddPlannerGuides } from '../../components/Guides/Guides';
type Props = {
  config: any;
  hideModal: Function;
}

const AddPlannerModal: React.FC<Props> = ({ config, hideModal }) => {
  const history = useHistory();
  const uid = useSelector((state: any) => state.auth.uid);
  const userData = useSelector((state: any) => state.userData);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);
  const date = new Date();
  const y = date.getFullYear();
  const [plannerYear, setPlannerYear] = useState<any>(y);
  const [newPlannerName, setNewPlannerName] = useState<string>(config.plannerName);
  const [selectedWeekStart, setSelectedWeekStart] = useState<String>('monday');
  const [plannerAdded, setPlannerAdded] = useState(false);
  const [showCoverModal, setShowCoverModal] = useState(false);
  const [coverModalConfig, setCoverModalConfig] = useState<Object>({});
  const [selectedCover, setSelectedCover] = useState('');

  useIonViewDidEnter(() => {
    document.title = 'Add planner - Digital Planner App';
    logScreenViewEvent();
  }, [])

  const savePlanner = () => {
    dispatch(actionTypes.add_new_planner({
      uid: uid,
      plannerId: config.id,
      plannerName: newPlannerName,
      builderId: config.builderId,
      plannerYear,
      selectedWeekStart,
      plannerCover: selectedCover,
    }));
    setPlannerAdded(true);
  }

  useEffect(() => {
    if(plannerAdded && !userData.loading){
      hideModal();
      history.push("/");
    }
  }, [plannerAdded, userData.loading])

  const saveSelectedCover = (cover: any) => {
    setShowCoverModal(false);
    setSelectedCover(cover);
  }

  return (
    <>
    <AddPlannerGuides />
      {showCoverModal && <ChooseCoverPopup config={coverModalConfig} hideModal={() => setShowCoverModal(false)} saveSelectedCover={(cover: any) => saveSelectedCover(cover)} />}
      <IonModal isOpen={showModal} cssClass="add-planner-modal" onWillDismiss={() => { hideModal() }}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Planner</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => hideModal()}>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonItem key="newPlannerName" className="newPlannerName">
            <IonLabel position="floating">Planner name</IonLabel>
            <IonInput value={newPlannerName} onIonChange={(e) => {setNewPlannerName(e.detail.value!)}}></IonInput>
          </IonItem>
          {config.dates
            && (<IonItem key="plannerYear" className="plannerYear">
              <IonLabel>Planner Year</IonLabel>
              <IonDatetime
                displayFormat="YYYY"
                value={plannerYear}
                min="2010"
                max="2030"
                onIonChange={e => setPlannerYear(moment(e.detail.value!).format('YYYY'))} />
            </IonItem>)
          }
          {config.weekStart
            && <IonItem className="weekStart">
              <IonLabel>Week start day</IonLabel>
              <IonSelect value={selectedWeekStart} onIonChange={e => setSelectedWeekStart(e.detail.value)}>
                <IonSelectOption value="monday">Monday</IonSelectOption>
                <IonSelectOption value="sunday">Sunday</IonSelectOption>
              </IonSelect>
            </IonItem>
          }

          <IonButton fill="outline" className="ion-margin-top chooseCoverBttn" expand="block" onClick={() => {setCoverModalConfig({plannerId: undefined}); setShowCoverModal(true)}}>
            Choose planner cover
          </IonButton>

          <IonButton disabled={userData.loading} className="ion-margin-top" expand="block" onClick={() => savePlanner()}>
            {userData.loading 
            ? <IonSpinner slot="start" name="lines-small" />
            : <IonIcon icon={addOutline} slot="start"></IonIcon>}
            Add to my bookshelf
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  )
}

export default AddPlannerModal;