import React, { useEffect, useRef, useState } from 'react';
import { Image, Transformer, Text } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import * as plannerActions from '../../store/actions/plannerControls';
import { invertColor } from '../../services/colorConversters';

type Props = {
  stageRef: React.RefObject<any>;
  originalText: string;
  coords: any;
  textId: number;
  size: any;
  rotation: number;
  fontSize: number;
  textColor: string;
  fontFamily: string;
}

const PlannerStageText: React.FC<Props> = ({ stageRef, originalText, coords, textId, size, rotation, fontSize, textColor, fontFamily }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData);
  const plannerControls = useSelector((state: any) => state.plannerControls);
  const selectedElement = useSelector((state: any) => state.plannerControls.selectedElement);
  const curPage = useSelector((state: any) => state.plannerControls.curPage);
  const plannerId = useSelector((state: any) => state.userData.activePlanner.fbPlannerId);
  const plannerYear = useSelector((state: any) => state.userData.activePlanner.plannerYear);
  const uid = useSelector((state: any) => state.auth.uid);
  const fontLoading = useSelector((state: any) => state.plannerControls.fontLoading);
  const pageType = useRef<any>();
  const pageTypeCount = useRef<any>();
  const trRef = useRef<any>(null);
  const textRef = useRef<any>(null);
  const [textVal, setTextVal] = useState<string>(originalText);
  const [isSelected, setIsSelected] = useState(selectedElement && typeof selectedElement.textId === 'number' && selectedElement.textId === textId);
  const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  useEffect(() => {
    pageType.current = userData.activePlanner.pages[plannerControls.curPage].pageId;
    pageTypeCount.current = userData.activePlanner.pages[plannerControls.curPage].pageTypeCount;
    textRef.current && textRef.current.getLayer().batchDraw();
    setIsSelected(selectedElement && typeof selectedElement.textId === 'number' && selectedElement.textId === textId);
    if (isSelected) {
      trRef.current.nodes([textRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  })

  useEffect(() => {
    setTimeout(() => {
      if (textRef.current) {
        textRef.current.getLayer().batchDraw();
      }
    }, 3000);
  }, [])

  // useEffect(() => {
  //   if (!fontLoading && textRef.current) {
  //     setTimeout(() => {
  //       textRef.current.getLayer().batchDraw();
  //     }, 2000);
  //   }
  // }, [fontLoading, plannerControls.stageTexts])

  const setTextArea = () => {
    textRef.current.hide();
    trRef.current.hide();
    let textPosition = textRef.current.absolutePosition();

    let areaPosition = {
      x: stageRef.current.container().offsetLeft + textPosition.x,
      y: stageRef.current.container().offsetTop + textPosition.y,
    };

    // let textarea = document.createElement('textarea');
    let textarea = document.createElement('div');
    textarea.setAttribute('contenteditable', 'true');
    textarea.setAttribute('stageText', 'true');
    textarea.classList.add('stageEditableEl');
    document.querySelector('.plannerCanvas')?.appendChild(textarea);
    textarea.innerText = textRef.current.text();
    textarea.style.position = 'absolute';
    textarea.style.top = areaPosition.y + 'px';
    textarea.style.left = areaPosition.x + 'px';
    textarea.style.width = textRef.current.width() * stageRef.current.scale().x + 'px';
    textarea.style.height = textRef.current.height() * stageRef.current.scale().y + 'px';
    textarea.style.fontSize = textRef.current.fontSize() * stageRef.current.scale().x + 'px';

    textarea.style.border = '1px solid green';
    textarea.style.padding = '0px';
    textarea.style.margin = '0px';
    textarea.style.overflow = 'hidden';
    textarea.style.background = 'none';
    textarea.style.outline = 'none';
    textarea.style.resize = 'none';
    textarea.style.lineHeight = textRef.current.lineHeight();
    textarea.style.fontFamily = textRef.current.fontFamily();
    textarea.style.transformOrigin = 'left top';
    textarea.style.textAlign = textRef.current.align();
    textarea.style.color = textRef.current.fill();
    let rotation = textRef.current.rotation();
    let transform = '';
    if (rotation) {
      transform += 'rotateZ(' + rotation + 'deg)';
    }

    let px = 0;
    // also we need to slightly move textarea on firefox
    // because it jumps a bit
    let isFirefox =
      navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (isFirefox) {
      px += 2 + Math.round(textRef.current.fontSize() / 20);
    }
    transform += 'translateY(-' + px + 'px)';

    textarea.style.transform = transform;

    // reset height
    // textarea.style.height = 'auto';
    // after browsers resized it we can set actual value
    // textarea.style.height = textarea.scrollHeight + 3 + 'px';

    textarea.focus();

    function removeTextarea() {
      textarea.parentNode!.removeChild(textarea);
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('touchend', handleOutsideClick);
      // console.log(trRef.current, textRef.current)
      if (textRef.current) {
        textRef.current.show();
      }
      if (trRef.current) {
        trRef.current.show();
        trRef.current.forceUpdate();
      }
    }

    function setTextareaWidth(newWidth: any) {
      if (!newWidth) {
        // set width for placeholder
        newWidth = textRef.current.placeholder.length * textRef.current.fontSize();
      }
      // some extra fixes on different browsers
      let isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      let isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isSafari || isFirefox) {
        newWidth = Math.ceil(newWidth);
      }

      // let isEdge =
      //   document.documentMode || /Edge/.test(navigator.userAgent);
      // if (isEdge) {
      //   newWidth += 1;
      // }
      textarea.style.width = newWidth + 'px';
    }

    textarea.addEventListener('keydown', function (e) {
      // hide on enter
      // but don't hide on shift + enter
      if (e.keyCode === 13 && e.shiftKey) {
        textRef.current.text(textarea.innerText);
        setTextVal(textarea.innerText);
        updateTextEl();
        removeTextarea();
      }
      // on esc do not set value back to node
      if (e.keyCode === 27) {
        removeTextarea();
      }
    });

    textarea.addEventListener('keydown', function (e) {
      let scale = textRef.current.getAbsoluteScale().x;
      setTextareaWidth(textRef.current.width() * scale);
      textarea.style.height = 'auto';
      textarea.style.height =
        textarea.scrollHeight + textRef.current.fontSize() + 'px';
    });

    function handleOutsideClick(e: any) {
      if (e.target !== textarea) {
        textRef.current.text(textarea.innerText);
        setTextVal(textarea.innerText);
        updateTextEl();
        removeTextarea();
      }
    }
    setTimeout(() => {
      window.addEventListener('click', handleOutsideClick);
      window.addEventListener('touchend', handleOutsideClick);
    });

  }


  const selectTextEl = (e: any) => {
    if (plannerControls.drawMethod == 'controls') {
      const textEl = textRef.current && {
        textId,
        type: 'text',
        width: textRef.current.attrs.width,
        height: textRef.current.attrs.height,
        x: textRef.current.attrs.x,
        y: textRef.current.attrs.y,
        rotation: textRef.current.attrs.rotation,
        fontSize: textRef.current.attrs.fontSize,
        text: textRef.current.attrs.text,
        textPage: curPage,
        textColor: textRef.current.attrs.fill,
        fontFamily: textRef.current.attrs.fontFamily,
      }
      dispatch(plannerActions.select_stage_element({ ...textEl }))
    }
  }

  const updateTextEl = () => {
    const textEl = textRef.current && {
      textId,
      type: 'text',
      width: textRef.current.attrs.width,
      height: textRef.current.attrs.height,
      x: textRef.current.attrs.x,
      y: textRef.current.attrs.y,
      rotation: textRef.current.attrs.rotation,
      fontSize: textRef.current.attrs.fontSize,
      text: textRef.current.attrs.text,
      textPage: curPage,
      // textColor: textRef.current.attrs.fill,
      textColor: darkMode ? invertColor(textRef.current.attrs.fill) : textRef.current.attrs.fill,
      fontFamily: textRef.current.attrs.fontFamily,
    }
    dispatch(plannerActions.select_stage_element({ ...textEl, textColor: darkMode ? invertColor(textEl.textColor) : textEl.textColor }));
    dispatch(plannerActions.update_stage_text_el(pageType.current, pageTypeCount.current, textEl));
    dispatch(plannerActions.set_stage_texts_changed(pageType.current, pageTypeCount.current));
  }

  // useEffect(() => {
  //   if (textRef.current && isSelected) {
  //       setTimeout(() => {
  //         textRef.current.getLayer().batchDraw();
  //       }, 1000);
  //     // }
  //   }
  // }, [selectedElement, fontFamily, fontLoading])

  // useEffect(() => {
  //   if (stageRef.current && textRef.current && isSelected) {
  //     console.log(stageRef.current.getStage())
  //     var canvas = document.createElement('canvas');
  //     var ctx = canvas.getContext('2d');
  //     if (ctx) {
  //       ctx.font = fontFamily;
  //       var isFontLoaded = false;
  //       var TEXT_TEXT = 'Some test text;';
  //       var initialMeasure = ctx.measureText(TEXT_TEXT);
  //       var initialWidth = initialMeasure.width;

  //       const whenFontIsLoaded = (callback: Function, attemptCount?: any) => {
  //         if (attemptCount === undefined) {
  //           attemptCount = 0;
  //         }
  //         if (attemptCount >= 5) {
  //           callback();
  //           return;
  //         }
  //         if (isFontLoaded) {
  //           callback();
  //           return;
  //         }
  //         if (ctx) {
  //           const metrics = ctx.measureText(TEXT_TEXT);
  //           const width = metrics.width;
  //           console.log(width, initialWidth)
  //           if (width !== initialWidth) {
  //             isFontLoaded = true;
  //             callback();
  //           } else {
  //             setTimeout(function () {
  //               whenFontIsLoaded(callback, attemptCount + 1);
  //             }, 1000);
  //           }
  //         }
  //       }

  //       whenFontIsLoaded(function () {
  //         // set font style when font is loaded
  //         // so Konva will recalculate text wrapping if it has limited width
  //         console.log('setting new font');
  //         textRef.current.fontFamily(fontFamily);
  //         textRef.current.getLayer().batchDraw();
  //       });
  //     }
  //   }
  // }, [fontFamily])

  // useEffect(() => {
  //   setStageFont()
  // }, [fontFamily])


  return (
    <>
      <Text ref={textRef}
        x={coords.x} y={coords.y}
        width={size.width}
        height={size.height} wrap="char"
        text={originalText}
        fontSize={fontSize}
        fill={textColor}
        rotation={rotation}
        draggable={isSelected}
        onClick={(e) => selectTextEl(e)}
        onTap={(e) => selectTextEl(e)}
        onDblClick={() => setTextArea()}
        onDblTap={() => setTextArea()}
        fontFamily={fontFamily}
        // style={{fontFamily: 'Tangerine, cursive'}}
        onTransform={() => {
          textRef.current.setAttrs({
            width: textRef.current.width() * textRef.current.scaleX(),
            height: textRef.current.height() * textRef.current.scaleY(),
            scaleX: 1,
            scaleY: 1,
          });
        }}
        onTransformEnd={() => updateTextEl()}
        onDragEnd={() => updateTextEl()}
      />
      {isSelected &&
        <Transformer
          ref={trRef}
          // node={textRef.current}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      }
    </>
  );
}

export default PlannerStageText