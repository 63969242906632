import * as actionTypes from '../actions/plannerControls';

const initialState = {
  curPage: 0,
  curPageTypeCount: 0,
  zoom: 0,
  zoomFitByHeight: 0,
  plannerFit: false,
  globalId: 0,
  pinching: false,
  panning: false,
  panDisabled: false,
  zoomDisabled: false,
  pinchZoommed: false,
  plannerDisabled: false,
  drawMode: false,
  drawing: false,
  linesChanged: {},
  plannerDataChanged: {},
  stickersChanged: {},
  stageTextsChanged: {},
  drawMethod: 'links',
  strokeWidth: 3,
  eraserWidth: 3,
  strokeColor: '#000000',
  plannerTextStyle: { family: 'Ubuntu', style: '', size: 3.2, lineHeight: 1.4 },
  lastTextStyles: {},
  lines: [],
  stickers: [],
  stageTexts: [],
  lastAddedSticker: {},
  selectedElement: {},
  elementOnFocus: false,
  plannerData: [],
  selectedLine: {},
  fontLoading: false,
  showStickerSelector: false,
}

export const plannerControlsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.CLEANUP_ALL_DATA:
      return {
        ...initialState
      }
    case actionTypes.SET_CUR_PAGE:
      return {
        ...state,
        curPage: action.curPage
      }
    case actionTypes.SET_ZOOM:
      return {
        ...state,
        zoom: action.zoom
      }
    case actionTypes.SET_ZOOM_BY_HEIGHT:
      return {
        ...state,
        zoomFitByHeight: action.zoom
      }
    case actionTypes.SET_PLANNER_FIT:
      return {
        ...state,
        plannerFit: action.fit
      }
    case actionTypes.SET_PINCHING:
      return {
        ...state,
        pinching: action.pinching
      }
    case actionTypes.SET_PANNING:
      return {
        ...state,
        panning: action.panning
      }
    case actionTypes.SET_PLANNER_DISABLED:
      return {
        ...state,
        plannerDisabled: action.disabled
      }
    case actionTypes.SET_PINCH_ZOOM:
      return {
        ...state,
        pinchZoommed: action.pinchZoom
      }
    case actionTypes.SET_DRAW_MODE:
      return {
        ...state,
        drawMode: action.drawMode,
        selectedElement: {},
        selectedLine: {},
      }
    case actionTypes.SET_STROKE_WIDTH:
      return {
        ...state,
        strokeWidth: action.strokeWidth
      }
    case actionTypes.SET_ERASER_WIDTH:
      return {
        ...state,
        eraserWidth: action.eraserWidth
      }
    case actionTypes.SET_STROKE_COLOR:
      return {
        ...state,
        strokeColor: action.strokeColor
      }
    case actionTypes.SET_DRAWING:
      return {
        ...state,
        drawing: action.drawing
      }
    case actionTypes.SET_PLANNER_LINES:
      const newLines: any = { ...state.lines };
      if (!newLines[action.pageType]) newLines[action.pageType] = {};
      newLines[action.pageType][action.pageTypeCount] = JSON.stringify(action.lines);
      return {
        ...state,
        lines: newLines
      }
    case actionTypes.REMOVE_PAGE_LINES:
      const allLines: any = JSON.parse(JSON.stringify(state.lines));
      delete allLines[action.pageType][action.pageTypeCount];
      return {
        ...state,
        lines: allLines,
        selectedLine: {},
      }
    case actionTypes.LOAD_PLANNER_LINES:
      return {
        ...state,
        lines: action.pagesData
      }
    case actionTypes.SET_DRAW_METHOD:
      return {
        ...state,
        drawMethod: action.method,
        // selectedElement: {},
        // selectedLine: {},
      }
    case actionTypes.ADD_STICKER_TO_PLANNER:
      const lastStickers: any = JSON.parse(JSON.stringify(state.stickers));
      if (!lastStickers[action.pageType]) lastStickers[action.pageType] = {};
      lastStickers[action.pageType][action.pageTypeCount]
        ? lastStickers[action.pageType][action.pageTypeCount] = [...lastStickers[action.pageType][action.pageTypeCount], action.sticker]
        : lastStickers[action.pageType][action.pageTypeCount] = [action.sticker];
      return {
        ...state,
        globalId: state.globalId + 1,
        stickers: { ...lastStickers },
        lastAddedSticker: { id: action.sticker.globalId }
      }
    case actionTypes.LOAD_PLANNER_STICKERS:
      return {
        ...state,
        stickers: action.plannerStickers
      }
    case actionTypes.UPDATE_STICKER_POSITION:
      const updatedStickers: any = JSON.parse(JSON.stringify(state.stickers));
      const newSticker = {
        url: action.sticker.url,
        x: action.sticker.x,
        y: action.sticker.y,
        scaleX: action.sticker.scaleX || 1,
        scaleY: action.sticker.scaleY || 1,
        rotation: action.sticker.rotation || 0
      };
      updatedStickers[action.pageType][action.pageTypeCount][action.sticker.stickerId] = { ...newSticker };
      return {
        ...state,
        stickers: { ...updatedStickers }
      }
    case actionTypes.UPDATE_SVG_STICKER:
      const updatedStickers2: any = JSON.parse(JSON.stringify(state.stickers));
      const newSticker2 = {
        url: action.sticker.url,
        x: action.sticker.x,
        y: action.sticker.y,
        type: 'svg',
        source: action.sticker.svgSource,
        scaleX: action.sticker.scaleX || 1,
        scaleY: action.sticker.scaleY || 1,
        rotation: action.sticker.rotation || 0
      };
      // console.log(updatedStickers2[action.pageType][action.pageTypeCount][action.sticker.stickerId])
      // console.log(newSticker2)
      updatedStickers2[action.pageType][action.pageTypeCount][action.sticker.stickerId] = { ...newSticker2 };
      return {
        ...state,
        stickers: { ...updatedStickers2 }
      }
    case actionTypes.REMOVE_STICKER:
      const originalStickers: any = JSON.parse(JSON.stringify(state.stickers));
      originalStickers[action.sticker.pageType][action.sticker.pageTypeCount].splice(action.sticker.id, 1);
      return {
        ...state,
        stickers: { ...originalStickers },
        selectedElement: {},
        lastAddedSticker: {}
      }
    case actionTypes.SELECT_STAGE_ELEMENT:
      return {
        ...state,
        selectedLine: {},
        selectedElement: { ...action.element },
        lastTextStyles: (action.element && action.element.type == 'text') ? { ...action.element } : { ...state.lastTextStyles }
      }
    case actionTypes.SET_LAST_ADDED_STICKER:
      return {
        ...state,
        selectedLine: {},
        selectedElement: {},
        lastAddedSticker: { id: action.id || undefined }
      }
    case actionTypes.REPLACE_SVG_COLOR:
      return {
        ...state,
        selectedElement: { ...state.selectedElement, replaceColors: action.newColors }
      }
    case actionTypes.CLEANUP_SELECTED_ELEMENTS:
      return {
        ...state,
        selectedElement: {},
        selectedLine: {}
      }
    case actionTypes.CLEANUP_PLANNER_DATA:
      return {
        ...state,
        lines: [],
        stickers: [],
        selectedElement: {},
        elementOnFocus: false,
      }
    case actionTypes.SELECT_LINE:
      return {
        ...state,
        selectedElement: {},
        selectedLine: action.line
      }
    case actionTypes.CLEANUP_SELECTED_LINE:
      return {
        ...state,
        selectedLine: {}
      }
    case actionTypes.SET_ELEMENT_FOCUS:
      return {
        ...state,
        elementOnFocus: action.focusState
      }
    case actionTypes.SET_PAN_DISABLED:
      return {
        ...state,
        panDisabled: action.disabled
      }
    case actionTypes.SET_ZOOM_DISABLED:
      return {
        ...state,
        zoomDisabled: action.disabled
      }
    case actionTypes.UPDATE_PLANNER_DATA:
      const originalData: any = JSON.parse(JSON.stringify(state.plannerData));
      if (!originalData[action.pageType]) originalData[action.pageType] = {};
      if (!originalData[action.pageType][action.pageTypeCount]) originalData[action.pageType][action.pageTypeCount] = {};
      originalData[action.pageType][action.pageTypeCount][action.element.name] = action.element.value;
      return {
        ...state,
        plannerData: { ...originalData }
      }
    case actionTypes.LOAD_PLANNER_DATA:
      return {
        ...state,
        plannerData: { ...action.plannerData }
      }
    case actionTypes.LOAD_PLANNER_TEXTS:
      return {
        ...state,
        stageTexts: action.plannerTexts
      }
    case actionTypes.ADD_STAGE_TEXT:
      const lastTexts: any = JSON.parse(JSON.stringify(state.stageTexts));
      if (!lastTexts[action.pageType]) lastTexts[action.pageType] = {};
      action.textEl.textId = state.globalId + 1;
      lastTexts[action.pageType][action.pageTypeCount]
        ? lastTexts[action.pageType][action.pageTypeCount] = [...lastTexts[action.pageType][action.pageTypeCount], action.textEl]
        : lastTexts[action.pageType][action.pageTypeCount] = [action.textEl];
      return {
        ...state,
        stageTexts: { ...lastTexts },
        drawMethod: 'controls'
      }
    case actionTypes.UPDATE_STAGE_TEXT:
      const lastTexts2: any = JSON.parse(JSON.stringify(state.stageTexts));
      if (!lastTexts2[action.pageType]) lastTexts2[action.pageType] = {};
      lastTexts2[action.pageType][action.pageTypeCount][action.textEl.textId] = { ...action.textEl };
      return {
        ...state,
        stageTexts: { ...lastTexts2 }
      }
    case actionTypes.REMOVE_STAGE_TEXT:
      const originalTexts: any = JSON.parse(JSON.stringify(state.stageTexts));
      originalTexts[action.textEl.pageType][action.textEl.pageTypeCount].splice(action.textEl.id, 1);
      return {
        ...state,
        selectedElement: {},
        stageTexts: { ...originalTexts }
      }
    case actionTypes.CHANGE_PLANNER_FONT:
      return {
        ...state,
        plannerTextStyle: { family: action.font.name, style: '', size: action.font.size, lineHeight: action.font.lineHeight },
      }
    case actionTypes.FONT_LOADING:
      return {
        ...state,
        fontLoading: action.loading,
      }
    case actionTypes.SET_LINES_CHANGED:
      const newPagesLinesChanged: any = JSON.parse(JSON.stringify(state.linesChanged));
      if (!newPagesLinesChanged[action.pageType]) newPagesLinesChanged[action.pageType] = [];
      newPagesLinesChanged[action.pageType].push(`${action.pageTypeCorrection}`)
      return {
        ...state,
        linesChanged: { ...JSON.parse(JSON.stringify(newPagesLinesChanged)) },
      }
    case actionTypes.SET_PLANNER_DATA_CHANGED:
      const newPlannerDataChanged: any = JSON.parse(JSON.stringify(state.plannerDataChanged));
      if (!newPlannerDataChanged[action.pageType]) newPlannerDataChanged[action.pageType] = [];
      newPlannerDataChanged[action.pageType].push(`${action.pageTypeCount}`)
      return {
        ...state,
        plannerDataChanged: { ...JSON.parse(JSON.stringify(newPlannerDataChanged)) },
      }
    case actionTypes.SET_STICKERS_CHANGED:
      const newStickersChanged: any = JSON.parse(JSON.stringify(state.stickersChanged));
      if (!newStickersChanged[action.pageType]) newStickersChanged[action.pageType] = [];
      newStickersChanged[action.pageType].push(`${action.pageTypeCount}`)
      return {
        ...state,
        stickersChanged: { ...JSON.parse(JSON.stringify(newStickersChanged)) },
      }
    case actionTypes.SET_STAGE_TEXTS_CHANGED:
      const newStageTextsChanged: any = JSON.parse(JSON.stringify(state.stageTextsChanged));
      if (!newStageTextsChanged[action.pageType]) newStageTextsChanged[action.pageType] = [];
      newStageTextsChanged[action.pageType].push(`${action.pageTypeCount}`)
      return {
        ...state,
        stageTextsChanged: { ...JSON.parse(JSON.stringify(newStageTextsChanged)) },
      }
    case actionTypes.CLEANUP_LINES_CHANGED:
      return {
        ...state,
        linesChanged: {},
      }
    case actionTypes.CLEANUP_PLANNER_DATA_CHANGED:
      return {
        ...state,
        plannerDataChanged: {},
      }
    case actionTypes.CLEANUP_STICKERS_CHANGED:
      return {
        ...state,
        stickersChanged: {},
      }
    case actionTypes.CLEANUP_STAGE_TEXTS_CHANGED:
      return {
        ...state,
        stageTextsChanged: {},
      }
    case actionTypes.SET_SHOW_STICKER_SELECTOR:
      return {
        ...state,
        showStickerSelector: action.show,
      }
    default:
      return {
        ...state
      }
  }

}