import { IonButton, IonCol, IonIcon, IonInput, IonRow, IonSpinner, IonText, IonThumbnail } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as libraryActions from '../../store/actions/library';
import '../Library/ChooseCoverModal.css';
import { addNewCover } from '../../services/adminActions';
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';

const AdminCovers: React.FC = () => {
  const coversLibrary = useSelector((state: any) => state.library.coversLibrary);
  const coversLoading = useSelector((state: any) => state.library.loading);
  const dispatch = useDispatch();
  const [newCoverId, setNewCoverId] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [addingCover, setAddingCover] = useState<boolean>(false);

  useEffect(() => {
    dispatch(libraryActions.load_covers_library());
  }, [])

  const saveNewCover = () => {
    if (newCoverId) {
      setAddingCover(true);
      addNewCover(newCoverId)
        .then(() => { dispatch(libraryActions.load_covers_library()); setAddingCover(false); setNewCoverId('') })
        .catch((e) => { setErrorMessage('cover not added'); console.log(e); setAddingCover(false); })
    }
  }

  return (
    <IonRow>
      <IonCol size="12" style={{ backgroundColor: 'rgb(245 245 245)' }} className="ion-margin-bottom">
        <IonRow>
          {errorMessage && <IonText color="danger">{errorMessage}</IonText>}
          <IonInput value={newCoverId} placeholder="Cover Builder ID" onIonChange={(e: any) => setNewCoverId(e.detail.value)}></IonInput>
          <IonButton disabled={addingCover} onClick={() => saveNewCover()}>
            {addingCover && <IonSpinner />}
            Add new cover
            </IonButton>
        </IonRow>
      </IonCol>
      {coversLoading 
      ? <LoadingSpinner />
      : coversLibrary?.map((cover: any) => {
        return (
          <IonCol size="3" key={cover.id}>
            <div className="">
              <IonThumbnail className="coverThumbnail">
                <img src={cover.frontImage} />
              </IonThumbnail>
            </div>
          </IonCol>
        )
      })}
    </IonRow>
  )
}

export default AdminCovers;