import firebase from "../../services/firebase";
import axios from 'axios';
import { CallDavGoogle, CallDavApple } from '../../services/caldav';
import * as calendarsActions from './userCalendars';
import sha256 from 'crypto-js/sha256';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import moment from "moment";
const gauth = GoogleAuth.init();
// console.log(GoogleAuth)

declare global {
  interface Window {
    gapi: any;
  }
}

export * from './auth.microsoft';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_LOGOUT = 'USER_LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOADING = 'AUTH_LOADING';
export const GAPI_AUTH_LOADING = 'GAPI_AUTH_LOADING';
export const GAPI_AUTH = 'GAPI_AUTH';
export const GAPI_AUTH2 = 'GAPI_AUTH2';
export const SET_APPLE_CREDENTIALS = 'SET_APPLE_CREDENTIALS';
export const SET_APPLE_CREDENTIALS_ERROR = 'SET_APPLE_CREDENTIALS_ERROR';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_CUSTOM_FB_UID = 'SET_CUSTOM_FB_UID';
export const PASSWORD_CONFIRMATION_NEEDED = 'PASSWORD_CONFIRMATION_NEEDED';
export const RESET_RECOGNIZED_USER = 'RESET_RECOGNIZED_USER';

const userLogin = (user: string, uid: string, role: string, googleUsed: any, microsoftUsed: any) => ({
  type: USER_LOGIN,
  user,
  uid,
  role,
  googleUsed,
  microsoftUsed
});
const userLogout = () => ({
  type: USER_LOGOUT
});
const authError = (message: string) => ({
  type: AUTH_ERROR,
  message
});
const authLoading = (loading: boolean) => ({
  type: AUTH_LOADING,
  loading
});
const setUserRole = (role: string, googleUsed: any, microsoftUsed: any) => ({
  type: SET_USER_ROLE,
  role,
  googleUsed,
  microsoftUsed
})
const gapiAuthLoading = (loading: boolean) => ({
  type: GAPI_AUTH_LOADING,
  gapiAuthLoading: loading
});
const gapiAuth = (googleUser: any, googleAccessToken: string) => ({
  type: GAPI_AUTH,
  googleUser,
  googleAccessToken
});
const gapiAuth2 = (googleAccessToken: string, googleUsed: boolean) => ({
  type: GAPI_AUTH2,
  googleAccessToken,
  googleUsed
});
const setAppleCredentials = (credentials: any) => ({
  type: SET_APPLE_CREDENTIALS,
  credentials
})

export const update_user_activity = () => {
  return (dispatch: any) => {
    const curUser = firebase.auth().currentUser;
    if (curUser && curUser.email) {
      const registered = moment(curUser.metadata.creationTime).format('DD-MM-YYYY HH:mm');
      const lastSignIn = moment(curUser.metadata.lastSignInTime).format('DD-MM-YYYY HH:mm');
      const lastActive = moment().format('DD-MM-YYYY HH:mm');
      firebase.firestore().collection('userList').doc(curUser.email)
        .set({ registered, lastSignIn, lastActive }, { merge: true })
    }
  }
}

export const fb_user_auth = () => {
  return (dispatch: any) => {
    dispatch(authError(''));
    dispatch(authLoading(true));
    // let lsuid: any = '';
    const auth = firebase.auth().onAuthStateChanged((user: any) => {
      if (user && user.email && user.uid) {
        const userSettingsCollection = firebase.firestore().collection('userData').doc(user.uid).collection('settings');
        userSettingsCollection.doc('permissions').get().then((permissions: any) => {
          // console.log(permissions.data().googleUsed)

          dispatch(userLogin(user.email, user.uid, sha256('role' + permissions.data().role).toString(), permissions.data().googleUsed, permissions.data().microsoftUsed));
        })
        // lsuid = user.uid
      }
    })

    dispatch(authLoading(false));
    return auth()
  }
}

export const set_custom_uid = (fbuid: any) => {
  return (dispatch: any) => {
    if (!fbuid) return;
    dispatch({ type: SET_CUSTOM_FB_UID, uid: localStorage.getItem('customFbUID') || fbuid });
  }
}
export const get_user_role = (uid: string) => {
  return (dispatch: any) => {
    firebase.firestore().collection('userData').doc(uid).collection('settings').doc('permissions').get().then((permissions: any) => {
      dispatch(setUserRole(sha256('role' + permissions.data().role).toString(), permissions.data().googleUsed, permissions.data().microsoftUsed));
      if (permissions.data().role == 'admin') {
        const lsuid = localStorage.getItem('customFbUID') || uid;
        dispatch({ type: SET_CUSTOM_FB_UID, uid: lsuid });
      }
    })
  }
}

export const set_custom_fb_uid = (uid: string) => {
  return (dispatch: any) => {
    // if (user_role == 'admin') {
    localStorage.setItem('customFbUID', uid);
    dispatch({ type: SET_CUSTOM_FB_UID, uid: uid });
    // }
  }
}

export const user_login = (userName: string, userPass: string) => {
  return (dispatch: any) => {
    dispatch(authError(''));
    dispatch(authLoading(true));
    // Trying to authenticate at Firebase
    firebase.auth().signInWithEmailAndPassword(userName, userPass)
      .then((userCredentials: any) => {
        if (userCredentials.user) {
          let userRole = '';
          const permDoc = firebase.firestore().collection('userData').doc(userCredentials.user.uid).collection('settings').doc('permissions');
          permDoc.get().then((permissions: any) => {
            if (!permissions.exists) {
              userRole = 'customer';
              permDoc.set({ role: userRole, email: userName }, { merge: true });
            } else {
              userRole = permissions.data().role;
            }
            dispatch(userLogin(userName, userCredentials.user.uid, sha256('role' + userRole).toString(), permissions.data().googleUsed, permissions.data().microsoftUsed));
          });
        }
      })
      .then(() => dispatch(authLoading(false)))
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {

          // If user not found - trying to authenticate at onPlanners.com
          const url = 'https://digital-planner.com/planner/api/login';
          const body = { "name": userName, "pass": userPass };
          const headers = { 'content-type': 'text/plain' };

          axios.post(url, body, { "headers": headers })
            .then((res: any) => {
              if (res.data.success && !res.data.wrong_pass) {
                dispatch(authLoading(true));
                firebase.auth().createUserWithEmailAndPassword(userName, userPass)
                  .then((userCredentials) => {
                    if (userCredentials.user) {
                      firebase.firestore().collection('userData').doc(userCredentials.user.uid)
                        .collection('settings').doc('permissions').set({ role: 'customer', email: userName }, { merge: true });

                      dispatch(userLogin(userName, userCredentials.user.uid, 'customer', false, false));
                      return userCredentials.user.uid
                    }
                  })
                  .then((uid) => {
                    firebase.firestore().collection('userList').doc(userName).set({ uid: uid, email: userName, remoteUid: res.data.uid }, { merge: true })
                  })
                  .then(() => dispatch(authLoading(false)))
                  .catch((error) => {
                    dispatch(authError(error.message));
                    dispatch(authLoading(false));
                  });
              } else if (res.data.success && res.data.wrong_pass) {
                dispatch({ type: PASSWORD_CONFIRMATION_NEEDED, loginEmail: userName, remoteUid: res.data.uid })
              } else {
                dispatch(authError(res.data.message))
                dispatch(authLoading(false));
              }
            })
            .then(() => dispatch(authLoading(false)))
            .catch((error) => {
              dispatch(authError(error.message));
              dispatch(authLoading(false));
            });
        } else {
          dispatch(authError(error.message))
          dispatch(authLoading(false));
        }

      });
  }
}

export const user_registration = (userName: string, userPass: string) => {
  return (dispatch: any) => {
    dispatch(authError(''));
    dispatch(authLoading(true));


    // Registering user at onPlanners
    const url = 'https://digital-planner.com/planner/api/create';
    const body = { "email": userName, "pass": userPass };
    const headers = { 'content-type': 'text/plain' };

    axios.post(url, body, { "headers": headers })
      .then((res: any) => {
        if (res.data.success) {
          firebase.auth().createUserWithEmailAndPassword(userName, userPass)
            .then((userCredentials) => {
              if (userCredentials.user) {
                firebase.firestore().collection('userData').doc(userCredentials.user.uid)
                  .collection('settings').doc('permissions').set({ role: 'customer', email: userName }, { merge: true });

                dispatch(userLogin(userName, userCredentials.user.uid, 'customer', false, false));
                return userCredentials.user.uid
              }
            })
            .then((uid) => {
              firebase.firestore().collection('userList').doc(userName).set({ uid: uid, email: userName, remoteUid: res.data.uid }, { merge: true })
            })
            .then(() => dispatch(authLoading(false)))
            .catch((error) => {
              dispatch(authError(error.message));
              dispatch(authLoading(false));
            });
        } else {
          dispatch(authError(res.data.message))
          dispatch(authLoading(false));
        }
      })
      .then(() => dispatch(authLoading(false)))
      .catch((error) => {
        dispatch(authLoading(false));
      });
  }
}

export const reset_recognized_user = () => {
  return (dispatch: any) => {
    dispatch({ type: RESET_RECOGNIZED_USER })
  }
}

export const register_recognized_user = (userName: string, userPass: string, remoteUid: string | number) => {
  return (dispatch: any) => {
    dispatch(authLoading(true));
    firebase.auth().createUserWithEmailAndPassword(userName, userPass)
      .then((userCredentials) => {
        if (userCredentials.user) {
          firebase.firestore().collection('userData').doc(userCredentials.user.uid)
            .collection('settings').doc('permissions').set({ role: 'customer', email: userName }, { merge: true });

          dispatch(userLogin(userName, userCredentials.user.uid, 'customer', false, false));
          return userCredentials.user.uid
        }
      })
      .then((uid) => {
        firebase.firestore().collection('userList').doc(userName).set({ uid: uid, email: userName, remoteUid: remoteUid }, { merge: true })
      })
      .then(() => dispatch(authLoading(false)))
      .catch((error) => {
        dispatch(authError(error.message));
        dispatch(authLoading(false));
      });
  }
}

export const user_logout = () => {
  return (dispatch: any) => {
    dispatch(authError(''));
    dispatch(authLoading(true));
    firebase.auth().signOut()
      .then(() => { dispatch(userLogout()); localStorage.removeItem('customFbUID') })
      .then(() => dispatch(authLoading(false)))
      .then(() => document.location.reload())
      .catch((error) => {
        dispatch(authError(error.message))
        dispatch(authLoading(false));
      });
  }
}

///////// OLD GAPI AUTH
// export const gapi_auth = () => {
//   return (dispatch: any) => {
//     dispatch(gapiAuthLoading(true));
//     let gapi = window.gapi;
//     if (gapi) {
//       console.log(JSON.stringify(gapi))
//       gapi.load('client:auth2');
//       let checkGapiInterval = setInterval(function(){
//         if(typeof(gapi.client) == 'undefined'){
//             console.log(JSON.stringify(gapi))
//         } else {
//             console.log('gapi.client loaded');
//             clearInterval(checkGapiInterval);
//             gapi.client.init({
//               // apiKey: 'AIzaSyBFJPUiw_SIPy8RgYZEhRSpvmM-UPdroPw',
//               // clientId: '907878610945-pnmrnagfrbul9m1c02ak7hqe54rp8acl.apps.googleusercontent.com',
//               // discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//               // scope: 'https://www.googleapis.com/auth/calendar'
//               apiKey: 'AIzaSyCPYNgGhoR9uMmhubCWMOfs7jZkiK6mok4',
//               // clientId: '648240162949-42mqe54ljlq1ahg48sj5iemkp0feo3sl.apps.googleusercontent.com', /// WEB for all
//               clientId: '648240162949-epofrbumjf0mcgfa45o6714kb8n3mi4k.apps.googleusercontent.com', /// iOS only
//               discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//               scope: 'https://www.googleapis.com/auth/calendar'
//               // apiKey: 'AIzaSyBFJPUiw_SIPy8RgYZEhRSpvmM-UPdroPw',
//               // clientId: '1049802044456-3ilcm7qd9uonop02fcaiq9lmhpr1g0dc.apps.googleusercontent.com',
//               // discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//               // scope: 'https://www.googleapis.com/auth/calendar'
//             }).then((res: any) => {
//               console.log('second stage gapi');
//               const googleAuth = gapi.auth2.getAuthInstance();
//               if (googleAuth && googleAuth.isSignedIn.get()) {
//                 const user = googleAuth.currentUser.get();
//                 const googleToken = user.getAuthResponse().id_token;
//                 const access_token = user.getAuthResponse().access_token;
//                 dispatch(gapiAuth(user.getBasicProfile().getEmail(), access_token));

//                 /* Load Google user calendars list */
//                 const callDavGoogle = new CallDavGoogle(access_token)
//                 callDavGoogle.listCalendars().then((calendars: any) => {
//                   if (calendars) {
//                     dispatch(calendarsActions.set_google_user_calendars(calendars))
//                   }
//                 })
//               }
//               dispatch(gapiAuthLoading(false));
//             })
//             .catch((e: any) => {
//               console.log('gapi error', e);
//               dispatch(gapiAuthLoading(false));
//             })
//           }
//     },10000)
//     } else {
//       dispatch(gapiAuthLoading(false));
//     }
//   }
// }

function getCalendars(dispatch: any, token: string) {
  dispatch(gapiAuth2(token, true));
  const callDavGoogle = new CallDavGoogle(token)
  callDavGoogle.listCalendars().then((calendars: any) => {
    if (calendars) {
      dispatch(calendarsActions.set_google_user_calendars(calendars))
    }
  })
}

///////// NEW GAPI AUTH
export const gapi_auth = () => {
  return async (dispatch: any) => {
    dispatch(gapiAuthLoading(true));
    GoogleAuth.refresh().then((credentials) => {
      getCalendars(dispatch, credentials.accessToken);
      dispatch(gapiAuthLoading(false));
    })
      .catch(() => {
        GoogleAuth.signIn().then((credentials) => {
          if (credentials) {
            getCalendars(dispatch, credentials.authentication.accessToken);
          }
          dispatch(gapiAuthLoading(false));
        })
          .catch((e) => dispatch(gapiAuthLoading(false)));
      });
  }
}

export const gapi_signout = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(gapiAuthLoading(true));
    // const googleAuth = window.gapi.auth2.getAuthInstance();
    // if (googleAuth) {
    //   await googleAuth.signOut();
    // }
    await GoogleAuth.signOut();
    let uid = getState().auth.uid;
    if(uid) {
      firebase.firestore().collection('userData').doc(uid)
        .collection('settings').doc('permissions').set({ googleUsed: false }, { merge: true });
    }

    dispatch(calendarsActions.set_google_user_calendars([]))
    dispatch(gapiAuth2('', false));
    dispatch(gapiAuthLoading(false));
  }
}
export const gapi_signin = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(gapiAuthLoading(true));

    GoogleAuth.signIn().then((credentials) => {
      if (credentials) {
        let uid = getState().auth.uid;
        if(uid) {
          firebase.firestore().collection('userData').doc(uid)
            .collection('settings').doc('permissions').set({ googleUsed: true }, { merge: true });
        }

        getCalendars(dispatch, credentials.authentication.accessToken);
      }
      dispatch(gapiAuthLoading(false));
    })
      .catch((e) => dispatch(gapiAuthLoading(false)));
  }
}

export const set_apple_credentials = (credentials: any) => {
  return (dispatch: any) => {
    firebase.firestore().collection('userData').doc(credentials.uid).collection('credentials').doc('apple').set({
      userName: credentials.userName,
      password: credentials.password
    })
    dispatch(setAppleCredentials(credentials))
  }
}

export const get_apple_credentials = (uid: string) => {
  return (dispatch: any) => {
    try {
      dispatch({type: SET_APPLE_CREDENTIALS_ERROR, error: false})
      dispatch(calendarsActions.set_apple_user_calendars([]))
      firebase.firestore().collection('userData').doc(uid).collection('credentials').doc('apple').get()
        .then((data) => {
          const credentials = data.data();
          if (credentials) {
            dispatch(setAppleCredentials(credentials));
            const callDavGoogle = new CallDavApple(credentials.userName, credentials.password)
            callDavGoogle.listCalendars().then((calendars: any) => {
              if (calendars) {
                dispatch(calendarsActions.set_apple_user_calendars(calendars))
              }
            })
              .catch((e) => {dispatch({type: SET_APPLE_CREDENTIALS_ERROR, error: true}); console.log(e)})
          }
        })
    } catch (e) {
      dispatch({type: SET_APPLE_CREDENTIALS_ERROR, error: true})
      console.log(e);
    }
  }
}