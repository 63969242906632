import { IonContent, IonPage, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillLeave } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from "react-redux-firebase";
import * as plannerActions from '../../store/actions/plannerControls';
import PlannerControlsHeader from '../../components/UI/Header/PlannerControlsHeader';
import MainMenu from '../../components/UI/Menu/MainMenu';
import PlannerMenu from '../../components/UI/Menu/PlannerMenu';
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';
import PlannerStage from '../../components/Planner/PlannerStage';
import PlannerZoomController from '../../components/Planner/PlannerZoomController';
import PlannerEvents from '../../components/Planner/Events/PlannerEvents';
import * as userDataActionTypes from '../../store/actions/userData';
import { useHistory } from 'react-router';
import './Planner.css';
import moment from 'moment';
import { useChangePlannerYear } from '../../services/ChangePlannerYear';
import { useWindowResize } from '../../services/WindowResizeHook';
import { parse } from 'node-html-parser';
import { convertStylesStringToObject } from '../../services/htmlStylesParser';
import UserEditFieldsHandler from '../../components/Planner/helpers/UserEditFieldsHandler';
import { useCheckPermission } from '../../services/userRolesContext';
import { useThemeSwitcher } from '../../services/ThemeSwitcher';
import PlannerChangesWatcher from '../../components/Planner/helpers/PlannerChangesWatcher'
import StickerSelector from '../../components/Planner/StickerSelector';
import { logScreenViewEvent } from "../../services/logger";
import { PlannerGuides } from '../../components/Guides/Guides';

declare global {
  interface Window {
    builderApi: any;
  }
}

const Planner: React.FC = () => {
  const userPermissions: any = useCheckPermission();
  const uid = useSelector((state: any) => state.auth.uid);
  const auth = useSelector((state: any) => state.firebase.auth);
  const { plannerData, curPage, elementOnFocus, zoom: plannerZoom, ...plannerControls } = useSelector((state: any) => state.plannerControls);
  const { pagerStep, activePlanner, ...userData } = useSelector((state: any) => state.userData);
  const { fbPlannerId: plannerId, plannerYear } = useSelector((state: any) => state.userData.activePlanner);
  const plannerCover = activePlanner.plannerCover?.customBackImage || 'https://marketplace.canva.com/EAD29gI7er8/2/0/800w/canva-swirling-stars-and-space-video-zoom-virtual-background-I_B0jpKq2ug.jpg';
  const pageType = useRef<any>();
  const pageTypeCount = useRef<any>();
  const contentHeightRef: any = useRef('100%');
  const history = useHistory();
  const dispatch = useDispatch();
  const builderApi = window.builderApi;
  const prevHash = useRef();
  const [firstPageHtml, setFirstPageHtml] = useState('');
  const [secondPageHtml, setSecondPageHtml] = useState('');
  const changePlannerYear = useChangePlannerYear();
  const windowSize = useWindowResize();
  const lastPage = useRef(0);
  const lastPageType = useRef(0);
  const pageChanged = useRef(false);
  const curPlannerDisabled = useRef<false>();
  const ThemeSwitcher = useThemeSwitcher();


  useIonViewDidEnter(() => {
    document.title = 'Planner - Digital Planner App';
  }, [])

  // let tabsWidth = 9.8;
  const tabsWidth = useRef(0);
  const canvasTabs = useRef(0);

  useEffect(() => {
    if (activePlanner.pages) {
      pageType.current = activePlanner.pages[curPage].pageId;
      pageTypeCount.current = activePlanner.pages[curPage].pageTypeCount;
      if (pageTypeCount.current % 2) {
        pageTypeCount.current = pageTypeCount.current - 1;
      }
      if (!lastPage.current) lastPage.current = 0;
      if (!lastPageType.current) lastPageType.current = 0;
      const pageName = activePlanner.staticPages[activePlanner.pages[curPage].pageId].pageName;
      document.title = pageName + ' - Digital Planner App';
      logScreenViewEvent(pageName + ' - planner page');
    }
    if (pageChanged.current && plannerData[lastPageType.current] && plannerData[lastPageType.current][lastPage.current]) {
      // console.log('writing to db', lastPageType.current, lastPage.current, plannerData[lastPageType.current][lastPage.current])
      // dispatch(plannerActions.update_planner_data_db(uid, userPermissions, plannerId, plannerYear, lastPageType.current, lastPage.current, plannerData[lastPageType.current][lastPage.current]))
      pageChanged.current = false;
    }
    lastPage.current = pageTypeCount.current
    lastPageType.current = pageType.current

  }, [curPage])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const handleKeyDown = (e: any) => {
    // if()
    switch (e.keyCode) {
      case 37: !elementOnFocus && goToPage('', false, true); break;
      case 39: !elementOnFocus && goToPage('', true, false); break;
      default: break;
    }
  };

  useIonViewWillLeave(() => {
    // if(history.location.pathname.includes('settings')) return;
    dispatch(userDataActionTypes.cleanup_active_planner());
    dispatch(plannerActions.cleanup_planner_data());
    dispatch(plannerActions.set_cur_page(0));
    dispatch(plannerActions.set_zoom_by_height(0));
    dispatch(plannerActions.set_planner_fit(false));
  }, [activePlanner])

  useEffect(() => {
    if (!elementOnFocus) {
      dispatch(userDataActionTypes.set_pager_step(window.innerWidth / window.innerHeight > 1 ? 2 : 1));
      calcZoom();
    }
  }, [windowSize, curPage]);

  useEffect(() => {
    calcZoom();
  }, [pagerStep, firstPageHtml])

  useEffect(() => {
    calcZoom(true);
  }, [plannerControls.zoom])

  useEffect(() => {
    curPlannerDisabled.current = plannerControls.plannerDisabled;
    if (isLoaded(auth) && !isEmpty(auth) && !userData.userPlanners.length && !userData.loading) {
      dispatch(userDataActionTypes.load_user_planners(uid));
    }
  })

  useEffect(() => {
    if (
      // history.location.pathname.includes('/planner') && 
      !history.location.pathname.includes('settings')
      && !activePlanner.pages
      && !userData.activePlannerLoading) {
      const plannerIdRegex = `${process.env.PUBLIC_URL}\/planner\/(.[^/]*)\/?`;
      const plannerId = history.location.pathname.replace(new RegExp(plannerIdRegex, 'g'), (m1: string, m2: string) => m2);
      const plannerConfig = userData.userPlanners.find((planner: any) => planner.fbPlannerId == plannerId);
      if (!userData.userPlanners.length) return;
      if (!plannerConfig && history.location.pathname.includes('/planner')) return history.push(`${process.env.PUBLIC_URL}/`);
      if (history.location.pathname.includes('/planner')) dispatch(userDataActionTypes.load_planner_source(uid, plannerConfig));
    }
    if (!plannerZoom) calcZoom();
  })

  useEffect(() => {
    if (!activePlanner.pages) return;
    const urlHash = history.location.hash.split('#')[1];
    if (urlHash && activePlanner?.pages[curPage].ids.indexOf(urlHash) < 0) goToPage(urlHash);
    const weekStart = activePlanner.weekStart == 'monday' ? 1 : 0;
    const staticPageId = activePlanner?.pages[curPage].pageId;
    const staticPageIndex = activePlanner.staticPages.findIndex((elem: any) => elem.pageId == staticPageId);
    const pageDate = moment(activePlanner?.pages[curPage].date);
    let pageDinamic = activePlanner.staticPages[staticPageIndex][activePlanner?.pages[curPage].page];
    let stateCounts = {};
    pageDinamic = pageDinamic.replace(builderApi.LAZY_TOKEN, (token: string, m1: string) => builderApi.processLazyToken(m1, curPage, pageDate, stateCounts, weekStart));
    pageDinamic = pageDinamic.replace(builderApi.LAZY_DATA, (token: string, m1: string) => builderApi.processLazyData(m1, pageDate));
    let leftPageHtml = activePlanner?.pages[curPage] ? '<div class="plannerSignlePage cur">' + pageDinamic + '</div>' : '';
    let parsedLeftPage = parse(leftPageHtml);
    if (parsedLeftPage.querySelector('.tabwrapper')) canvasTabs.current = 9;
    let rightPageHtml = '';
    if (pagerStep == 2 && activePlanner?.pages[curPage + 1]) {

      // Removing left page tabs on landscape view
      if (parsedLeftPage.querySelector('.tabwrapper')) tabsWidth.current = 9;
      parsedLeftPage.querySelector('.tabwrapper')?.remove();
      const leftPageStyles: any = parsedLeftPage.querySelector('.page').getAttribute('style');
      const styles: any = convertStylesStringToObject(leftPageStyles);
      styles.width = (parseInt(styles.width) - tabsWidth.current) + 'em';
      parsedLeftPage.querySelector('.page').setAttribute('style', JSON.stringify(styles).replace('{', '').replace('}', '').replace(/\"+/g, '').replace(/\,+/g, ';'));
      leftPageHtml = parsedLeftPage.toString();

      // Adding second page on landscape view
      const staticPageId = activePlanner?.pages[curPage + 1].pageId;
      const pageDate = moment(activePlanner?.pages[curPage + 1].date);
      let pageDinamic = activePlanner.staticPages[staticPageIndex][activePlanner?.pages[curPage + 1].page];
      let stateCounts = {};
      pageDinamic = pageDinamic.replace(builderApi.LAZY_TOKEN, (token: string, m1: string) => builderApi.processLazyToken(m1, curPage + 1, pageDate, stateCounts, weekStart));
      pageDinamic = pageDinamic.replace(builderApi.LAZY_DATA, (token: string, m1: string) => builderApi.processLazyData(m1, pageDate));
      rightPageHtml = activePlanner?.pages[curPage] ? '<div class="plannerSignlePage cur">' + pageDinamic + '</div>' : '';
    }
    setFirstPageHtml(leftPageHtml);
    setSecondPageHtml(rightPageHtml);
  }, [activePlanner, curPage, plannerZoom])

  const setPageChanged = (state: boolean) => {
    pageChanged.current = state;
  }

  const calcZoom = (resized?: boolean) => {
    if (!activePlanner.plannerSize) return;
    const plannerContentsNodeHeight = document.querySelector('.planner-contents')?.clientHeight;
    const contentHeight = plannerContentsNodeHeight || document.querySelector('.ion-header')?.clientHeight || window.innerHeight - 45; // 45 = approx toolbar height
    const plannerPagesWrapperWidth = document.querySelector('.plannerPagesWrapper')?.getBoundingClientRect().width;
    contentHeightRef.current = contentHeight || '100%';
    const plannerWidth = pagerStep == 1 ? activePlanner.plannerSize.width : activePlanner.plannerSize.width * 2 - tabsWidth.current;
    const plannerHeight = activePlanner.plannerSize.height;
    let newZoom = window.innerWidth / plannerWidth;
    if(!plannerControls.zoomFitByWidth || resized) {
      if (contentHeight && contentHeight >= (plannerHeight * newZoom)) {
        const zoomFitByWidth = (window.innerWidth - 15) / plannerWidth;
        const minScale = window.innerWidth / (plannerPagesWrapperWidth || window.innerWidth);
        (plannerControls.zoomFitByHeight != minScale || plannerControls.zoomFitByHeight <= 1) && dispatch(plannerActions.set_zoom_by_height(minScale));
        // console.log(zoomFitByWidth)
        dispatch(plannerActions.set_zoom(zoomFitByWidth));
      } else {
        const zoomFitByHeight = (contentHeight! - 15) / plannerHeight;
        const minScale = window.innerWidth / (plannerPagesWrapperWidth || window.innerWidth);
        (plannerControls.zoomFitByHeight != minScale || plannerControls.zoomFitByHeight) <= 1 && dispatch(plannerActions.set_zoom_by_height(minScale));
        dispatch(plannerActions.set_zoom(zoomFitByHeight));
      }
    }else{
      dispatch(plannerActions.set_zoom(newZoom));
    }
  }

  useEffect(() => {
    if (firstPageHtml != '') {
      document.querySelector('.plannerPagesWrapper')?.querySelectorAll('a').forEach(link => {
        link.addEventListener('mouseup', (e: any) => { plannerLinkClickHandler(e); });
        link.addEventListener('touchend', (e: any) => { plannerLinkClickHandler(e); });
      });
      return () => {
        document.querySelector('.plannerPagesWrapper')?.querySelectorAll('a').forEach(link => {
          link.removeEventListener('mouseup', plannerLinkClickHandler);
          link.removeEventListener('touchend', plannerLinkClickHandler);
        });
      }
    }
  }, [firstPageHtml])

  const hashChangeHandler = () => {
    goToPage(history.location.hash.split('#')[1])
  }
  useEffect(() => {
    if (activePlanner.plannerFont) dispatch(plannerActions.update_planner_font(uid, plannerId, userPermissions, activePlanner.plannerFont));
    window.addEventListener("hashchange", hashChangeHandler, false);
    return () => window.removeEventListener("hashchange", hashChangeHandler);
  }, [activePlanner.pages])

  const plannerLinkClickHandler = (e: any) => {
    if (curPlannerDisabled.current) return;
    const href = e.target.closest('a').getAttribute('href');
    if (href && href.includes('#')) {
      e.preventDefault();
      let year: any = href.match(/(y[0-9])\w+/g);
      if (year && activePlanner.plannerYear && parseInt(year[0].replace('y', '')) !== parseInt(activePlanner.plannerYear)) {
        console.log('changing year')
        changePlannerYear(year[0].replace('y', ''), href);
        history.push(history.location.pathname + '#' + href.replace('#', ''));
      } else {
        goToPage(href.replace('#', ''));
        prevHash.current = href;
      }
    }
  }

  const goToPage = (pageId?: string, nextPage?: boolean, prevPage?: boolean) => {
    dispatch(plannerActions.select_stage_element(null));
    const curLocation = history.location.pathname;
    if (nextPage) {
      if (activePlanner.pages[curPage + pagerStep]) {
        const nextPageIds = activePlanner.pages[curPage + pagerStep].ids
        const nextHash = nextPageIds[nextPageIds.length - 1];
        nextHash ? history.push(curLocation + '#' + nextHash) : history.push(curLocation.split('#')[0]);
        dispatch(plannerActions.set_cur_page(curPage + pagerStep));
      }
    } else if (prevPage) {
      if (activePlanner.pages[curPage - pagerStep]) {
        const nextPageIds = activePlanner.pages[curPage - pagerStep].ids
        const nextHash = nextPageIds[nextPageIds.length - 1];
        nextHash ? history.push(curLocation + '#' + nextHash) : history.push(curLocation.split('#')[0]);
        dispatch(plannerActions.set_cur_page(curPage - pagerStep));
      }
    } else {
      let newTitle = '';
      const newPageIndex = activePlanner.pages?.findIndex((element: any) => {
        if (element.ids.indexOf(pageId) > -1) { return true; }
        return false;
      })
      if (newPageIndex > -1) {
        pageId?.length && history.push(curLocation + '#' + pageId);
        dispatch(plannerActions.set_cur_page(newPageIndex));
      } else { console.log(pageId + ': page not found'); }
    }
  }

  return (
    <>
      <PlannerGuides />
      <MainMenu />
      <PlannerMenu />
      <IonPage id="mainContent">
        {activePlanner && (<>
          <PlannerControlsHeader title={activePlanner.plannerName} goToPage={(nextPage: boolean, prevPage: boolean) => goToPage('', nextPage, prevPage)} />
          <IonContent className="planner-contents"
            scrollX={false} scrollY={false}
            style={{ overflow: 'scroll' }}
          >
            { plannerControls.showStickerSelector && <StickerSelector /> }
            {userData.activePlannerLoading
              ? <LoadingSpinner />
              : activePlanner.pages && (
                <PlannerZoomController width={window.innerWidth} height={contentHeightRef.current} pagerStep={pagerStep}>
                  <PlannerChangesWatcher />
                  <UserEditFieldsHandler firstPageHtml={firstPageHtml} pageType={pageType.current || 0} pageTypeCount={pageTypeCount.current || 0} pageChanged={pageChanged.current} setPageChanged={(state: boolean) => setPageChanged(state)} />
                  <div className="plannerWrapper plannerCover" style={{ height: contentHeightRef.current, backgroundImage: `url(${plannerCover})` }}>
                    <div className={activePlanner.isResponsive ? `plannerPagesWrapper ${activePlanner.plannerClass} digital-app-padding` : `plannerPagesWrapper ${activePlanner.plannerClass}`}>
                      {pagerStep == 1 ?
                        <div className="plannerHolder" style={{
                          fontSize: plannerZoom + 'px',
                          width: activePlanner.plannerSize?.width * plannerZoom + 'px',
                          height: activePlanner.plannerSize?.height * plannerZoom + 'px'
                        }} >
                          <PlannerStage canvasWidth={(activePlanner.plannerSize?.width - canvasTabs.current) * 2} canvasHeight={activePlanner.plannerSize?.height} />
                          <div className="plannerHeader" dangerouslySetInnerHTML={{ __html: activePlanner.header }}></div>
                          <div className={plannerControls.plannerDisabled ? "plannerPages pagesDisabled" : "plannerPages"}
                            // style={plannerPageTranslate}
                            dangerouslySetInnerHTML={{ __html: firstPageHtml }}>
                          </div>

                        </div>
                        :
                        <div style={{ 'display': 'flex' }}>
                          <div className="plannerHolder" style={{
                            fontSize: plannerZoom + 'px',
                            width: (activePlanner.plannerSize?.width - tabsWidth.current) * plannerZoom + 'px',
                            height: activePlanner.plannerSize?.height * plannerZoom + 'px'
                          }} >
                            <PlannerStage canvasWidth={(activePlanner.plannerSize?.width - tabsWidth.current) * 2} canvasHeight={activePlanner.plannerSize?.height} />
                            <div className="plannerHeader" dangerouslySetInnerHTML={{ __html: activePlanner.header }} />
                            <div className={plannerControls.plannerDisabled ? "plannerPages pagesDisabled" : "plannerPages"}
                              // style={plannerPageTranslate}
                              dangerouslySetInnerHTML={{ __html: firstPageHtml }}>
                            </div>
                          </div>
                          <div className="plannerHolder" style={{
                            fontSize: plannerZoom + 'px',
                            width: activePlanner.plannerSize?.width * plannerZoom + 'px',
                            height: activePlanner.plannerSize?.height * plannerZoom + 'px'
                          }} >
                            <div className={plannerControls.plannerDisabled ? "plannerPages right pagesDisabled" : "plannerPages right"}
                              // style={plannerPageTranslate}
                              dangerouslySetInnerHTML={{ __html: secondPageHtml }}>
                            </div>

                          </div>
                        </div>
                      }
                      <PlannerEvents />
                      {pagerStep !== 1 && <PlannerEvents actualPage={1} />}
                    </div>
                  </div>
                </PlannerZoomController>
              )
            }
          </IonContent>
        </>)}
      </IonPage>
    </>
  )
}
export default Planner;