import { IonButton, IonCol, IonIcon, IonInput, IonRow, IonSpinner, IonText, IonThumbnail, IonToast } from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from "../../services/firebase";
import './AdminStyles.css';
import moment from 'moment';

const AdminUserList: React.FC = () => {
  const dispatch = useDispatch();
  const fbAuthUID = useSelector((state: any) => state.firebase.auth.uid);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [searchVal, setSearchVal] = useState<string>('asdhomer@gmail.com');
  const [searchRes, setSearchRes] = useState<Array<[any]>>([]);
  const [userList, setUserList] = useState<any>(null);
  const [snapshotDate, setSnapshotDate] = useState<any>(null);
  const [userData, setUserData] = useState<any>(null);

  const setUsers = () => {
    firebase.firestore().collection('snapshots').doc('userList').get()
      .then((doc: any) => {
        if (doc.exists) {
          // const allUsers: any = [];
          let allUsers = JSON.parse(doc.data().allUsers);
          const lastUpdateDate = doc.data().lastUpdate
          allUsers.sort((a: any, b: any) => {
            if (moment(a['registered'], 'DD-MM-YYYY HH:mm').isBefore(moment(b['registered'], 'DD-MM-YYYY HH:mm'))) {
              return 1
            } else {
              return -1
            }
          })
          setUserList(allUsers);
          setSnapshotDate(lastUpdateDate);
        }
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    setUsers()
  }, []);

  const makeUserListSnapshot = () => {
    let date = new Date();
    let todayDate = date.toISOString().split('T')[0]
    firebase.firestore().collection('userList').get()
      .then((res) => {
        const allUsers: any = [];
        res.forEach(user => allUsers.push(user.data()))
        firebase.firestore()
          .collection('snapshots')
          .doc('userList')
          .set({ allUsers: JSON.stringify(allUsers), lastUpdate: todayDate }, { merge: true })
          .catch(e => console.log(e));
      })
      .then(() => {
        setUsers()
      })
      .catch((e) => console.log(e))
  }

  const getUserDetails = (uid: string) => {
    firebase.firestore().collection('userData').doc(uid).collection('planners').get()
      .then((res) => {
        const userPlanners: any = [];
        res.forEach(planner => {
          let plannerData = planner.data()
          plannerData['fbPlannerId'] = planner.id
          userPlanners.push(plannerData)
        });
        console.log(userPlanners)
        setUserData({ ...userData, [uid]: [...userPlanners] })
      })
      .catch((e) => console.log(e))
  }

  const searchUid = (email: string | undefined) => {
    if (email) {
      firebase.firestore().collection('userList').where(firebase.firestore.FieldPath.documentId(), '==', email).get()
        .then((res) => {
          const results: any = [];
          res.forEach(result => { results.push(result.data()) })
          // console.log(results)
          setSearchRes([...results])
        })
        .catch(e => console.log(e));
    }
  }

  return (
    <IonRow>
      <IonCol size="12" className="ion-margin-bottom">
        <IonRow>
          <IonCol size="6">
            Snapshot Date: {snapshotDate} <br />
            <IonButton onClick={() => makeUserListSnapshot()}>Update Snapshot</IonButton>
          </IonCol>
          <IonCol size="6">
            <IonInput style={{ border: '1px solid #ccc' }} value={searchVal} placeholder="Enter user email" onIonChange={(e: any) => setSearchVal(e.detail.value)}></IonInput>
            <IonButton onClick={() => searchUid(searchVal)}>
              Search UID by email
            </IonButton>
          </IonCol>
        </IonRow>
        {userList && userList.length > 0 &&
          <IonRow className="ion-margin-top ion-margin-bottom">
            <IonCol size="12"><h3>Users list ({userList.length})</h3></IonCol>
            <IonCol size="12" style={{ overflowX: 'scroll' }}>
              <table className="userListTable">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Remote UID</th>
                    <th>UID</th>
                    <th>Last active</th>
                    <th>Last login</th>
                    <th>Registered</th>
                    <th>Planners info</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((user: any, i: number) => {
                    const markedUser = (moment(user.registered, 'DD-MM-YYYY HH:mm').isBefore(moment(user.lastActive, 'DD-MM-YYYY HH:mm')))
                    const alert = markedUser ? (<span style={{ borderRadius: 50 + "%", backgroundColor: "rgb(103 209 0)", width: 10 + "px", height: 10 + "px", display: 'inline-block' }} />) : ''
                    return (<tr key={user.email + `${user.registered}`} onClick={() => getUserDetails(user.uid)}>
                      <td>{user.email}</td>
                      <td>{user.remoteUid}</td>
                      <td>{user.uid}</td>
                      <td>{alert} {user.lastActive}</td>
                      <td>{user.lastSignIn}</td>
                      <td>{user.registered}</td>
                      <td>
                        {userData && userData[user.uid]
                          ?
                          <>
                            <div>Planners used: {userData[user.uid].length}</div>
                            {userData[user.uid].map((planner: any) => {
                              return (<div key={planner.fbPlannerId}><a target="_blank" href={`https://console.firebase.google.com/u/3/project/digital-planner-321711/firestore/data/~2FuserData~2F${user.uid}~2Fplanners~2F${planner.fbPlannerId}`}>{planner.fbPlannerId}</a></div>)
                            })
                            }
                          </>
                          : <IonButton>Load user data</IonButton>}</td>
                    </tr>
                    )
                  })}
                </tbody>
              </table>
            </IonCol>
          </IonRow>
        }
      </IonCol>
    </IonRow>
  )
}

export default AdminUserList;