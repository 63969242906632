import * as actionTypes from '../actions/auth';

const initialState = {
    user: null,
    uid: '',
    role: '',
    error: '',
    loginEmail: '',
    loginPassWrong: false,
    remoteUid: '',
    loading: false,
    gapiAuthLoading: false,
    googleUsed: false,
    googleUser: false,
    googleAccessToken: '',
    msalAuthLoading: false,
    microsoftAccessToken: '',
    microsoftUsed: false,
    appleCredentials: null,
    appleCredentialsError: false
}

export const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.USER_LOGIN:
            return {
                ...state,
                user: action.user,
                uid: action.uid,
                role: action.role,
                googleUsed: action.googleUsed ? true : false,
                microsoftUsed: action.microsoftUsed ? true : false,
                loginPassWrong: false,
                loginEmail: '',
            }
        case actionTypes.USER_LOGOUT:
            return {
                ...initialState
            }
        case actionTypes.AUTH_ERROR:
            return {
                ...state,
                error: action.message
            }
        case actionTypes.AUTH_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case actionTypes.SET_USER_ROLE:
            return {
                ...state,
                role: action.role,
                googleUsed: action.googleUsed ? true : false,
                microsoftUsed: action.microsoftUsed ? true : false
            }
        case actionTypes.GAPI_AUTH_LOADING:
            return {
                ...state,
                gapiAuthLoading: action.gapiAuthLoading
            }
        case actionTypes.GAPI_AUTH:
            return {
                ...state,
                googleUser: action.googleUser,
                googleAccessToken: action.googleAccessToken
            }
        case actionTypes.GAPI_AUTH2:
            return {
                ...state,
                googleUsed: action.googleUsed,
                googleAccessToken: action.googleAccessToken
            }
        case actionTypes.MSAL_AUTH_LOADING:
            return {
                ...state,
                msalAuthLoading: action.msalAuthLoading
            }
        case actionTypes.MSAL_AUTH:
            return {
                ...state,
                microsoftUsed: action.microsoftUsed,
                microsoftAccessToken: action.microsoftAccessToken
            }
        case actionTypes.SET_APPLE_CREDENTIALS:
            return {
                ...state,
                appleCredentials: {appleId: action.credentials.userName, password: action.credentials.password}
            }
        case actionTypes.SET_CUSTOM_FB_UID:
            return {
                ...state,
                uid: action.uid
            }
        case actionTypes.PASSWORD_CONFIRMATION_NEEDED:
            return {
                ...state,
                loginEmail: action.loginEmail,
                loginPassWrong: true,
                remoteUid: action.remoteUid
            }
        case actionTypes.RESET_RECOGNIZED_USER:
            return {
                ...state,
                loginEmail: action.loginEmail,
                loginPassWrong: false,
                remoteUid: action.remoteUid
            }
        case actionTypes.SET_APPLE_CREDENTIALS_ERROR:
            return {
                ...state,
                appleCredentialsError: action.error
            }
        default:
            return {
                ...state
            }
    }

}