import { Route, Redirect, RouteProps } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { IonSpinner } from '@ionic/react'
import React from "react";

export type Props = {
  children: any,
  exact?: any,
  path?: any,
};

const PrivateRoute = ({ children, ...remainingProps }: Props) => {
  const auth = useSelector((state: any) => state.firebase.auth);
  return isLoaded(auth) ? (
    <Route
      {...remainingProps}
      render={({ location, match }: any) =>
        !isEmpty(auth) || location.pathname == `${process.env.PUBLIC_URL}/login` || location.pathname.includes(`${process.env.PUBLIC_URL}/preview`)
          ? (children)
          : (<Redirect to={{ pathname: `${process.env.PUBLIC_URL}/login`, state: { from: location } }} />)
      }
    />
  ) : (<div
    className="ion-text-center ion-justify-content-center ion-align-items-center"
    style={{ minHeight: 100 + 'vh', display: 'flex' }}>
    <IonSpinner />
  </div>);
};

export default PrivateRoute;