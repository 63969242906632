import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonPopover, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import React from 'react';
import * as authActions from '../../store/actions/auth';
import * as calendarsActions from '../../store/actions/userCalendars';
import { useSelector, useDispatch } from 'react-redux';
import MainMenu from '../../components/UI/Menu/MainMenu';
import MainHeader from '../../components/UI/Header/MainHeader';
import LoadingSpinner from '../../components/UI/Helpers/LoadingSpinner';
import { ellipse } from 'ionicons/icons';
import './ProfileCalendars.css';
import { logScreenViewEvent } from '../../services/logger';

const ProfileCalendars: React.FC = () => {
  const auth = useSelector((state: any) => state.auth);
  const uid = useSelector((state: any) => state.auth.uid);
  const appleCredentials = useSelector((state: any) => state.auth.appleCredentials)
  const appleCredentialsError = useSelector((state: any) => state.auth.appleCredentialsError)
  const userCalendars = useSelector((state: any) => state.userCalendars)
  const dispatch = useDispatch();
  const [accessCalendar, setAccessCalendar] = useState({
    url: '',
    name: '',
    timezone: '',
    color: ''
  });
  // const [listCalendars, setListCalendars] = useState<Array<[]>>();
  const [listEvents, setListEvents] = useState([]);
  const [appleUserInput, setAppleUserInput] = useState<string>('');
  const [applePassInput, setApplePassInput] = useState<string>('');
  const [showAppleHelp, setShowAppleHelp] = useState<boolean>(false);

  useIonViewDidEnter(() => {
    document.title = 'My Calendars - Digital Planner App';
    logScreenViewEvent();
  }, [])

  const googleSignOut = () => {
    dispatch(authActions.gapi_signout())
  }

  const googleSignIn = () => {
    dispatch(authActions.gapi_signin())
  }

  const microsoftSignIn = () => {
    dispatch(authActions.msal_signin())
  }

  const microsoftSignOut = () => {
    dispatch(authActions.msal_signout())
  }

  useEffect(() => {
    if (appleCredentials) {
      setAppleUserInput(appleCredentials.appleId);
      setApplePassInput(appleCredentials.password);
      // dispatch(authActions.get_apple_credentials(uid));
    }
  }, [appleCredentials])

  const saveAppleCalendars = (e: any) => {
    e.preventDefault();
    dispatch(authActions.set_apple_credentials({ uid, userName: e.target.appleId.value.trim(), password: e.target.applePass.value.trim() }));
    dispatch(authActions.get_apple_credentials(uid));
  }

  return (
    <>
      <MainMenu />
      <IonPage id="mainContent">
        <MainHeader title="My Calendars" />
        <IonContent className="planner-settings page-content-bg-gray">
          <IonPopover cssClass='appleHelpPopover'
            isOpen={showAppleHelp}
            onDidDismiss={() => setShowAppleHelp(false)}
          >
            <div className="ion-padding"><h2>Please complete <strong>both steps</strong> to generate an app-specific password:</h2>
              <h3><span >1.</span> Turn on Two-Factor Authentication</h3>
              <h4>On your iPhone or iPad:</h4>
              <ol>
                <li>Go to Settings &gt; (your name) &gt; Password &amp; Security.</li>
                <li>Tap Turn on Two-Factor Authentication.</li>
                <li>Tap Continue.</li>
                <li>Follow verification prompts.</li>
              </ol>
              <h4>On your Mac:</h4>
              <ol>
                <li>Go to Apple menu &gt; System Preferences &gt; iCloud &gt; Account Details.</li>
                <li>Click Security.</li>
                <li>Tap Turn on Two-Factor Authentication.</li>
              </ol>
              <h4><span>2.</span> Login to Apple ID to Generate Password</h4>
              <ol>
                <li>Sign in to <a target="_blank" href="https://appleid.apple.com/account/home">your Apple ID account page</a>.</li>
                <li>In the Security section, click Generate Password below App-Specific Passwords.</li>
                <li>Follow the steps on your screen.</li>
                <li>Don't forget to add a label for future reference.</li>
              </ol>
            </div>
          </IonPopover>
          <IonGrid fixed={true}>
            <IonRow>
              <IonCol size="12">
                <div className="infoText">
                  Connect your Google and/or Apple account to use a full potential of your planner app. 
                  You will be able to view, edit and create events on planner pages of your planners. 
                  We will not store or share your events to any third parties.
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="4">
                <div className="infoText h-100">
                  <h3>Google calendars:</h3>
                  {userCalendars.googleCalendarsList.length > 0 ? (
                    <IonList className="ion-margin-bottom">
                      {userCalendars.googleCalendarsList.map((calendar: any, index: number) =>
                        <IonItem key={calendar.url} lines={userCalendars.googleCalendarsList.length - 1 == index ? 'none' : 'full'}>
                          <IonLabel>
                            <IonIcon icon={ellipse} style={{ fontSize: 8 + 'px', color: calendar.color, marginRight: 10 + 'px' }} />
                            {calendar.name}
                          </IonLabel>
                        </IonItem>
                      )}
                    </IonList>
                  )
                    : <div className="ion-margin-bottom">
                      <h6>Please Connect Your Google Account</h6>
                      <p>After clicking on the 'Connect Google Calendars' button you will be prompted to sign-in to your Google account and provide access to this app.</p>
                      <p>Beta test specific: Since this app is still in beta-test mode you will see warning from Google saying that the app was not reviewed yet. Please click 'Advanced' then 'Go to Digital Planner (unsafe)' button to successfully connect to our app.</p>
                    </div>
                  }
                  {auth.gapiAuthLoading
                    ? <LoadingSpinner type="inline" />
                    : auth.googleAccessToken
                      ? (<>
                        <IonButton onClick={googleSignOut} color="warning">Disconnect Google calendars</IonButton>
                      </>)
                      : <IonButton onClick={googleSignIn}>Connect Google calendars</IonButton>
                  }
                </div>
              </IonCol>
              <IonCol sizeXs="12" sizeMd="4">
                <div className="infoText h-100">
                  <h3>Apple calendars:</h3>
                  {!appleCredentials && (
                    <div className="ion-margin-bottom">
                      <h6>Please Generate an App-Specific Password</h6>
                      <p>According to Apple security measures, you will need to generate a one-off password to allow access to your Apple Calendar.</p>
                      <p><a onClick={() => setShowAppleHelp(true)}>Click Here</a> for simple instructions.</p></div>
                  )}
                  <form onSubmit={saveAppleCalendars}>
                    <IonList>
                      <IonItem key="appleId">
                        <IonLabel position="floating">Your Apple-ID:</IonLabel>
                        <IonInput type="text" value={appleUserInput} name="appleId" required onIonChange={e => setAppleUserInput(e.detail.value!)}></IonInput>
                      </IonItem>
                      <IonItem lines="none" key="applePass">
                        <IonLabel position="floating">Your Apple App-specific password:</IonLabel>
                        <IonInput type="text" value={applePassInput} name="applePass" required onIonChange={e => setApplePassInput(e.detail.value!)}></IonInput>
                      </IonItem>
                    </IonList>
                    <IonButton type="submit" className="ion-margin-bottom">
                      {appleCredentials ? 'Update credentials' : 'Connect Apple Calendars'}
                    </IonButton>
                  </form>
                  {appleCredentials ?
                    userCalendars.appleCalendarsList.length == 0
                      ? appleCredentialsError
                        ? <IonText color="danger">Something went wrong. Please check your credentials and try again.</IonText> 
                        : <LoadingSpinner type="inline" />
                      : <IonList>
                        {userCalendars.appleCalendarsList.map((calendar: any, index: number) => (
                          <IonItem key={calendar.url} lines={userCalendars.appleCalendarsList.length - 1 == index ? 'none' : 'full'}>
                            <IonLabel>
                              <IonIcon icon={ellipse} style={{ fontSize: 8 + 'px', color: calendar.color, marginRight: 10 + 'px' }} />
                              {calendar.name}
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                    : ''}
                </div>
              </IonCol>
              <IonCol sizeXs="12" sizeMd="4">
                <div className="infoText h-100">
                  <h3>Microsoft calendars:</h3>
                  {userCalendars.outlookCalendarsList.length > 0 ? (
                    <IonList className="ion-margin-bottom">
                      {userCalendars.outlookCalendarsList.map((calendar: any, index: number) =>
                        <IonItem key={calendar.url} lines={userCalendars.outlookCalendarsList.length - 1 == index ? 'none' : 'full'}>
                          <IonLabel>
                            <IonIcon icon={ellipse} style={{ fontSize: 8 + 'px', color: calendar.color, marginRight: 10 + 'px' }} />
                            {calendar.name}
                          </IonLabel>
                        </IonItem>
                      )}
                    </IonList>
                  )
                    : <div className="ion-margin-bottom">
                      <h6>Please Connect Your Microsoft Account</h6>
                      <p>After clicking on the 'Connect Microsoft Calendars' button you will be prompted to sign-in to your Microsoft account and provide access to this app.</p>
                      <p>Beta test specific: Since this app is still in beta-test mode you will see warning from Microsoft saying that the app was not reviewed yet. Please click 'Advanced' then 'Go to Digital Planner (unsafe)' button to successfully connect to our app.</p>
                    </div>
                  }
                  {auth.msalAuthLoading
                    ? <LoadingSpinner type="inline" />
                    : auth.microsoftAccessToken
                      ? (<>
                        <IonButton onClick={microsoftSignOut} color="warning">Disconnect Microsoft calendars</IonButton>
                      </>)
                      : <IonButton onClick={microsoftSignIn}>Connect Microsoft calendars</IonButton>
                  }
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  )
}

export default ProfileCalendars;